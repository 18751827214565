import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Autoanswer } from '@/types'

import FormValue from '../../../../components/FormValue'
import PanelLayout from '../../../../components/PanelLayout'
import { type PanelProps } from '../../../../types/nodes'
import { useCurrentAccount } from '../../../../utils/currentAccountUtils'

import { AutoAnswerNodeData } from './AutoAnswerNode'

interface AutoAnswerPanelProps extends PanelProps<AutoAnswerNodeData> {}

const GET_EXTENSION = gql`
  query Extension($id: ID!, $accountId: ID!) {
    extension(id: $id, accountId: $accountId) {
      ... on Autoanswer {
        type
        name
        extension
      }
    }
  }
`

export const AutoAnswerPanel = ({
  node: {
    data: {
      extension: { extension_id: extensionId },
    },
  },
  onClose,
}: AutoAnswerPanelProps) => {
  const { t } = useTranslation()
  const account = useCurrentAccount()

  const { data, loading } = useQuery<{ extension: Autoanswer }>(GET_EXTENSION, {
    variables: {
      id: extensionId,
      accountId: account?.id,
    },
  })

  const extension = data?.extension

  return (
    <PanelLayout loading={loading} title={t('Auto Answer')} onClose={onClose}>
      <PanelLayout.GroupHeading>{t('General')}</PanelLayout.GroupHeading>
      <FormValue label={t('Name')} value={extension?.name} />
      <FormValue label={t('Extension number')} value={extension?.extension} />
      <PanelLayout.GroupDivider />
      <PanelLayout.GroupHeading>{t('Configuration')}</PanelLayout.GroupHeading>
      <FormValue label={t('Audio file')} value='AutoAnswer.mp3' isAudiofile />
    </PanelLayout>
  )
}

export default AutoAnswerPanel
