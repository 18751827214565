import { defineCustomElementClCheckbox } from '@enreach/core-component-library/dist/components/index'
import { ClCheckbox } from '@enreach/core-component-library-react'
import React, { forwardRef } from 'react'

defineCustomElementClCheckbox()

interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof ClCheckbox> {}

export const Checkbox = forwardRef<HTMLClCheckboxElement, CheckboxProps>(function Checkbox(props, ref) {
  return <ClCheckbox {...props} ref={ref} onClToggle={props.onChange} />
})
