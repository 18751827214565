import type { JSX } from 'react'

import { useTranslation } from 'react-i18next'
import { type NodeProps } from 'reactflow'

import { Destination } from '@/types'

import { FlowNodeAddonProps, NodeDataBase } from '../../../../types/nodes'
import { FlowNode } from '../FlowNode'

export interface ForwardNodeData extends NodeDataBase {
  destination: Destination
  addons?: Array<
    {
      type: string
    } & FlowNodeAddonProps
  >
}

export const ForwardNode = (props: NodeProps<ForwardNodeData>): JSX.Element => {
  const { t } = useTranslation()
  const { data } = props
  return (
    <FlowNode
      {...props}
      addons={data.addons?.reduce<FlowNodeAddonProps[]>((prev, AddonProps) => {
        console.warn(`Unknown addon type: ${type} passed in ${props.type} data `, data)

        return prev
      }, [])}
      body={{ children: data.title }}
      title={{
        icon: 'communication--call--forward--unconditional',
        label: t('Forward'),
        nmbr: data.extensionNmbr,
      }}
    />
  )
}

export default ForwardNode
