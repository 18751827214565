import type { ModuleConfig } from '../../../../types/nodes'

import Node from './IVRNode'
import Panel from './IVRPanel'

const callFlowModule: Partial<ModuleConfig> = {
  nodeTypes: {
    ivr: Node,
  },
  panelTypes: {
    ivr: Panel,
  },
}

export default callFlowModule
