import type { ModuleConfig } from '../../../../types/nodes'

import Node from './UserNode'
import Panel from './UserPanel'

const callFlowModule: Partial<ModuleConfig> = {
  nodeTypes: {
    usr: Node,
  },
  panelTypes: {
    usr: Panel,
  },
}

export default callFlowModule
