import 'reactflow/dist/style.css'
import './callflow.css'

import type { CallflowNode, ModuleConfig, Nodes } from '../../types/nodes'

import _ from 'lodash'
import { type JSX, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactFlow, {
  Background,
  Controls,
  type Edge,
  MiniMap,
  type Node,
  type NodeMouseHandler,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
  type XYPosition,
} from 'reactflow'
import { useLocalStorage } from 'usehooks-ts'

import { Scheme } from '@/types'
import { Panel, Typography } from '@/ui'

import BlockingLoader from '../../components/BlockingLoader'
import { NavBar } from '../../components/NavBar'
import { BuildNodeStrategy, type DomainFilterCriteria, type GraphFilterCriteria } from '../../types/filters'
import { useGetSchemeGraphQuery } from '../../types/graphqlOperations'
import { useStandalone } from '../../utils/appModeUtils'
import {
  applyAllHighlights,
  buildNodes as buildNodesFN,
  calculatePositions,
  filterDomainModel,
  filterGraph,
  useCallflowDownload,
} from '../../utils/callflowUtils'
import { useCurrentAccount } from '../../utils/currentAccountUtils'
import { generateLink, RoutePaths } from '../../utils/routeUtiles'

import AutoAnswerModule from './components/AutoAnswer'
import BotModule from './components/Bot'
import ExtPickerModule from './components/ExtPicker'
import ForwardModule from './components/Forward'
import FreeNumberNode from './components/FreeNumberNode'
import GroupModule from './components/Group'
import IVRModule from './components/IVR'
import LBRModule from './components/LBR'
import NmbrModule from './components/Nmbr'
import QueueModule from './components/Queue'
import TBRmodule from './components/TBR'
import UserModule from './components/User'
import VoicemailModule from './components/Voicemail'
import CallFlowBar, { type Filter } from './CallFlowBar'

function benchmark<T extends (...args: any[]) => R, R>(func: T): T {
  return ((...args: Parameters<T>): R => {
    console.debug(`benchmarking ${func.name}`, ...args)
    console.time(func.name)
    const result: R = func(...args)
    console.timeEnd(func.name)
    return result
  }) as T
}
const calculatePositionsBM: typeof calculatePositions = benchmark(calculatePositions)

const buildNodes: typeof buildNodesFN = benchmark(buildNodesFN)

const config: ModuleConfig = _.merge(
  {
    edgeTypes: {},
    nodeTypes: {
      freeNumber: FreeNumberNode,
    },
    panelTypes: {},
  },
  IVRModule,
  ForwardModule,
  GroupModule,
  LBRModule,
  NmbrModule,
  QueueModule,
  TBRmodule,
  UserModule,
  VoicemailModule,
  AutoAnswerModule,
  ExtPickerModule,
  BotModule
)

const STORE_KEY_PANEL_WIDTH = 'vcfd_SchemeCallFlowView_panelWidth'
const STORE_KEY_GRAPH_FILTER = 'vcfd_SchemeCallFlowView_graphFilter'

const proOptions = { hideAttribution: true }

function useFilterForScheme(schemeId: string): [Filter, (f: Filter) => void] {
  const [filterCollection, setFilterCollection] = useLocalStorage<Record<string, Filter>>(STORE_KEY_GRAPH_FILTER, {
    [schemeId]: {
      dialplans: [],
      number: '',
      showUnconnected: false,
      view: BuildNodeStrategy.FULLY_INTERCONNECTING,
    },
  })

  return useMemo(() => {
    const setFilter = (f: Filter) => {
      setFilterCollection({
        ...filterCollection,
        [schemeId]: f,
      })
    }

    return [
      filterCollection[schemeId] ?? {
        dialplans: [],
        number: '',
        showUnconnected: false,
        view: BuildNodeStrategy.FULLY_INTERCONNECTING,
      },

      setFilter,
    ]
  }, [schemeId, setFilterCollection, filterCollection])
}

/**
 * TODO: integrate loading all data for this view into a single query
 * TODO: implement loading of scheme call flow data
 * TODO: implement resizable panel
 * TODO: implement pinnable panel
 */
const CallFlow = (): JSX.Element => {
  const [positions, setPositions] = useState<Record<string, XYPosition>>({})
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [panel, setPanel] = useState<JSX.Element | null>(null)
  const [panelWidth] = useLocalStorage<string>(STORE_KEY_PANEL_WIDTH, '25rem')
  const account = useCurrentAccount()
  const utils = useReactFlow()
  const isStandAlone = useStandalone()
  const navigateTo = useNavigate()
  const { id: schemeId } = useParams()
  const [filter, setFilter] = useFilterForScheme(schemeId)
  const [loops] = useState<
    Array<{
      nodes: Node[]
      edges: Edge[]
    }>
  >([])
  const [loading, setLoading] = useState(false)
  const [selectedNodes, setSelectedNodes] = useState<Nodes>([])
  const ref = useRef<HTMLElement | null>(null)
  const { onClick } = useCallflowDownload(ref)

  const schemeQuery = useGetSchemeGraphQuery({
    skip: !account || !schemeId,
    ...(account && schemeId && { variables: { id: schemeId, accountId: account.id } }),
  })

  const handleOnSelectionChange = useCallback(({ nodes: selectedNds }: { nodes: Nodes }) => {
    setSelectedNodes(selectedNds)
    const data = applyAllHighlights({
      nodes: utils.getNodes(),
      edges: utils.getEdges(),
      nodeId: selectedNds[0]?.id,
      loops,
    })
    setNodes(data.nodes)
    setEdges(data.edges)
  }, [])

  const onInit = useCallback((reactFlowInstance: unknown): void => {
    console.debug('flow loaded:', reactFlowInstance)
    ref.current = document.querySelector('.react-flow__container')
  }, [])

  const getPanelComponent = (type?: string) => {
    if (!type) return null
    return config.panelTypes?.[type] ?? null
  }

  const isAddonClickEvent = (event: React.MouseEvent & { addon?: { type: string } }) => {
    return !!event.addon
  }

  const getPanelType = useCallback((event: React.MouseEvent, node: Node) => {
    if (isAddonClickEvent(event as React.MouseEvent & { addon?: { type: string } })) {
      return (event as React.MouseEvent & { addon?: { type: string } }).addon?.type
    }
    return node.type
  }, [])

  /**
   * when handleing double click event on a node we need to check if the event has an addon
   * if it does we use the addon type to determine the panel to show
   */
  const handleNodeDoubleClick: NodeMouseHandler = useCallback(
    (event, node: CallflowNode) => {
      const PanelComponent = getPanelComponent(getPanelType(event, node))
      if (!PanelComponent) return

      setPanel(
        <PanelComponent
          node={node}
          onClose={() => {
            setPanel(null)
          }}
        />
      )
    },
    [getPanelType]
  )

  /**
   * handler for navbar button to layout the graph
   */
  const handleLayoutClick = useCallback(() => {
    setPositions(
      calculatePositionsBM({
        nodes,
        edges,
      })
    )
    setTimeout(() => utils.fitView(), 200)
  }, [nodes, edges, utils])

  const handleFilterChange = useCallback(
    (f: Filter) => {
      setFilter(f)
      handleLayoutClick()
    },
    [handleLayoutClick, setFilter]
  )

  /**
   * moving back to browse schemes page
   */
  const handleBrowseClick = useCallback(() => {
    navigateTo(generateLink(RoutePaths.SCHEMES, { accountId: account?.id }))
  }, [navigateTo, account?.id])

  /**
   * moving to different scheme url
   */
  const handleSchemeChange = useCallback(
    (value: string) => {
      const url = generateLink(RoutePaths.SCHEME_CALLFLOW, {
        accountId: account?.id,
        id: value,
      })
      navigateTo(url)
    },
    [navigateTo, account?.id]
  )

  const domainFilterState = useMemo<DomainFilterCriteria>(
    () => ({
      dialplans: filter.dialplans,
      number: filter.number,
      view: filter.view,
    }),
    [filter.dialplans, filter.number, filter.view]
  )

  const graphFilterState = useMemo<GraphFilterCriteria>(
    () => ({
      showUnconnected: filter.showUnconnected,
    }),
    [filter.showUnconnected]
  )

  const data = useMemo(() => {
    if (!schemeQuery.data?.scheme) {
      return { nodes: [], edges: [] }
    }
    const res = buildNodes(
      filterDomainModel(schemeQuery.data.scheme as Scheme, domainFilterState),
      positions,
      domainFilterState.view
    )
    return res

    // NOTE: do not add positions dependencies here!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemeQuery.data?.scheme, domainFilterState])

  useEffect(() => {
    const filteredData = filterGraph(data, graphFilterState)
    setPositions(
      calculatePositionsBM({
        ...filteredData,
      })
    )
    setNodes(filteredData.nodes)
    setEdges(filteredData.edges)
    // NOTE: do not add dependencies here to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, graphFilterState])

  useEffect(() => {
    setNodes(nds =>
      nds.map(node => {
        const position = positions[node.id]
        if (position) {
          node.position = position
        }
        return node
      })
    )
    setTimeout(() => {
      utils.fitView()
    }, 200)
  }, [positions, setNodes, utils])

  useEffect(() => {
    setLoading(schemeQuery.loading)
  }, [schemeQuery.loading])

  useEffect(() => {
    setNodes((nds: Nodes) => {
      /**
       * apply highlights to nodes which have the same extension id as the selected one
       * this applies when you click on a node and it highlights all nodes with the same extension id
       */
      const selectedNodesExtNmbrs = selectedNodes.map(nd => nd.data.extensionNmbr)
      const highlights = nds
        .filter(nd => nd.type !== 'nmbr')
        .filter(nd => selectedNodesExtNmbrs.includes(nd.data.extensionNmbr))
        .map(nd => nd.id)

      return nds.map(node => {
        return {
          ...node,
          data: {
            ...node.data,
            highlight: highlights.includes(node.id),
          },
        }
      })
    })
  }, [selectedNodes, setNodes])

  return (
    <div className='flex size-full flex-col items-stretch'>
      {isStandAlone && (
        <NavBar>
          <span className='ml-8 flex gap-12 md:w-[500px]' slot='leading'>
            <span className='flex min-w-32 items-center gap-2' title={account?.name}>
              <Typography lineClamp={1} variant='body-2'>
                {account?.name}
              </Typography>
            </span>
          </span>
        </NavBar>
      )}

      <CallFlowBar
        account={!isStandAlone ? account?.name : undefined}
        disabled={loading}
        filter={filter}
        scheme={schemeQuery.data?.scheme as Scheme}
        onChange={handleFilterChange}
        onDownloadClick={onClick}
        onLayout={handleLayoutClick}
        onSchemeBrowse={handleBrowseClick}
        onSchemeChange={handleSchemeChange}
      />

      <div className='relative flex grow overflow-hidden'>
        {loading && <BlockingLoader />}
        <ReactFlow
          className='relative transition-all'
          edgeTypes={config.edgeTypes}
          edges={edges}
          nodeTypes={config.nodeTypes}
          nodes={nodes}
          proOptions={proOptions}
          snapGrid={[16, 16]}
          style={{
            marginRight: panel ? 0 : `-${panelWidth}`,
          }}
          fitView
          snapToGrid
          onEdgesChange={onEdgesChange}
          onInit={onInit}
          onNodeDoubleClick={handleNodeDoubleClick}
          onNodesChange={onNodesChange}
          onSelectionChange={handleOnSelectionChange}
        >
          <MiniMap pannable zoomable />
          <Controls />
          <Background gap={16} style={{ backgroundColor: 'rgb(var(--color-neutral-100))' }} />
        </ReactFlow>

        <Panel
          anchor='right'
          open={!!panel}
          style={{
            '--panel-width': panelWidth,
            '--panel-height': '100%',
          }}
          variant='persistent'
        >
          <span className='flex h-full flex-col'>{panel}</span>
        </Panel>
      </div>
    </div>
  )
}
const ReactFlowProvidedCallFlowView = (): JSX.Element => (
  <ReactFlowProvider>
    <CallFlow />
  </ReactFlowProvider>
)

export default ReactFlowProvidedCallFlowView
