import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Usr } from '@/types'

import FormValue from '../../../../components/FormValue'
import PanelLayout from '../../../../components/PanelLayout'
import { type PanelProps } from '../../../../types/nodes'
import { useCurrentAccount } from '../../../../utils/currentAccountUtils'

import { UserNodeData } from './UserNode'

export interface UserPanelProps extends PanelProps<UserNodeData> {}

const GET_EXTENSION = gql`
  query Extension($id: ID!, $accountId: ID!) {
    extension(id: $id, accountId: $accountId) {
      ... on Usr {
        type
        name
        extension
      }
    }
  }
`

export const UserPanel = ({
  node: {
    data: {
      extension: { extension_id: extensionId },
    },
  },
  onClose,
}: UserPanelProps) => {
  const { t } = useTranslation()
  const account = useCurrentAccount()

  const { data, loading } = useQuery<{ extension: Usr }>(GET_EXTENSION, {
    variables: {
      id: extensionId,
      accountId: account?.id,
    },
  })

  const extension = data?.extension

  return (
    <PanelLayout loading={loading} title={t('User')} onClose={onClose}>
      <PanelLayout.GroupHeading>{t('General')}</PanelLayout.GroupHeading>
      <FormValue label={t('Name')} value={extension?.name} />
      <FormValue label={t('Extension number')} value={extension?.extension} />
      <PanelLayout.GroupDivider />
      <PanelLayout.GroupHeading>{t('Configuration')}</PanelLayout.GroupHeading>
      <FormValue label={t('Assistant Group')} value={[{ label: t('Please choose'), value: '0' }]} isSelect />
      <FormValue label={t('Pincode')} value='0000' />

      <FormValue label={t('Areacode')} value='036' />
      <FormValue label={t('Offer second call')} isBoolean />
      <FormValue
        label={t('Language')}
        value={[
          {
            label: 'English',
            value: 'en',
          },
        ]}
        isSelect
      />
      <FormValue
        label={t('Fallback Emergency Caller ID')}
        value={[{ label: t('Please choose'), value: '0' }]}
        isSelect
      />
      <PanelLayout.GroupDivider />
      <PanelLayout.GroupHeading>{t('Voicemail')}</PanelLayout.GroupHeading>
      <FormValue
        label={t('Language')}
        value={[
          {
            label: 'English',
            value: 'en',
          },
        ]}
        isSelect
      />
      <FormValue label={t('Pincode')} value='0000' />
      <FormValue label={t('Pin Authentication')} value={[{ label: t('untrusted'), value: '0' }]} isSelect />
    </PanelLayout>
  )
}

export default UserPanel
