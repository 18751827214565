import type { JSX, ReactNode } from 'react'

import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client'

import { useAuthLink, useErrorLink } from './apolloLinks'

const uri = `${window.runtimeConfig?.API_URL as string}/graphql`

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
})

export const ApolloClientProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const errorLink = useErrorLink(apolloClient)
  const authLink = useAuthLink()
  const links = from([authLink, errorLink, new HttpLink({ uri })])
  apolloClient.setLink(links)

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
