/**
 * NOTE: must load config first to detect fallback
 */
import './config/ccl'
import './config/sentry'
import './index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'

import { ensureRuntimeConfig } from './config/ensureRuntimeConfig'
import { App } from './App'

ensureRuntimeConfig()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
