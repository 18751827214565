import { useTranslation } from 'react-i18next'

import { Destination, Forwards, Maybe } from '@/types'

import FormValue from '../components/FormValue'
import PanelLayout from '../components/PanelLayout'

import formatInternationalPhoneNumber from './phoneNumberUtils'

export function useDestinationFormValues(destination: Maybe<Destination> | undefined) {
  const { t } = useTranslation()

  const ret: {
    value: Maybe<string> | undefined
    option: Array<{ label: string; value: string }>
  } = {
    value: undefined,
    option: [],
  }

  if (destination) {
    const { __typename: destinationType } = destination

    if (destinationType === 'ExtensionDestination') {
      ret.value = destination.extension?.toString()
      ret.option = [{ label: t('Extension'), value: 'Extension' }]
    } else if (destinationType === 'NumberDestination') {
      ret.value = destination.number ? formatInternationalPhoneNumber(destination.number) : undefined
      ret.option = [{ label: t('Number'), value: 'Number' }]
    } else if (destinationType === 'FreeNumberDestination') {
      ret.value = formatInternationalPhoneNumber(destination.destination_number)
      ret.option = [{ label: t('Free Number'), value: 'Free Number' }]
    }
    return ret
  }
  return null
}

export const ForwardFields = ({ forwards }: { forwards?: Maybe<Forwards> }) => {
  const { t } = useTranslation()

  const externalAll = useDestinationFormValues(forwards?.external?.all)
  const externalBusy = useDestinationFormValues(forwards?.external?.busy)
  const externalNoanswer = useDestinationFormValues(forwards?.external?.noanswer)
  const internalAll = useDestinationFormValues(forwards?.internal?.all)
  const internalBusy = useDestinationFormValues(forwards?.internal?.busy)
  const internalNoanswer = useDestinationFormValues(forwards?.internal?.noanswer)

  return (
    <>
      <PanelLayout.GroupHeading>{t('Forward of external calls')}</PanelLayout.GroupHeading>

      {externalAll && (
        <>
          <FormValue label={t('Forward All to')} value={externalAll.option} isSelect />
          <FormValue label={t('Destination')} value={externalAll.value} />
        </>
      )}
      {externalBusy && (
        <>
          <FormValue label={t('Forward Busy to')} value={externalBusy.option} isSelect />
          <FormValue label={t('Destination')} value={externalBusy.value} />
        </>
      )}
      {externalNoanswer && (
        <>
          <FormValue label={t('Forward No Answer to')} value={externalNoanswer.option} isSelect />
          <FormValue label={t('Destination')} value={externalNoanswer.value} />
          <FormValue label={t('No Answer timeout (s)')} value={forwards?.external?.noanswer?.timeout ?? 0} />
        </>
      )}
      {forwards?.external?.callerid && (
        <FormValue
          label={t('External Caller ID')}
          value={[
            {
              label: t(forwards?.external?.callerid),
              value: forwards?.external?.callerid,
            },
          ]}
          isSelect
        />
      )}
      <PanelLayout.GroupDivider />
      <PanelLayout.GroupHeading>{t('Forward of internal calls')}</PanelLayout.GroupHeading>
      {internalAll && (
        <>
          <FormValue label={t('Forward All to')} value={internalAll.option} isSelect />
          <FormValue label={t('Destination')} value={internalAll.value} />
        </>
      )}
      {internalBusy && (
        <>
          <FormValue label={t('Forward Busy to')} value={internalBusy.option} isSelect />
          <FormValue label={t('Destination')} value={internalBusy.value} />
        </>
      )}
      {internalNoanswer && (
        <>
          <FormValue label={t('Forward No Answer to')} value={internalNoanswer.option} isSelect />
          <FormValue label={t('Destination')} value={internalNoanswer.value} />
          <FormValue label={t('No Answer timeout (s)')} value={forwards?.internal?.noanswer?.timeout ?? 0} />
        </>
      )}
      {forwards?.internal?.callerid && (
        <FormValue
          label={t('External Caller ID')}
          value={[
            {
              label: t(forwards?.internal?.callerid),
              value: forwards?.internal?.callerid,
            },
          ]}
          isSelect
        />
      )}
    </>
  )
}
