import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { generateLink, RoutePaths } from '../utils/routeUtiles'

export const SchemeFlowLink = ({
  accountId,
  children,
  highlight,
  schemeId: id,
  ...LinkProps
}: {
  accountId?: string
  schemeId: string
  highlight?: string
  children: React.ReactNode
}) => {
  const to = useMemo(
    () =>
      generateLink(RoutePaths.SCHEME_CALLFLOW, {
        accountId,
        id,
        highlight,
      }),

    [accountId, id, highlight]
  )

  return (
    <Link {...LinkProps} to={to}>
      {children}
    </Link>
  )
}

export const SchemeLink = ({
  accountId,
  children,
  schemeId: id,
  ...LinkProps
}: {
  accountId?: string
  schemeId: string
  highlight?: string
  children: React.ReactNode
}) => {
  const to = useMemo(() => generateLink(RoutePaths.SCHEME, { accountId: accountId ?? null, id }), [accountId, id])

  return (
    <Link {...LinkProps} to={to}>
      {children}
    </Link>
  )
}
