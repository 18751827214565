export const SECONDS_PER = {
  MINUTE: 60,
  HOUR: 3600,
  DAY: 86400,
  /**
   * NOTE: effectively 7 days minus 1 second
   * the reason for this is that the end of the week is considered to be 23:59:59 on Sunday
   */
  WEEK: 604799,
} as const

export const WEEK = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
} as const

export type DAYS = keyof typeof WEEK

/**
 *
 * @param time 24h time string
 * @returns
 */
export function timeToSeconds(time: string) {
  if (time === '24:00') {
    // eslint-disable-next-line no-param-reassign
    time = '23:59'
  }
  const [hours, minutes] = time.split(':').map(Number) as [number, number]
  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) throw new Error(`Invalid time: ${time}`)

  return hours * SECONDS_PER.HOUR + minutes * SECONDS_PER.MINUTE
}

/**
 *
 * @param day string day of the week
 * @param time 24h time string
 * @returns
 */
export function dayTimeToSeconds(day: DAYS, time: string) {
  return WEEK[day] * SECONDS_PER.DAY + timeToSeconds(time)
}

/**
 *
 */
export function isDateOverlap(date: Date, start: number, end: number): boolean {
  const momentInWeek = dateToSecondsInWeek(date)
  return momentInWeek >= start && momentInWeek <= end
}

/**
 * converts a date to number of seconds from the start of the week
 * this disregards year, month etc and looks at day and time only
 * @param date
 * @returns
 */
export function dateToSecondsInWeek(date: Date): number {
  if (!(date instanceof Date)) {
    throw new Error('Input must be a Date object')
  }
  const dayOfWeek = date.getDay()
  const adjustedDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1 // Adjust Sunday to be 6 instead of 0
  const days = adjustedDayOfWeek * SECONDS_PER.DAY
  const seconds =
    days +
    date.getHours() * SECONDS_PER.HOUR +
    date.getMinutes() * SECONDS_PER.MINUTE +
    date.getSeconds() +
    date.getMilliseconds() / 1000

  return seconds
}

/**
 * Converts the given number of seconds to a time string.
 * @param days: An array of seven strings representing the days of the week.
 */
export const convertSecondsToTimeString = (
  seconds: number,
  days: [string, string, string, string, string, string, string]
): string => {
  const day = Math.floor(seconds / SECONDS_PER.DAY)
  const hours = Math.floor((seconds % SECONDS_PER.DAY) / SECONDS_PER.HOUR)
  const minutes = Math.floor((seconds % SECONDS_PER.HOUR) / SECONDS_PER.MINUTE)

  const dayString = days[day % 7]

  const hourString = hours.toString().padStart(2, '0')
  const minuteString = minutes.toString().padStart(2, '0')

  return `${dayString} ${hourString}:${minuteString}`
}
