import type { JSX } from 'react'

import { useTranslation } from 'react-i18next'
import { type NodeProps } from 'reactflow'

import { FlowNodeAddonProps, NodeDataBase } from '../../../../types/nodes'
import { FlowNode } from '../FlowNode'

export interface AutoAnswerNodeData extends NodeDataBase {
  addons?: Array<
    {
      type: string
    } & FlowNodeAddonProps
  >
}

export const AutoAnswerNode = (props: NodeProps<AutoAnswerNodeData>): JSX.Element => {
  const { t } = useTranslation()
  const { data } = props
  return (
    <FlowNode
      {...props}
      body={{ children: data.title }}
      title={{
        icon: 'communication--call--voicemail',
        label: t('AutoAnswer'),
        nmbr: data.extensionNmbr,
      }}
    />
  )
}

export default AutoAnswerNode
