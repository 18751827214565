import { useTranslation } from 'react-i18next'
import { NodeProps } from 'reactflow'

import { NodeDataBase } from '../../../types/nodes'

import { FlowNode } from './FlowNode'

interface FreeNumberNodeProps extends NodeDataBase {}

export const FreeNumberNode = (props: NodeProps<FreeNumberNodeProps>) => {
  const { t } = useTranslation()
  return (
    <FlowNode
      {...props}
      body={{ children: props.data.destination_number }}
      title={{ icon: 'communication--device--phone', label: t('External Number') }}
    />
  )
}

export default FreeNumberNode
