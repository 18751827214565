import { type ApolloClient, type ApolloLink, type FetchResult, Observable } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import REFRESH_ACCESS_TOKEN from '../../graphql/users/mutations/refreshAccessToken.graphql'
import { type RefreshAccessTokenMutation, type RefreshAccessTokenMutationVariables } from '../../types/graphqlTypes'
import { useAccessToken, useLogout, useRefreshToken, useSaveAuthenticationToken } from '../../utils/authenticationUtils'

export const useErrorLink = (apolloClient: ApolloClient<object>): ApolloLink => {
  const { t } = useTranslation()
  const refreshToken = useRefreshToken()
  const logout = useLogout()
  const saveAuthenticationToken = useSaveAuthenticationToken()

  const errorLink: ApolloLink = onError(({ forward, graphQLErrors, networkError, operation }) => {
    if (networkError) {
      toast.error(t('Something went wrong during the request.'))
      console.error(`[Network error]: ${networkError}`)
      return
    }

    if (graphQLErrors) {
      for (const graphqlError of graphQLErrors) {
        if (graphqlError.extensions?.code === 401) {
          // ignore 401 error for a refresh request
          // NOTE: for some reason the operationname is capitalized
          if (operation.operationName === 'RefreshAccessToken') return

          const observable = new Observable<FetchResult<Record<string, object>>>(observer => {
            void (async () => {
              try {
                if (!refreshToken) {
                  throw new Error('Empty refresh token')
                }
                // refresh the token here, and retry the request
                const oldHeaders = operation.getContext().headers

                const { data } = await apolloClient.mutate<
                  RefreshAccessTokenMutation,
                  RefreshAccessTokenMutationVariables
                >({
                  mutation: REFRESH_ACCESS_TOKEN,
                  variables: { input: { refreshToken } },
                })
                if (data) {
                  saveAuthenticationToken(data.refreshAccessToken.authenticationToken)
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: `Bearer ${data.refreshAccessToken.authenticationToken.accessToken}`,
                    },
                  })
                  // Retry the failed request
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  }

                  return forward(operation).subscribe(subscriber)
                }
                throw new Error('Invalid refresh token')
              } catch (err) {
                observer.error(err)
                logout()
              }
            })()
          })

          return observable
        }
      }

      const errorString = graphQLErrors.map(error => error.message).join('\n')
      console.error(errorString)
      toast.error(t('Something went wrong during the request.'))
    }
  })

  return errorLink
}

export const useAuthLink = (): ApolloLink => {
  const accessToken = useAccessToken()
  const refreshToken = useRefreshToken()

  const authLink = setContext((request, context): { headers: Record<string, string> } => {
    const token = request.operationName === 'RefreshAccessToken' ? refreshToken : accessToken

    if (!token) {
      return { headers: context.headers as Record<string, string> }
    }

    return {
      headers: {
        ...(context.headers as Record<string, string>),
        authorization: `Bearer ${token}`,
      },
    }
  })

  return authLink
}
