import { jwtDecode } from 'jwt-decode'
import { type ContextType, useContext } from 'react'

import { type AuthenticationToken } from '@/types'

import { AuthenticationContext } from '../contexts/AuthenticationContext'

interface DecodedAccessToken {
  sub: string
  email: string
  exp: number
  account_id: string
}

export const useAuthenticationContext = (): ContextType<typeof AuthenticationContext> =>
  useContext(AuthenticationContext)

export const useAccessToken = (): string | null => {
  const { accessToken } = useAuthenticationContext()
  return accessToken
}

export const useIsAuthenticated = (): boolean => {
  const accessToken = useAccessToken()
  return Boolean(accessToken)
}

export const useRefreshToken = (): string | null => {
  const { refreshToken } = useAuthenticationContext()
  return refreshToken
}

const decodeToken = (token: string): DecodedAccessToken | null => {
  try {
    return jwtDecode<{ sub: string; email: string; exp: number; account_id: string }>(token)
  } catch {
    return null
  }
}

export const useDecodedAccessToken = (): DecodedAccessToken | null => {
  const accessToken = useAccessToken()

  if (!accessToken) {
    return null
  }

  const decodedAccessToken = decodeToken(accessToken)

  if (!decodedAccessToken) {
    return null
  }

  return decodedAccessToken
}

export const useSaveAuthenticationToken = (): ((authenticationToken: AuthenticationToken) => void) => {
  const { setAccessToken, setRefreshToken } = useAuthenticationContext()

  return (authenticationToken: AuthenticationToken) => {
    setAccessToken(authenticationToken.accessToken)
    setRefreshToken(authenticationToken.refreshToken)
  }
}

export const useLogout = (): (() => void) => {
  const { setAccessToken, setRefreshToken } = useAuthenticationContext()

  return () => {
    setAccessToken(null)
    setRefreshToken(null)
  }
}
