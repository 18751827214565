import { defineCustomElementClTextField } from '@enreach/core-component-library/dist/components/index'
import { ClTextField } from '@enreach/core-component-library-react'
import React, { forwardRef } from 'react'

defineCustomElementClTextField()

export type TextFieldProps = {
  onChange?: (event: Event) => void
} & Omit<React.ComponentProps<typeof ClTextField>, 'clChange'>

export const TextField = forwardRef<HTMLClTextFieldElement, TextFieldProps>(function TextField(props, ref) {
  return <ClTextField {...props} ref={ref} onClInput={props.onChange} />
})
