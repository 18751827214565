import type { ModuleConfig } from '../../../../types/nodes'

import Node from './ExtPickerNode'
import Panel from './ExtPickerPanel'

const callFlowModule: Partial<ModuleConfig> = {
  nodeTypes: {
    extensionpicker: Node,
  },
  panelTypes: {
    extensionpicker: Panel,
  },
}

export default callFlowModule
