import { Lightbox, Loader } from '@/ui'

export function BlockingLoader() {
  return (
    <Lightbox align='center' className='!absolute !h-full !w-full' blurred open>
      <Loader size={15} thickness={9} circular indeterminate />
    </Lightbox>
  )
}

export default BlockingLoader
