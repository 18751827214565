import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Forward } from '@/types'

import FormValue from '../../../../components/FormValue'
import PanelLayout from '../../../../components/PanelLayout'
import { type PanelProps } from '../../../../types/nodes'
import { useCurrentAccount } from '../../../../utils/currentAccountUtils'
import { useDestinationFormValues } from '../../../../utils/extensionFormFieldHelpers'

import { ForwardNodeData } from './ForwardNode'

export interface ForwardPanelProps extends PanelProps<ForwardNodeData> {}

const GET_EXTENSION = gql`
  query Extension($id: ID!, $accountId: ID!) {
    extension(id: $id, accountId: $accountId) {
      ... on Forward {
        type
        name
        destination {
          ... on ExtensionDestination {
            extension_id
            extension
            prefix
          }
          ... on NumberDestination {
            number_id
            number
          }
          ... on FreeNumberDestination {
            destination_number
            timeout
          }
        }
      }
    }
  }
`

export const ForwardPanel = ({
  node: {
    data: {
      extension: { extension_id: extensionId },
    },
  },
  onClose,
}: ForwardPanelProps) => {
  const { t } = useTranslation()
  const account = useCurrentAccount()

  const { data, loading } = useQuery<{ extension: Forward }>(GET_EXTENSION, {
    variables: {
      id: extensionId,
      accountId: account?.id,
    },
  })

  const extension = data?.extension
  const destination = useDestinationFormValues(extension?.destination)

  return (
    <PanelLayout loading={loading} title={t('Forward')} onClose={onClose}>
      <PanelLayout.GroupHeading>{t('General')}</PanelLayout.GroupHeading>
      <FormValue label={t('Name')} value={extension?.name} />
      <PanelLayout.GroupDivider />
      <FormValue label={t('Destination')} value={destination?.option} isSelect />
      <FormValue label='Destination' value={destination?.value} />
    </PanelLayout>
  )
}

export default ForwardPanel
