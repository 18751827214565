import { allCountries } from 'country-telephone-data'
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'

function formatInternationalPhoneNumber(phoneNumber: string): string | null {
  const countryCode = phoneNumber.slice(0, 2) // Extract the country code

  // Find the country data for this country code
  const countryData = allCountries.find(country => country.dialCode === countryCode)

  if (countryData) {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, countryData.iso2.toUpperCase() as CountryCode)
    return parsedPhoneNumber?.formatInternational() || null
  }

  return phoneNumber
}

export default formatInternationalPhoneNumber
