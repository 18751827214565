import { type JSX, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDebounceCallback } from 'usehooks-ts'

import { Dropdown, DropdownGroup, DropdownItem, Icon, TextField } from '@/ui'

import { useNmbrsByCategoriesLazyQuery } from '../types/graphqlOperations'
import { useCurrentAccount } from '../utils/currentAccountUtils'
import { generateLink, RoutePaths } from '../utils/routeUtiles'

export const NmbrSearchField = (): JSX.Element => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const navigateTo = useNavigate()
  const [fn, results] = useNmbrsByCategoriesLazyQuery()
  const account = useCurrentAccount()

  const debouncedSearch = useDebounceCallback((searchPattern, accountId) => {
    void fn({
      variables: {
        searchPattern,
        category1: 'Allocated',
        category2: 'Available',
        accountId,
      },
    })
  }, 500)

  const handleClick = useCallback(
    (schemeId: string | undefined, highlight: string) => {
      if (!account) {
        console.error('Account is not defined')
        return
      }
      navigateTo(
        generateLink(
          RoutePaths.SCHEME_CALLFLOW,
          {
            accountId: account.id,
            id: schemeId,
          },
          { highlight }
        )
      )
    },
    [account?.id, navigateTo]
  )

  return (
    <div className='relative'>
      <TextField
        disabled={!account}
        placeholder={t('Type in the number')}
        processing={results.loading}
        value={searchText}
        onChange={event => {
          const term = (event as unknown as { detail: { value: string } }).detail.value
          setSearchText(term)
          debouncedSearch(term, account?.id)
        }}
      >
        <span slot='leading'>
          <Icon name='action--search' />
        </span>
      </TextField>
      {results.data && !results.loading && (
        <Dropdown fullWidth open>
          <DropdownGroup key={1} label={t('Allocated')}>
            {results.data.category1.items.map((nmbr, index) => (
              <DropdownItem
                key={index}
                label={nmbr.value}
                subtext={nmbr.scheme?.name}
                value={`${index}`}
                onClSelect={() => {
                  handleClick(nmbr.scheme?.id, `number:${nmbr.value}`)
                }}
              >
                <Icon fillColor='rgba(var(--color-brand-main))' name='action--edit--alt' slot='leading'></Icon>
              </DropdownItem>
            ))}
          </DropdownGroup>

          <DropdownGroup key={2} label={t('Available')}>
            {results.data.category2.items.map((nmbr, index) => (
              <DropdownItem key={index} label={nmbr.value} value={nmbr.value} disabled>
                <Icon name='action--edit--alt' slot='leading'></Icon>
              </DropdownItem>
            ))}
          </DropdownGroup>
        </Dropdown>
      )}
    </div>
  )
}

export default NmbrSearchField
