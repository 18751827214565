import type { JSX } from 'react'

import { useTranslation } from 'react-i18next'
import { type NodeProps } from 'reactflow'

import { FlowNodeAddonProps, NodeDataBase } from '../../../../types/nodes'
import { FlowNode } from '../FlowNode'

export interface LBRNodeData extends NodeDataBase {
  addons?: Array<
    {
      type: 'fallback' | 'fallbackAnonymous'
    } & FlowNodeAddonProps
  >
}

export const LBRNode = (props: NodeProps<LBRNodeData>): JSX.Element => {
  const { t } = useTranslation()
  const { data } = props
  return (
    <FlowNode
      {...props}
      addons={data.addons?.reduce<FlowNodeAddonProps[]>((prev, AddonProps) => {
        if (AddonProps.type === 'fallback') {
          return [
            ...prev,
            {
              icon: 'communication--call--forward--no--answer',
              label: t('On Default Forward'),
              ...AddonProps,
            },
          ]
        } else if (AddonProps.type === 'fallbackAnonymous') {
          return [
            ...prev,
            {
              icon: 'action--block',
              label: t('Blocked Caller ID Forward'),
              ...AddonProps,
            },
          ]
        }

        return prev
      }, [])}
      body={{ children: data.title }}
      title={{
        icon: 'communication--call--split',
        label: t('Area code router'),
        nmbr: data.extensionNmbr,
      }}
    />
  )
}

export default LBRNode
