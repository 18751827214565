import type { JSX } from 'react'

import { Dropdown, DropdownItem, IconButton, SelectField } from '@/ui'

interface Props {
  next?: () => void
  prev?: () => void
  page: number
  pageSize: number
  totalPages: number
  onPage?: (page: number) => void
  onPageSize?: (pageSize: number) => void
}

export const PaginationControls = ({
  next,
  onPage,
  onPageSize,
  page,
  pageSize,
  prev,
  totalPages,
}: Props): JSX.Element => {
  return (
    <>
      <SelectField
        style={{ '--field-width': 'content' }}
        value={[{ label: pageSize.toString(), value: pageSize.toString() }]}
        closeOnSelect
        onClChange={e => {
          console.debug('changed', e)
          // NOTE: intentionally becase value[0] should be always defined
          onPageSize?.(+(e.detail.value[0]?.value || 5))
        }}
      >
        <Dropdown>
          <DropdownItem label='5' value='5' />
          <DropdownItem label='10' value='10' />
        </Dropdown>
      </SelectField>

      <IconButton disabled={!prev} iconName='micro--chevron--left' variant='tertiary' onClick={prev} />
      {Array.from({ length: totalPages }, (_, i) => i + 1).map(p => (
        <IconButton key={p} disabled={p === page} variant='tertiary' onClick={() => onPage?.(p)}>
          {p}
        </IconButton>
      ))}
      <IconButton disabled={!next} iconName='micro--chevron--right' variant='tertiary' onClick={next} />
    </>
  )
}

export const DEFAULT_PAGE_SIZE = 10
