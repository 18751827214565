import {
  isValidElement,
  type JSX,
  type MouseEventHandler,
  type ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Handle, type HandleProps, Position } from 'reactflow'
import { useClickAnyWhere } from 'usehooks-ts'

import { cn, Divider, Icon, Typography } from '@/ui'

import { AddonStates, FlowNodeAddonProps, FlowNodeProps, NodeBodyProps, NodeTitleProps } from '../../../types/nodes'

// Type guard for ReactElement
function isReactElement(element: unknown): element is ReactElement<any> {
  return isValidElement(element)
}

/**
 * FlowNode is meant as layout element which can be used to create the look&feel
 * for the callflow graph. The FlowNode ought to used inside the components used
 * as the mapped nodetypes for the reactflow graph. This setup restults in a component graph:
 * <ReactFlowNodeWrapper {proptypes: Node<T,K>}>
 *    <[ConcreteMappedNodeComponent] {proptypes: NodeProps<T>}>
 *      <FlowNode {proptypes: flownode props}>
 *
 * where:
 * - T is the type of the node Data and K identifier string used for mapping.
 * - ConcreteMappedNodeComponent is the component which defines the concrete data used
 * for the specific node type.
 *
 * FlowNode has some magic to it, because it allows its compositional component props to be
 * either props or actual React Elements. This is done to allow for a more flexible usage
 * because this way the concrete node component can define Elements when it needs to do
 * special event handling etc while without that need it can simply pass the props to the
 * FlowNode component and let it handle the rendering.
 *
 */
export const FlowNode = ({ addons, body, data, isRoot, selected, title }: FlowNodeProps) => {
  const handleElement = useMemo(() => {
    if (isRoot) {
      return null
    }
    return <FlowNodeHandle position={Position.Left} type='target' />
  }, [isRoot])

  const doubleClick = useCallback(
    (addon: FlowNodeAddonProps) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const eventWithAddon = event as React.MouseEvent<HTMLDivElement, MouseEvent> & { addon: FlowNodeAddonProps }
      eventWithAddon.addon = addon
    },
    []
  )

  const addonsEl = useMemo(() => {
    if (addons && isReactElement(addons)) {
      return addons
    }
    if (addons) {
      return addons.map((addon, index) => {
        return <FlowNodeAddon {...addon} key={index} onDoubleClick={doubleClick(addon)} />
      })
    }
    return null
  }, [addons, doubleClick])

  return (
    <div className='min-w-40 max-w-40'>
      <div
        className={cn(
          'bg-[rgb(var(--surface-color-contrast))]',
          selected && 'bg-[rgb(var(--color-brand-100))]',
          !selected && data.highlight && 'bg-[rgb(var(--color-brand-50))]',
          'box-border',
          'relative',
          "after:content-[' ']",
          'after:shadow-md',
          'after:shadow-[rgba(var(--color-neutral-black),0.1)]',
          'after:box-border',
          'after:absolute',
          'after:top-[0]',
          'after:left-[0]',
          'after:bottom-[0]',
          'after:w-full',
          'after:box-border',
          'after:z-30',
          'after:rounded-[var(--border-radius-small)]',
          'rounded-[var(--border-radius-small)] border-[rgb(var(--color-neutral-300))]',
          // state === 'error' && 'border-[rgb(var(--system-color-main-error))]',
          // state === 'warning' && 'border-[rgb(var(--system-color-main-error))]',
          selected && 'border-[rgb(var(--behaviour-selected-primary))]'
        )}
      >
        {isReactElement(title) ? title : <NodeTitle selected={selected} {...title} />}
        <Divider
          className='px-1.5'
          color={selected ? 'rgb(var(--color-brand-200))' : 'rgb(var(--color-neutral-100))'}
          spacing={0}
        />
        {isReactElement(body) ? body : <NodeBody {...body} />}
      </div>
      {addonsEl}
      {handleElement}
    </div>
  )
}

export const NodeBody = (props: NodeBodyProps): JSX.Element => {
  return <Typography className={cn('flex flex-col py-2 px-2', props.className)} variant='subtitle-2' {...props} />
}

export function NodeTitle({ icon, iconColor, label, nmbr, selected }: NodeTitleProps) {
  return (
    <div className='flex items-center gap-1 px-1.5 py-1'>
      <div className='flex items-center'>
        {typeof icon === 'string' ? (
          <Icon fillColor={iconColor ?? 'rgb(var(--color-neutral-700))'} name={icon} size={3} />
        ) : (
          icon
        )}
      </div>
      <div className='flex flex-1 overflow-hidden'>
        {typeof label === 'string' ? (
          <Typography
            style={{
              '--typography-color': 'rgb(var(--color-neutral-700))',
            }}
            noWrap
          >
            {label}
          </Typography>
        ) : (
          label
        )}
      </div>
      {nmbr && (
        <div
          className={cn(
            selected ? 'bg-[rgb(var(--color-brand-200))]' : 'bg-[rgb(var(--color-neutral-100))]',
            'flex items-center p-1',
            'text-[rgb(var(--color-neutral-500))]',
            'rounded-[var(--border-radius-small)]'
          )}
        >
          <Typography variant='body-2'>{nmbr}</Typography>
        </div>
      )}
    </div>
  )
}

const AddonStateStylesMap = {
  [AddonStates.Invalid]:
    '!bg-[rgb(var(--system-color-info-yellow-light))] before:!bg-[rgb(var(--system-color-info-yellow-light))]',
  [AddonStates.Highlighted]: '!bg-[rgb(var(--color-brand-100))] before:!bg-[rgb(var(--color-brand-100))]',
}

export function FlowNodeAddon({
  className,
  handle,
  highlight,
  icon,
  id,
  invalid,
  label,
  onDoubleClick,
  onSelected,
}: FlowNodeAddonProps) {
  const [selected, setSelected] = useState(false)
  const nodeRef = useRef<HTMLDivElement>(null)

  const handleEl = useMemo(() => {
    if (handle === undefined) {
      return <FlowNodeHandle id={id} />
    } else if (isReactElement(handle)) {
      return handle
    } else if (typeof handle === 'object') {
      return <FlowNodeHandle {...handle} id={id} />
    }
    return null
  }, [handle, id])

  const handleOnClick: MouseEventHandler<HTMLDivElement> = () => {
    setSelected(true)
    onSelected?.()
  }

  useClickAnyWhere((event: Event) => {
    if (nodeRef.current && event.target instanceof Node) {
      const sel = !!nodeRef.current?.contains(event.target)
      setSelected(sel)
    }
  })

  const handleDoubleClick: MouseEventHandler<HTMLDivElement> = event => {
    setSelected(true)
    onDoubleClick?.(event)
  }

  return (
    <div
      ref={nodeRef}
      className={cn(
        className,
        'box-border',
        'relative',
        'bg-[rgb(var(--color-accent-50))]',
        'rounded-b-[var(--border-radius-small)]',
        'before:h-[3rem]',
        'before:-top-4',
        'before:-z-10',
        'before:bg-[rgb(var(--color-accent-50))]',
        'before:box-border',
        "before:content-[' ']",
        'before:absolute',
        'before:w-full',
        'before:left-[0]',
        'after:shadow-md',
        'after:shadow-[rgba(var(--color-neutral-black),0.1)]',
        'after:top-[0]',
        'after:bottom-[0]',
        'after:z-30',
        'after:rounded-b-[var(--border-radius-small)]',
        'after:box-border',
        "after:content-[' ']",
        'after:absolute',
        'after:w-full',
        'after:left-[0]',
        'hover:cursor-pointer',
        selected && AddonStateStylesMap[AddonStates.Highlighted],
        highlight && AddonStateStylesMap[AddonStates.Highlighted],
        invalid && AddonStateStylesMap[AddonStates.Invalid]
      )}
      onClick={handleOnClick}
      onDoubleClick={handleDoubleClick}
    >
      <NodeTitle
        icon={icon}
        iconColor={'rgb(var(--color-neutral-500))'}
        label={label}
        selected={!!(highlight ?? selected)}
      />
      {handleEl}
    </div>
  )
}

export interface FlowNodeHandleProps extends Partial<HandleProps> {
  className?: string
}

export function FlowNodeHandle(props: FlowNodeHandleProps) {
  return (
    <Handle
      {...props}
      className={cn(
        props.className,
        props.position === Position.Left && '!top-[53px]',
        '!-z-30 !h-1 !w-1 !rounded-full !border-none !bg-[rgb(var(--color-brand-main))]'
      )}
      position={props.position ?? Position.Right}
      type={props.type ?? 'source'}
    />
  )
}

FlowNode.Body = NodeBody
FlowNode.Addon = FlowNodeAddon
FlowNode.Handle = FlowNodeHandle
