import { debounce } from 'lodash'
import { type JSX, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SortOrder } from '@/types'
import {
  Avatar,
  Card,
  Icon,
  IconButton,
  List,
  ListItem,
  ScrollWrapper,
  TextField,
  TextFieldProps,
  Typography,
} from '@/ui'

import { useGetAccountsLazyQuery } from '../types/graphqlOperations'
import { useCurrentUser } from '../utils/currentUserUtils'
import { generateLink, RoutePaths } from '../utils/routeUtiles'

export const AccountsView = (): JSX.Element => {
  const { t } = useTranslation()
  const [term, setTerm] = useState<string>('')
  const navigate = useNavigate()
  const user = useCurrentUser()
  const [getAccounts, results] = useGetAccountsLazyQuery()

  const debouncedSearch = useCallback(async (searchPattern: string | undefined, accountId: string) => {
    const debounceSeach = debounce(async (searchPattern, accountId) => {
      console.debug('debounced search:', searchPattern, accountId)
      void (await getAccounts({
        variables: {
          accountId,
          sort: { field: 'name', order: SortOrder.Asc },
          filter: {
            name: searchPattern,
          },
        },
      }))
    }, 500)

    await debounceSeach(searchPattern, accountId)
  }, [])

  const handleSearch = useCallback(
    (event: Parameters<NonNullable<TextFieldProps['onClChange']>>[0]) => {
      if (!user) return

      const val = (event as unknown as { detail: { value: string } }).detail.value
      console.debug('searching for:', event.detail)
      setTerm(val)
      void debouncedSearch(val, user?.accountId)
    },
    [user?.accountId]
  )

  const handleAccountClick = useCallback((accountId: string) => {
    navigate(generateLink(RoutePaths.ACCOUNT, { accountId }))
  }, [])

  const list = useMemo(() => {
    return (
      <List className='w-full'>
        {results.data?.accounts.accounts.map(account => (
          <ListItem
            key={account.id}
            primaryText={account.name}
            secondaryText={account.accountNumber}
            onClick={() => {
              handleAccountClick(account.id || '')
            }}
          >
            <Avatar initials={account.name.substring(0, 2)} slot='leading' />
            <IconButton iconName='navigation--arrow--right' slot='trailing' variant='secondary'></IconButton>
          </ListItem>
        ))}
      </List>
    )
  }, [results.data])

  useEffect(() => {
    if (user?.accountId) {
      void debouncedSearch(undefined, user.accountId)
    }
  }, [user?.accountId])

  useEffect(() => {
    document.title = t('Select Account')
  }, [t])

  return (
    <Card className='relative flex size-full flex-col items-start justify-center gap-2 md:w-3/4 md:flex-row'>
      <div className='flex size-full flex-col gap-2 md:h-[calc(-480px+100vh)]'>
        <div className='flex w-full'>
          <Typography variant='h6'>{t('Select Account')}</Typography>
        </div>

        <div className='w-full'>
          <TextField
            placeholder={t('Find Account')}
            processing={results.loading}
            value={term}
            fullWidth
            onClChange={handleSearch}
          >
            <Icon name='action--search' slot='leading'></Icon>
          </TextField>
        </div>
        {!results.loading && !results.data?.accounts.accounts.length && (
          <Typography className='text-center' variant='h5'>
            {t('No accounts found')}
          </Typography>
        )}
        <div className='w-full overflow-hidden'>
          <ScrollWrapper className='md:max-h-full'>{list}</ScrollWrapper>
        </div>
      </div>
    </Card>
  )
}

export default AccountsView
