import React, { type JSX } from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'

import { ApolloClientProvider } from './components/apollo/ApolloProvider'
import { AppModeContextProvider } from './contexts/AppModeContextProvider'
import { AuthenticationContextProvider } from './contexts/AuthenticationContext'
import { CurrentUserContextProvider } from './contexts/CurrentUserContext'
import { LanguageContextProvider } from './contexts/LanguageContext'
import { Routes } from './Routes'

/**
 *This is the root component of the application.
 * NOTE: the order of the context providers is important.
 */
export const App = (): JSX.Element | null => {
  return window.runtimeConfig?.noConfig ? (
    <NoConfigFound />
  ) : (
    <BrowserRouter>
      <AppModeContextProvider>
        <React.Suspense fallback='Loading'>
          <LanguageContextProvider>
            <AuthenticationContextProvider>
              <ApolloClientProvider>
                <CurrentUserContextProvider>
                  <Routes />
                  <Toaster position='top-right' />
                </CurrentUserContextProvider>
              </ApolloClientProvider>
            </AuthenticationContextProvider>
          </LanguageContextProvider>
        </React.Suspense>
      </AppModeContextProvider>
    </BrowserRouter>
  )
}

export const NoConfigFound = () => {
  return (
    <div className='flex h-screen items-center justify-center bg-neutral-100'>
      <div className='mx-auto max-w-sm rounded-lg bg-white p-10 text-center shadow-md'>
        <h2 className='mb-5 text-xl font-semibold text-neutral-800'>No Configuration Found</h2>
        <p className='text-neutral-600'>
          Oops! No configuration found. Please ensure the configuration file is correctly placed.
        </p>
      </div>
    </div>
  )
}
