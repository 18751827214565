import type { JSX } from 'react'

import { useTranslation } from 'react-i18next'

import FormValue from '../../../../components/FormValue'
import PanelLayout from '../../../../components/PanelLayout'
import { useGetNmbrQuery } from '../../../../types/graphqlOperations'
import { type PanelProps } from '../../../../types/nodes'
import { useCurrentAccount } from '../../../../utils/currentAccountUtils'
import formatInternationalPhoneNumber from '../../../../utils/phoneNumberUtils'

import { NmbrNodeData } from './NmbrNode'

export interface NmbrPanelProps extends PanelProps<NmbrNodeData> {}

export const NmbrPanel = ({ node, onClose }: NmbrPanelProps): JSX.Element => {
  const account = useCurrentAccount()
  const { t } = useTranslation()

  // TODO: Retrieve more number information (account, status, etc.)
  const { data, loading } = useGetNmbrQuery({
    skip: !account || !node.id,
    ...(account && {
      variables: {
        id: node.id,
        accountId: account?.id,
      },
    }),
  })

  return (
    <PanelLayout loading={loading} title={t('Number')} onClose={onClose}>
      <PanelLayout.GroupHeading>{t('General')}</PanelLayout.GroupHeading>{' '}
      <FormValue
        label={t('Name')}
        value={(data?.number.value && formatInternationalPhoneNumber(data?.number.value)) ?? data?.number.value}
      />
      {
        // <FormValue label={t('Account')} value={data?.number.account ?? undefined} />
        // <FormValue label={t('Status')} value={data?.number.status} />
        // <FormValue label={t('Country')} value={data?.number.country} />
        // <FormValue label={t('Operator')} value={data?.number.operator} />
        // <PanelLayout.GroupDivider />
        // <PanelLayout.GroupHeading>{t('Directory Listing')}</PanelLayout.GroupHeading>
        // <FormValue label={t('Name')} value={data?.number.name?.toString()} />
        // <FormValue label={t('Address')} value={data?.number.address?.toString()} />
        // <FormValue label={t('Listing')} value={data?.number.listing?.toString()} />
      }
    </PanelLayout>
  )
}

export default NmbrPanel
