import { type JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { RoutePaths } from '../utils/routeUtiles'

export const TermsAndPrivacy = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className='mt-4 flex justify-center'>
      <NavLink className='mx-4 text-neutral-900' target='_blank' to={RoutePaths.TERMS_AND_CONDITIONS}>
        {t('Terms and Conditions')}
      </NavLink>
      <NavLink className='mx-4 text-neutral-900' target='_blank' to={RoutePaths.PRIVACY_POLICY}>
        {t('Privacy Policy')}
      </NavLink>
    </div>
  )
}
