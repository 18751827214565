import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Extensionpicker } from '@/types'

import FormValue from '../../../../components/FormValue'
import PanelLayout from '../../../../components/PanelLayout'
import { type PanelProps } from '../../../../types/nodes'
import { useCurrentAccount } from '../../../../utils/currentAccountUtils'

import { ExtPickerNodeData } from './ExtPickerNode'

interface ExtPickerPanelProps extends PanelProps<ExtPickerNodeData> {}

const GET_EXTENSION = gql`
  query Extension($id: ID!, $accountId: ID!) {
    extension(id: $id, accountId: $accountId) {
      ... on ExtPicker {
        type
        name
        extension
      }
    }
  }
`

export const ExtPickerPanel = ({
  node: {
    data: {
      extension: { extension_id: extensionId },
    },
  },
  onClose,
}: ExtPickerPanelProps) => {
  const { t } = useTranslation()
  const account = useCurrentAccount()

  const { data, loading } = useQuery<{ extension: Extensionpicker }>(GET_EXTENSION, {
    variables: {
      id: extensionId,
      accountId: account?.id,
    },
  })

  const extension = data?.extension
  return (
    <PanelLayout loading={loading} title={t('Extension Picker')} onClose={onClose}>
      <PanelLayout.GroupHeading>{t('General')}</PanelLayout.GroupHeading>
      <FormValue label={t('Name')} value={extension?.name} />
      <FormValue label={t('Extension number')} value={extension?.extension} />
      <PanelLayout.GroupDivider />
      <PanelLayout.GroupHeading>{t('Configuration')}</PanelLayout.GroupHeading>
      <FormValue label={t('Audio file')} value='ExtPicker.mp3' isAudiofile />
      <FormValue label={t('Ringback tone enabled')} value={true} isBoolean />
      <FormValue
        label={t('Fallback Extension')}
        value={[
          {
            label: 'SomeExtension',
            value: '1291fb48-cd22-4e44-bd0d-1561b9daee9e',
          },
        ]}
        isSelect
      />
      <FormValue label={t('Fallback timeout (s)')} value={5} />
    </PanelLayout>
  )
}

export default ExtPickerPanel
