import { type ContextType, useContext } from 'react'

import { CurrentAccountContext } from '../contexts/CurrentAccountContext'
import { type AccountFragment } from '../types/graphqlTypes'

// import { type AccountFragment } from '../types/graphqlTypes'

export const useCurrentAccountContext = (): ContextType<typeof CurrentAccountContext> =>
  useContext(CurrentAccountContext)

export const useCurrentAccount = (): AccountFragment | null => {
  const { currentAccount } = useCurrentAccountContext()

  if (!currentAccount) return null

  return currentAccount
}
