import type { ModuleConfig } from '../../../../types/nodes'

import Node from './AutoAnswerNode'
import Panel from './AutoAnswerPanel'

const callFlowModule: Partial<ModuleConfig> = {
  nodeTypes: {
    autoanswer: Node,
  },
  panelTypes: {
    autoanswer: Panel,
  },
}

export default callFlowModule
