import BrandLibrary, { type Brand } from '@enreach/brand-library'

const brandLibrary = new BrandLibrary()

export const brandName: Brand = (window.runtimeConfig?.BRAND_NAME || 'neutral') as Brand

brandLibrary.setBrand(brandName).catch(console.error)

export function lightTheme(): void {
  brandLibrary.setTokens(brandName, 'light').catch(console.error)
}

export function darkTheme(): void {
  brandLibrary.setTokens(brandName, 'dark').catch(console.error)
}

export default brandLibrary
