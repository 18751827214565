import type { JSX } from 'react'

import { Navigate, Outlet, Route, Routes as ReactRouterDomRoutes } from 'react-router-dom'

import { Loader, Typography } from '@/ui'

import { CurrentAccountContextProvider } from './contexts/CurrentAccountContext'
import { useIsAuthenticated } from './utils/authenticationUtils'
import { useCurrentUserContext } from './utils/currentUserUtils'
import { generateLink, RoutePaths } from './utils/routeUtiles'
import AccountLandingView from './views/AccountLandingView'
import AccountsView from './views/AccountsView'
import BrowseNmbrsMainView from './views/BrowseNmbrsMainView'
import BrowseSchemesMainView from './views/BrowseSchemesMainView'
import SchemeCallFlowView from './views/CallFlow/CallFlow'
import LoginView from './views/LoginView'
import LogOutView from './views/LogOutView'
import MainLayout from './views/MainLayout'
import SchemeDetailMainView from './views/SchemeDetailView'

const ProtectedRoutes = (): JSX.Element => {
  const isAuthenticated = useIsAuthenticated()
  if (!isAuthenticated) {
    return <Navigate to={RoutePaths.LOGIN} replace />
  }

  return (
    <CurrentAccountContextProvider>
      <Outlet />
    </CurrentAccountContextProvider>
  )
}

const WholeSalerOnlyProtectedRoutes = (): JSX.Element => {
  const { currentUser: user, error, loading } = useCurrentUserContext()

  if (user && user.isWholeSaler) {
    return <Outlet />
  } else if (!user || loading || error) {
    return (
      <div className='flex size-full items-center justify-center'>
        <div className='flex flex-col items-center'>
          <Loader error={!!error} indeterminate={!error} size={16} thickness={10} circular>
            {error ? (
              <Typography slot='label' surface='light' variant='h5'>
                !!!
              </Typography>
            ) : undefined}
          </Loader>
          <Typography className='pt-3' slot='label' variant='h5'>
            {error ? 'Something went wrong' : 'Loading'}
          </Typography>
        </div>
      </div>
    )
  }
  return <Navigate to={generateLink(RoutePaths.ACCOUNT, { accountId: user.accountId })} replace />
}

export const Routes = (): JSX.Element => {
  return (
    <ReactRouterDomRoutes>
      <Route path={RoutePaths.MAIN}>
        <Route element={<LoginView />} path={RoutePaths.LOGIN} />
        <Route element={<LogOutView />} path={RoutePaths.LOGOUT} />

        <Route element={<ProtectedRoutes />}>
          <Route element={<WholeSalerOnlyProtectedRoutes />}>
            <Route element={<MainLayout />}>
              <Route element={<AccountsView />} index />
            </Route>
          </Route>

          <Route element={<MainLayout />}>
            <Route path={RoutePaths.ACCOUNT}>
              <Route element={<AccountLandingView />} index />
              <Route element={<BrowseSchemesMainView />} path={RoutePaths.SCHEMES} />
              <Route element={<SchemeDetailMainView />} path={RoutePaths.SCHEME} />
              <Route element={<BrowseNmbrsMainView />} path={RoutePaths.NUMBERS} />
            </Route>
          </Route>
          <Route element={<SchemeCallFlowView />} path={RoutePaths.SCHEME_CALLFLOW} />
        </Route>
      </Route>
      <Route element={<Navigate to={RoutePaths.MAIN} replace />} path='*' />
    </ReactRouterDomRoutes>
  )
}
