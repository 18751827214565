import type { JSX } from 'react'

import { useTranslation } from 'react-i18next'
import { type NodeProps } from 'reactflow'

import { FlowNodeAddonProps, NodeDataBase } from '../../../../types/nodes'
import { FlowNode } from '../FlowNode'

export interface GroupNodeData extends NodeDataBase {
  addons?: Array<
    {
      type: 'noAnswerForward' | 'busyForward' | 'member' | 'allForward'
      internal?: boolean
    } & FlowNodeAddonProps
  >
}

export const GroupNode = (props: NodeProps<GroupNodeData>): JSX.Element => {
  const { t } = useTranslation()
  const { data } = props
  return (
    <FlowNode
      {...props}
      addons={data.addons?.reduce<FlowNodeAddonProps[]>((prev, { internal, ...AddonProps }) => {
        if (AddonProps.type === 'member') {
          return [
            ...prev,
            {
              icon: 'generic--user--user',
              label: t('Group Member'),
              ...AddonProps,
            },
          ]
        } else if (AddonProps.type === 'noAnswerForward') {
          return [
            ...prev,
            {
              icon: 'communication--call--forward--no--answer',
              label: internal ? t('No Answer Forward (Internal)') : t('No Answer Forward (External)'),
              ...AddonProps,
            },
          ]
        } else if (AddonProps.type === 'busyForward') {
          return [
            ...prev,
            {
              icon: 'communication--call--forward--busy',
              label: internal ? t('On Busy Forward (Internal)') : t('On Busy Forward (External)'),
              ...AddonProps,
            },
          ]
        } else if (AddonProps.type === 'allForward') {
          return [
            ...prev,
            {
              icon: 'communication--call--forward--unconditional',
              label: internal ? t('Forward All (Internal)') : t('Forward All (External)'),
              ...AddonProps,
            },
          ]
        }
        console.warn(`Unknown addon type: ${AddonProps.type} passed in ${props.type} data `, data)

        return prev
      }, [])}
      body={{ children: data.title }}
      title={{
        icon: 'generic--user--user--group',
        label: t('Group'),
        nmbr: data.extensionNmbr,
      }}
    />
  )
}

export default GroupNode
