import type { Destination, Forwards, IvrOption, Mapping, NmbrPlan, Usr } from '@/types'

import { ComponentPropsWithoutRef, ComponentType, MouseEventHandler, ReactElement } from 'react'
import { EdgeTypes, HandleProps, type Node, NodeTypes } from 'reactflow'

import { Typography } from '@/ui'

export enum Modules {
  forward = 'forward',
  grp = 'grp',
  ivr = 'ivr',
  lbr = 'lbr',
  nmbr = 'nmbr',
  queue = 'queue',
  tbr = 'timeplan',
  user = 'user',
  voicemail = 'voicemail',
  autoanswer = 'autoanswer',
  extpicker = 'extensionpicker',
  bot = 'bot',
}

export interface NodeDataBase {
  extensionNmbr?: number
  title?: string
  id?: string
  highlight?: string
  extension: {
    extension_id: string
  }
}

export interface PanelProps<T = any> {
  /**
   * node contains all we need for fetching the correct info in the panel implementation
   */
  node: Node<T>

  onClose: () => void
}

export interface ModuleConfig {
  edgeTypes: EdgeTypes
  nodeTypes: NodeTypes
  panelTypes: Record<string, ComponentType<PanelProps<NodeDataBase>>>
}

export interface NodeTitleProps {
  icon?: string | React.ReactElement<any>
  label?: string | React.ReactElement<any>
  nmbr?: number
  iconColor?: string
  selected?: boolean
}

export type NodeBodyProps = ComponentPropsWithoutRef<typeof Typography>

export enum AddonStates {
  Invalid = 'Invalid',
  Highlighted = 'highlighted',
  Selected = 'selected',
}

export interface FlowNodeAddonProps {
  id?: string
  type?: string
  icon?: string | React.ReactElement<any>
  label?: string | React.ReactElement<any>
  highlight?: string
  handle?: React.ReactElement<any> | Omit<HandleProps, 'id'>
  className?: string
  invalid?: boolean
  onSelected?: () => void
  onDoubleClick?: MouseEventHandler<HTMLDivElement>
  plan?: NmbrPlan
  internal?: boolean
}

export interface FlowNodeProps {
  title: ReactElement<any> | NodeTitleProps
  body: ReactElement<any> | NodeBodyProps
  addons?: FlowNodeAddonProps[] | ReactElement<any>
  isRoot?: boolean
  data: {
    highlight?: string
  }
  selected: boolean
}

type NodeWithData<T, U extends string | undefined = string | undefined> = Node<
  T & NodeDataBase & { addons: FlowNodeAddonProps[] },
  U
>

export type GenericNode = NodeWithData<unknown, string>
export type IVROptionNode = NodeWithData<IvrOption, 'IVR'>
export type ForwardsNode = NodeWithData<Forwards, 'Forwards'>
export type UsersNode = NodeWithData<Usr[], 'Users'>
export type FreeNumberNode = NodeWithData<Destination, 'freeNumber'>
export type NumberNode = NodeWithData<{ pstnNmbr: string }, 'nmbr'>

export type CallflowNode = GenericNode | IVROptionNode | ForwardsNode | UsersNode | FreeNumberNode | NumberNode

export type Nodes = CallflowNode[]

export type Positions = Record<string, { x: number; y: number }>

export type NumberplanMap = Record<
  string,
  {
    plan: NmbrPlan
    mapping: Mapping
  }[]
>
