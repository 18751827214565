import type { ModuleConfig } from '../../../../types/nodes'

import Node from './GroupNode'
import Panel from './GroupPanel'

const callFlowModule: Partial<ModuleConfig> = {
  nodeTypes: {
    grp: Node,
  },
  panelTypes: {
    grp: Panel,
  },
}

export default callFlowModule
