import React, { type JSX, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { isDateOverlap, type NmbrPlan, type NmbrPlanScheduleItem, type Scheme, SECONDS_PER } from '@/types'
import {
  Button,
  Card,
  Chip,
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHead,
  DataTableHeadCell,
  DataTableHeadRow,
  DataTableRow,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@/ui'

import BackButton from '../components/BackButton'
import { Time } from '../components/Time'
import { SevenEntryArray } from '../types/dates'
import { useGetSchemeQuery } from '../types/graphqlOperations'
import { generateLink, RoutePaths } from '../utils/routeUtiles'

const secInPerc = 100 / SECONDS_PER.WEEK

export const SchemeDetailMainView = () => {
  const [currentDate, setCurrentDate] = React.useState(new Date())
  const [scheduleItems, setScheduleItems] = React.useState<NmbrPlanScheduleItem[]>([])
  const { accountId, id: schemeId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [schemeName, setSchemeName] = React.useState('')

  const { data, loading } = useGetSchemeQuery({
    skip: !accountId,
    ...(schemeId && accountId && { variables: { id: schemeId, accountId: accountId } }),
  })

  const handleNameChange = (event: Parameters<NonNullable<TextFieldProps['onClChange']>>[0]) => {
    setSchemeName(event.detail.value)
  }

  const nonOverlapPeriods: [number, number][] = useMemo((): [number, number][] => {
    if (data?.scheme) {
      return calculateNonOverlapPeriods(data.scheme.schedule as NmbrPlanScheduleItem[])
    }
    return []
  }, [data])

  const current = useMemo(() => {
    if (data?.scheme.override) {
      return {
        plan: data.scheme.override.nmbrPlan as NmbrPlan,
        activator: t('Override'),
      }
    }
    const plan = data?.scheme.schedule.find(item => item.active)?.nmbrPlan as NmbrPlan | undefined

    return plan ? { plan, activator: t('Timetable') } : undefined
  }, [data, t])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date())
    }, 60000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (!schemeName && data?.scheme) {
      setSchemeName(data.scheme.name)
    }
  }, [data?.scheme, schemeName])

  useEffect(() => {
    if (data?.scheme) {
      const mappedScheduleItems = data.scheme.schedule.map(item => ({
        ...item,
        active: isActive(item as NmbrPlanScheduleItem, currentDate, data.scheme as Scheme),
      })) as NmbrPlanScheduleItem[]
      console.debug(mappedScheduleItems)
      setScheduleItems(mappedScheduleItems)
    }
  }, [data?.scheme, currentDate])

  return (
    <Card className='relative flex size-full flex-col items-start justify-center gap-10 md:w-3/4 md:flex-row'>
      <div>
        <BackButton />
        <IconButton className='absolute right-4 top-4' iconName='action--delete' variant='tertiary' disabled />
      </div>
      <div className='container flex flex-wrap gap-y-4'>
        <div className='flex w-full'>
          <Typography className='capitalize' variant='h4'>
            {t('{{name}} scheme', { name: schemeName })}
          </Typography>
        </div>
        <div className='w-full xl:w-1/2'>
          <Typography className='capitalize' variant='h5' gutterBottom>
            {t('Scheme Name')}
          </Typography>
          <Typography>{t('Change the name of the scheme below')}</Typography>
          <span className='flex items-center gap-x-2 pt-2'>
            <TextField value={schemeName} onClChange={handleNameChange} />
            <Button disabled={schemeName === data?.scheme.name} variant='secondary'>
              {t('Rename')}
            </Button>
            {!loading && schemeName !== data?.scheme.name && (
              <IconButton
                iconName='editor--undo'
                variant='secondary'
                onClick={() => {
                  if (data) setSchemeName(data?.scheme.name)
                }}
              />
            )}
          </span>
        </div>
        <div className='w-full xl:w-1/2'>
          <Typography className='capitalize' variant='h5' gutterBottom>
            {t('Numberplan Activation')}
          </Typography>

          {!loading && (
            <Typography className='capitalize'>
              {current ? (
                <Trans
                  components={{
                    1: <span className='font-bold' />,
                    2: <span className='uppercase' />,
                  }}
                  i18nKey='<1>{{plan}}</1> (<2>set by {{activator}}</2>)'
                  values={{
                    plan: current.plan.name,
                    activator: current.activator,
                  }}
                />
              ) : (
                t('No active plan')
              )}
            </Typography>
          )}
          <div className='flex items-center justify-start gap-1 pt-2'>
            {current ? (
              <Button iconName='action--delete' variant='secondary'>
                {t('Clear')}
              </Button>
            ) : (
              <Button iconName='generic--activity' variant='secondary'>
                {t('Override')}
              </Button>
            )}
            <Button className='uppercase' variant='secondary'>
              {t('Set holidays')}
            </Button>
          </div>
        </div>
        <div className='w-full'>
          <Typography className='capitalize' variant='h5' gutterBottom>
            {t('Timetable')}
          </Typography>
          <div className='relative'>
            <div slot='footer'></div>
            <DataTable
              style={{
                border: 'var(--border-width) solid rgb(var(--color-neutral-300))',
              }}
            >
              <DataTableHead>
                <DataTableHeadRow>
                  <DataTableHeadCell className='uppercase'>
                    <Typography className='font-bold' noWrap>
                      {t('Start Time')}
                    </Typography>
                  </DataTableHeadCell>
                  <DataTableHeadCell className='uppercase'>
                    <Typography className='font-bold' noWrap>
                      {t('End Time')}
                    </Typography>
                  </DataTableHeadCell>
                  <DataTableHeadCell className='uppercase'>
                    <Typography className='font-bold' noWrap>
                      {t('Number Plan')}
                    </Typography>
                  </DataTableHeadCell>
                  <DataTableHeadCell className='w-1/2 min-w-64 uppercase'>
                    <div className='absolute left-0 grid w-full grid-cols-7'>
                      <Typography className='font-bold' noWrap>
                        {t('MO')}
                      </Typography>
                      <Typography className='font-bold' noWrap>
                        {t('TU')}
                      </Typography>
                      <Typography className='font-bold' noWrap>
                        {t('WE')}
                      </Typography>
                      <Typography className='font-bold' noWrap>
                        {t('TH')}
                      </Typography>
                      <Typography className='font-bold' noWrap>
                        {t('FR')}
                      </Typography>
                      <Typography className='font-bold' noWrap>
                        {t('SA')}
                      </Typography>
                      <Typography className='font-bold' noWrap>
                        {t('SU')}
                      </Typography>
                    </div>
                  </DataTableHeadCell>
                </DataTableHeadRow>
              </DataTableHead>
              <DataTableBody>
                {!loading && !!nonOverlapPeriods.length && (
                  <DataTableRow>
                    <DataTableCell></DataTableCell>
                    <DataTableCell></DataTableCell>
                    <DataTableCell>
                      <Typography type='error'>{t('Unscheduled time')}</Typography>
                    </DataTableCell>
                    <DataTableCell className='relative'>
                      <GridRow>
                        <NowIndicator now={currentDate} />
                        {nonOverlapPeriods.map((period, i) => {
                          return (
                            <TimeSlot
                              key={i}
                              color='rgba(var(--system-color-warning-main))'
                              end={period[1]}
                              start={period[0]}
                              tooltip
                            />
                          )
                        })}
                      </GridRow>
                    </DataTableCell>
                  </DataTableRow>
                )}
                {scheduleItems.map(item => {
                  return (
                    <DataTableRow key={item.id}>
                      <DataTableCell>
                        <Time seconds={item.start} />
                      </DataTableCell>
                      <DataTableCell>
                        <Time seconds={item.end} />
                      </DataTableCell>
                      <DataTableCell>
                        <div className='container flex items-center justify-between'>
                          <Typography>{item.nmbrPlan.name}</Typography>

                          {item.active && (
                            <Chip color='success' style={{ marginLeft: 'var(--spacing-1)' }} readOnly selected small>
                              {t('Active')}
                            </Chip>
                          )}
                        </div>
                      </DataTableCell>
                      <DataTableCell className='relative'>
                        <GridRow>
                          <NowIndicator now={currentDate} />
                          <TimeSlot end={item.end} start={item.start} />
                        </GridRow>
                      </DataTableCell>
                    </DataTableRow>
                  )
                })}
              </DataTableBody>
            </DataTable>
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              accountId &&
                schemeId &&
                navigate(
                  generateLink(RoutePaths.SCHEME_CALLFLOW, {
                    accountId,
                    id: schemeId,
                  })
                )
            }}
          >
            Go to Callflow
          </Button>
        </div>
      </div>
    </Card>
  )
}

const GridRow = (props: { children: React.ReactNode }): JSX.Element => (
  <>
    <div className='absolute left-0 top-0 grid size-full grid-cols-7'>
      <div className='border-l-2 border-dashed border-[rgba(var(--color-neutral-200))]'></div>
      <div className='border-l-2 border-dashed border-[rgba(var(--color-neutral-200))]'></div>
      <div className='border-l-2 border-dashed border-[rgba(var(--color-neutral-200))]'></div>
      <div className='border-l-2 border-dashed border-[rgba(var(--color-neutral-200))]'></div>
      <div className='border-l-2 border-dashed border-[rgba(var(--color-neutral-200))]'></div>
      <div className='border-l-2 border-dashed border-[rgba(var(--color-neutral-200))]'></div>
      <div className='border-x-2 border-dashed border-[rgba(var(--color-neutral-200))]'></div>
    </div>
    {props.children}
  </>
)

export default SchemeDetailMainView

const TimeSlot = ({
  color = 'rgba(var(--color-neutral-900))',
  end,
  start,
  tooltip = false,
}: {
  tooltip?: boolean
  start: number
  end: number
  color?: string
}): JSX.Element => {
  const { t } = useTranslation()

  const [width, offset] = calculateWidthAndOffset(start, end)
  const [open, setOpen] = useState(false)
  const days = useMemo((): SevenEntryArray => [t('MO'), t('TU'), t('WE'), t('TH'), t('FR'), t('SA'), t('SU')], [t])

  const ts = `${convertSecondsToTimeString(start, days)} - ${convertSecondsToTimeString(end, days)}`

  const slot = (
    <div
      className='absolute h-1 rounded-lg'
      style={{ width: `${width}%`, left: `${offset}%`, backgroundColor: color }}
      onMouseEnter={() => {
        setOpen(true)
      }}
      onMouseLeave={() => {
        setOpen(false)
      }}
    />
  )

  return tooltip ? (
    <Tooltip label={ts} open={open} placement='top'>
      {slot}
    </Tooltip>
  ) : (
    slot
  )
}

const NowIndicator = ({ now }: { now: Date }): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const offset = useMemo(() => {
    const days = (now.getDay() - 1) * SECONDS_PER.DAY
    const seconds = days + now.getHours() * SECONDS_PER.HOUR + now.getMinutes() * 60
    return secInPerc * seconds
  }, [now])

  const ts = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

  return (
    <Tooltip label={ts} open={open} placement='top'>
      <div
        className='absolute top-0 h-full w-1 bg-[rgba(var(--system-color-info-cobalt-light))]'
        style={{
          left: `${offset}%`,
        }}
        onMouseEnter={() => {
          setOpen(true)
        }}
        onMouseLeave={() => {
          setOpen(false)
        }}
      />
    </Tooltip>
  )
}

/**
 * Calculates the non-overlapping periods of the given numbering plans.
 */
const calculateNonOverlapPeriods = (scheduleItems: NmbrPlanScheduleItem[]): [number, number][] => {
  const weekInSeconds = SECONDS_PER.WEEK
  const sortedItems = [...scheduleItems].sort((a, b) => a.start - b.start)

  let currentEnd = 0

  const nonOverlapPeriods = sortedItems.reduce<[number, number][]>((acc, item) => {
    if (item.start > currentEnd) {
      acc.push([currentEnd, item.start])
    }
    currentEnd = Math.max(currentEnd, item.end)
    return acc
  }, [])

  if (currentEnd < weekInSeconds) {
    nonOverlapPeriods.push([currentEnd, weekInSeconds])
  }

  return nonOverlapPeriods
}

/**
 * Calculates the width and offset of a period in the timetable.
 */
const calculateWidthAndOffset = (start: number, end: number): [number, number] => {
  const width = (end - start) * secInPerc
  const offset = (start / SECONDS_PER.WEEK) * 100

  return [width, offset]
}

/**
 * Converts the given number of seconds to a time string.
 */
const convertSecondsToTimeString = (seconds: number, days: SevenEntryArray): string => {
  const day = Math.floor(seconds / SECONDS_PER.DAY)
  const hours = Math.floor((seconds % SECONDS_PER.DAY) / SECONDS_PER.HOUR)
  const minutes = Math.floor((seconds % SECONDS_PER.HOUR) / SECONDS_PER.MINUTE)

  const dayString = days[day % 7]

  const hourString = hours.toString().padStart(2, '0')
  const minuteString = minutes.toString().padStart(2, '0')

  return `${dayString} ${hourString}:${minuteString}`
}

/**
 * Checks if the given numbering plan is active at the given date or if it is the scheme's current active override.
 */
const isActive = (scheduleItem: NmbrPlanScheduleItem, currentDate: Date, scheme: Scheme): boolean => {
  // Check if the schedule item is the current active override
  if (scheme.override?.id === scheduleItem.nmbrPlan.id) {
    return true
  }

  // Reverse the schedule array to check the most recent schedule items first
  const reversedSchedule: NmbrPlanScheduleItem[] = scheme.schedule.slice().reverse()

  // Find the first schedule item that overlaps with the current date
  const firstOverlap = reversedSchedule.find((s: NmbrPlanScheduleItem) => isDateOverlap(currentDate, s.start, s.end))
  // Check if the first overlapping schedule item has the same numbering plan ID
  return (
    firstOverlap?.nmbrPlan.id === scheduleItem.nmbrPlan.id &&
    isDateOverlap(currentDate, scheduleItem.start, scheduleItem.end)
  )
}
