export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Account = {
  __typename?: 'Account';
  accountNumber: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AccountsFilterInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type AccountsResultPayload = {
  __typename?: 'AccountsResultPayload';
  accounts: Array<Account>;
  totalCount: Scalars['Int'];
};

export type Agenda = {
  __typename?: 'Agenda';
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nmbrPlan: NmbrPlan;
  start_date?: Maybe<Scalars['String']>;
};

export type Authentication = {
  __typename?: 'Authentication';
  destination?: Maybe<Destination>;
  expanded?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  hidden?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type AuthenticationPayload = {
  __typename?: 'AuthenticationPayload';
  authenticationToken: AuthenticationToken;
  query: Query;
};

export type AuthenticationToken = {
  __typename?: 'AuthenticationToken';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Autoanswer = {
  __typename?: 'Autoanswer';
  expanded?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  hidden?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Bot = {
  __typename?: 'Bot';
  bot_type?: Maybe<Scalars['String']>;
  expanded?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  hidden?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Destination = ExtensionDestination | FreeNumberDestination | NumberDestination;

export type Extension = Authentication | Autoanswer | Bot | Extensionpicker | Forward | Group | Ivr | Lbr | Queue | Timeplan | Usr | Voicemail;

export type ExtensionDestination = {
  __typename?: 'ExtensionDestination';
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  prefix?: Maybe<Scalars['String']>;
  timeout?: Maybe<Scalars['Int']>;
};

export type Extensionpicker = {
  __typename?: 'Extensionpicker';
  expanded?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  fallback?: Maybe<ExtensionDestination>;
  hidden?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ExtensionsFilterInput = {
  extension?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ExtensionsResultPayload = {
  __typename?: 'ExtensionsResultPayload';
  extensions: Array<Extension>;
  totalCount: Scalars['Int'];
};

export type Forward = {
  __typename?: 'Forward';
  destination?: Maybe<Destination>;
  expanded?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  hidden?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Forwards = {
  __typename?: 'Forwards';
  external?: Maybe<GroupForwards>;
  internal?: Maybe<GroupForwards>;
};

export type FreeNumberDestination = {
  __typename?: 'FreeNumberDestination';
  destination_number: Scalars['ID'];
  timeout?: Maybe<Scalars['Int']>;
};

export type Group = {
  __typename?: 'Group';
  allow_individual_forwards?: Maybe<Scalars['Boolean']>;
  expanded?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  forwards?: Maybe<Forwards>;
  hidden?: Maybe<Scalars['Boolean']>;
  members?: Maybe<Array<Usr>>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type GroupForwards = {
  __typename?: 'GroupForwards';
  all?: Maybe<Destination>;
  busy?: Maybe<Destination>;
  callerid?: Maybe<Scalars['String']>;
  noanswer?: Maybe<Destination>;
};

export type IvrOption = {
  __typename?: 'IVROption';
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  option?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
};

export type Ivr = {
  __typename?: 'Ivr';
  expanded?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  forward?: Maybe<Destination>;
  hidden?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<IvrOption>>;
  type: Scalars['String'];
};

export enum Language {
  English = 'ENGLISH'
}

export type Lbr = {
  __typename?: 'Lbr';
  expanded?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  fallback?: Maybe<Destination>;
  fallback_anonymous?: Maybe<Destination>;
  hidden?: Maybe<Scalars['Boolean']>;
  lbr_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Mapping = {
  __typename?: 'Mapping';
  extension: Extension;
  nmbr: Nmbr;
  prefix?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  refreshAccessToken: AuthenticationPayload;
};


export type MutationRefreshAccessTokenArgs = {
  input: RefreshAccessTokenInput;
};

export type Nmbr = {
  __typename?: 'Nmbr';
  id: Scalars['ID'];
  scheme?: Maybe<NmbrScheme>;
  value: Scalars['String'];
};

export type NmbrFilterInput = {
  scheme?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type NmbrPlan = {
  __typename?: 'NmbrPlan';
  description: Scalars['String'];
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  mapping: Array<Mapping>;
  name: Scalars['String'];
  number: Scalars['Int'];
};

export type NmbrPlanScheduleItem = {
  __typename?: 'NmbrPlanScheduleItem';
  active?: Maybe<Scalars['Boolean']>;
  end: Scalars['Int'];
  id: Scalars['ID'];
  nmbrPlan: NmbrPlan;
  start: Scalars['Int'];
};

export type NmbrScheme = {
  __typename?: 'NmbrScheme';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type NmbrSearchCategory = {
  __typename?: 'NmbrSearchCategory';
  id: Scalars['ID'];
  items: Array<Nmbr>;
  label: Scalars['String'];
};

export type NmbrsResultPayload = {
  __typename?: 'NmbrsResultPayload';
  nmbrs: Array<Nmbr>;
  totalCount: Scalars['Int'];
};

export type NumberDestination = {
  __typename?: 'NumberDestination';
  number?: Maybe<Scalars['String']>;
  number_id: Scalars['ID'];
  timeout?: Maybe<Scalars['Int']>;
};

export enum NumberListing {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum NumberStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type PaginationInput = {
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  accounts: AccountsResultPayload;
  extension: Extension;
  extensions: ExtensionsResultPayload;
  number: Nmbr;
  numbers: NmbrsResultPayload;
  numbersByCategory: NmbrSearchCategory;
  scheme: Scheme;
  schemes: SchemesResultPayload;
  user: User;
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
};


export type QueryAccountsArgs = {
  accountId: Scalars['ID'];
  filter?: InputMaybe<AccountsFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortInput>;
};


export type QueryExtensionArgs = {
  accountId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryExtensionsArgs = {
  accountId: Scalars['ID'];
  filter?: InputMaybe<ExtensionsFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortInput>;
};


export type QueryNumberArgs = {
  accountId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryNumbersArgs = {
  accountId: Scalars['ID'];
  filter?: InputMaybe<NmbrFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortInput>;
};


export type QueryNumbersByCategoryArgs = {
  accountId: Scalars['ID'];
  category: Scalars['String'];
  searchPattern: Scalars['String'];
};


export type QuerySchemeArgs = {
  accountId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QuerySchemesArgs = {
  accountId: Scalars['ID'];
  filter?: InputMaybe<SchemeFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortInput>;
};


export type QueryUserArgs = {
  accountId: Scalars['ID'];
  id: Scalars['ID'];
};

export type Queue = {
  __typename?: 'Queue';
  allow_individual_forwards?: Maybe<Scalars['Boolean']>;
  expanded?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  forward?: Maybe<Destination>;
  hidden?: Maybe<Scalars['Boolean']>;
  idle?: Maybe<Destination>;
  members?: Maybe<Array<Usr>>;
  name?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type RefreshAccessTokenInput = {
  refreshToken: Scalars['String'];
};

export type Scheme = {
  __typename?: 'Scheme';
  agenda?: Maybe<Array<Agenda>>;
  description: Scalars['String'];
  extensions?: Maybe<Array<Extension>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  nmbrPlans: Array<NmbrPlan>;
  override?: Maybe<NmbrPlanScheduleItem>;
  schedule: Array<NmbrPlanScheduleItem>;
  scheme_number: Scalars['Int'];
};

export type SchemeFilterInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type SchemesResultPayload = {
  __typename?: 'SchemesResultPayload';
  schemes: Array<Scheme>;
  totalCount: Scalars['Int'];
};

export type SortInput = {
  field: Scalars['String'];
  order: SortOrder;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Timeplan = {
  __typename?: 'Timeplan';
  expanded?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  fallback?: Maybe<Destination>;
  hidden?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<TimeplanOption>>;
  type: Scalars['String'];
};

export type TimeplanOption = {
  __typename?: 'TimeplanOption';
  description?: Maybe<Scalars['String']>;
  end: Scalars['Int'];
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  start: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  accountId: Scalars['String'];
  displayName: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isWholeSaler: Scalars['Boolean'];
  languageCode: Scalars['String'];
  lastName: Scalars['String'];
  prefix?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
  userName: Scalars['String'];
};

export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER'
}

export type Usr = {
  __typename?: 'Usr';
  expanded?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  hidden?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Voicemail = {
  __typename?: 'Voicemail';
  email_destination?: Maybe<Scalars['String']>;
  expanded?: Maybe<Scalars['Boolean']>;
  extension?: Maybe<Scalars['Int']>;
  extension_id: Scalars['ID'];
  hidden?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};
