import { useContext } from 'react'

import { AppMode, AppModeContext } from '../contexts/AppModeContextProvider'

export function useAppMode() {
  const appMode = useContext(AppModeContext)
  return appMode
}

export function useEmbedded() {
  const { mode } = useAppMode()

  return mode === AppMode.embedded
}

export function useStandalone() {
  const { mode } = useAppMode()

  return mode === AppMode.standalone
}
