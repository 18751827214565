import { type JSX, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { SortOrder } from '@/types'
import { Divider, Dropdown, DropdownGroup, DropdownItem, Icon, SelectField, SelectFieldProps } from '@/ui'

import { useGetAccountsQuery } from '../types/graphqlOperations'
import { useCurrentAccount } from '../utils/currentAccountUtils'
import { useCurrentUser } from '../utils/currentUserUtils'

export interface SelectAccountProps {
  onBrowse: () => void
  onChange: (schemeId: string) => void
}

export const SelectAccount = ({ onBrowse, onChange }: SelectAccountProps): JSX.Element | null => {
  const user = useCurrentUser()
  const account = useCurrentAccount()
  const { t } = useTranslation()

  const queryResults = useGetAccountsQuery({
    skip: !user?.accountId,
    ...(user?.accountId && {
      variables: {
        accountId: user?.accountId,
        sort: { field: 'usedAt', order: SortOrder.Asc },
        pagination: { limit: 3, page: 0 },
      },
    }),
  })

  const handleAccountChange = useCallback((event: Parameters<NonNullable<SelectFieldProps['onClChange']>>[0]) => {
    const value = event?.detail.value[0]?.value

    if (value === 'browse') {
      onBrowse()
      return
    } else if (value) {
      onChange(value)
    }
  }, [])

  const selectedAccountValue =
    account && queryResults.data
      ? [
          {
            label: queryResults.data.accounts.accounts.find(acc => acc.id === account.id)?.name ?? '',
            value: account.id,
          },
        ]
      : []

  const dropDown =
    account && queryResults.data
      ? queryResults.data.accounts.accounts.map(acc => (
          <DropdownItem key={acc.id} label={acc.name} selected={acc.id === account.id} value={acc.id}>
            <Icon name='action--edit--alt' slot='leading' />
          </DropdownItem>
        ))
      : null

  return (
    <SelectField value={selectedAccountValue} closeOnSelect onClChange={handleAccountChange}>
      <Dropdown>
        <DropdownGroup label={t('Recent customers')}>{dropDown}</DropdownGroup>
        <Divider spacing={1} />
        <DropdownItem label={t('Browse all customers')} value='browse'>
          <Icon name='file--folder' slot='leading' />
        </DropdownItem>
      </Dropdown>
    </SelectField>
  )
}
export default SelectAccount
