import { type JSX, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'

import { type SortInput, SortOrder } from '@/types'
import {
  Button,
  Card,
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHead,
  DataTableHeadCell,
  DataTableHeadRow,
  DataTableRow,
  IconButton,
  Typography,
} from '@/ui'

import BackButton from '../components/BackButton'
import { DEFAULT_PAGE_SIZE, PaginationControls } from '../components/PaginationControls'
import { SchemeLink } from '../components/SchemeLink'
import SortableDTHeadCell from '../components/SortableTableHeadCell'
import { useGetSchemesQuery } from '../types/graphqlOperations'

const STORE_KEY_PAGE_SIZE = 'vcfd_BrowseSchemesMainView_pageSize'

export const BrowseSchemesMainView = (): JSX.Element => {
  const { t } = useTranslation()
  const { accountId } = useParams()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useLocalStorage<number>(STORE_KEY_PAGE_SIZE, DEFAULT_PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const [sort, setSort] = useState<SortInput>({
    field: 'name',
    order: SortOrder.Asc,
  })

  // TODO: handle loading and error
  const { data, error, loading } = useGetSchemesQuery({
    skip: !accountId,
    ...(accountId && {
      variables: {
        sort,
        pagination: {
          limit: pageSize,
          page,
        },
        accountId: accountId,
      },
    }),
  })

  const { next, prev } = useMemo(() => {
    if (data?.schemes) {
      setTotalPages(Math.ceil(data.schemes.totalCount / pageSize))
      return {
        next:
          page < totalPages
            ? () => {
                setPage(page + 1)
              }
            : undefined,
        prev:
          page > 1
            ? () => {
                setPage(page - 1)
              }
            : undefined,
        totalPages,
      }
    }
    return {}
  }, [data, page, pageSize, totalPages])

  return (
    <Card className='relative flex size-full flex-col items-start justify-center gap-10 md:w-3/4 md:flex-row'>
      <BackButton />
      <div className='flex w-full flex-col'>
        <div className='flex w-full items-start justify-between'>
          <Typography variant='h4' gutterBottom>
            {t('Browse Schemes')}
          </Typography>
          <Button className='uppercase' variant='secondary' disabled>
            {t('New Scheme')}
          </Button>
        </div>
        <DataTable
          style={{
            border: 'var(--border-width) solid rgb(var(--color-neutral-300))',
          }}
        >
          <DataTableHead>
            <DataTableHeadRow>
              <SortableDTHeadCell field='name' setSort={setSort} sort={sort}>
                {t('Name')}
              </SortableDTHeadCell>
              <DataTableHeadCell>{t('Num plans')}</DataTableHeadCell>
              <DataTableHeadCell>{t('Holidays')}</DataTableHeadCell>
              <DataTableHeadCell>{t('Override')}</DataTableHeadCell>
              <DataTableHeadCell>{t('Actions')}</DataTableHeadCell>
            </DataTableHeadRow>
          </DataTableHead>
          <DataTableBody>
            {data?.schemes.schemes.map((scheme, index) => (
              <DataTableRow key={scheme.id} zebra={index % 2 === 0 ? 1 : 0}>
                <DataTableCell>{scheme.name}</DataTableCell>
                <DataTableCell>
                  {scheme.nmbrPlans &&
                    `${scheme.nmbrPlans.length} (${scheme.nmbrPlans.map(plan => plan.name).join(',')})`}
                </DataTableCell>
                <DataTableCell></DataTableCell>
                <DataTableCell>
                  <Typography className='!font-bold'>{scheme.override?.nmbrPlan.name}</Typography>
                </DataTableCell>
                <DataTableCell>
                  <SchemeLink accountId={accountId} schemeId={scheme.id}>
                    <IconButton iconName='action--edit--alt' variant='tertiary' />
                  </SchemeLink>
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTable>
        <div className='flex justify-end py-2' slot='footer'>
          <PaginationControls
            next={next}
            page={page}
            pageSize={pageSize}
            prev={prev}
            totalPages={totalPages}
            onPage={setPage}
            onPageSize={size => {
              setPageSize(size)
              localStorage.setItem(STORE_KEY_PAGE_SIZE, size.toString())
            }}
          />
        </div>
      </div>
    </Card>
  )
}

export default BrowseSchemesMainView
