import { defineCustomElementClSwitch } from '@enreach/core-component-library/dist/components/index'
import { ClSwitch } from '@enreach/core-component-library-react'
import React from 'react'

import { Typography } from './Typography'

defineCustomElementClSwitch()

type SwitchProps = {
  label: string
  disabled?: boolean
} & React.ComponentProps<typeof ClSwitch>

export const Switch = (props: SwitchProps) => (
  <span className='flex gap-4'>
    <ClSwitch {...props} />
    <Typography
      className=' flex flex-wrap content-center'
      style={props.disabled ? { '--typography-color': 'rgb(var(--behaviour-disabled-content))' } : {}}
    >
      {props.label}
    </Typography>
  </span>
)
