import { type JSX } from 'react'
import { useTranslation } from 'react-i18next'

import { Scheme } from '@/types'
import { Checkbox, cn, Dropdown, DropdownGroup, DropdownItem, IconButton, SelectField, Tooltip, Typography } from '@/ui'

import SelectScheme from '../../components/SelectScheme'
import { BuildNodeStrategy, type DomainFilterCriteria, type GraphFilterCriteria } from '../../types/filters'
import { useGetNmbrsQuery } from '../../types/graphqlOperations'
import { useCurrentAccount } from '../../utils/currentAccountUtils'
import formatInternationalPhoneNumber from '../../utils/phoneNumberUtils'

export type Filter = DomainFilterCriteria & GraphFilterCriteria

export interface CallFlowBarProps {
  filter: Filter
  account?: string
  scheme?: Scheme | null
  className?: string
  disabled?: boolean
  onSchemeChange: (schemeOption: string) => void
  onSchemeBrowse: () => void
  onDownloadClick: () => void
  onChange: (filter: Filter) => void
  onLayout: () => void
}

const useNumbers = (schemaId: string | undefined) => {
  const account = useCurrentAccount()
  const { data, loading } = useGetNmbrsQuery({
    skip: !account,
    ...(account && {
      variables: {
        accountId: account?.id,
      },
    }),
  })

  if (loading || !data?.numbers.nmbrs || !schemaId) {
    return {
      assigned: [],
      unassigned: [],
    }
  }
  const all = [...(data?.numbers.nmbrs ?? [])].filter(number => !number?.scheme || number.scheme?.id === schemaId)
  return {
    assigned: all
      .filter(number => number.scheme?.id === schemaId)
      ?.sort((a, b) => a.value.localeCompare(b.value))
      .map(number => ({
        label: formatInternationalPhoneNumber(number.value),
        value: number.id,
      })),
    unassigned: all
      .filter(number => !number.scheme)
      ?.sort((a, b) => a.value.localeCompare(b.value))
      .map(number => ({
        label: formatInternationalPhoneNumber(number.value),
        value: number.id,
      })),
  }
}

const useViews = () => {
  const { t } = useTranslation()
  return [
    {
      label: t('Flow per schema'),
      value: BuildNodeStrategy.FULLY_INTERCONNECTING,
    },
    {
      label: t('Flow per number'),
      value: BuildNodeStrategy.UNIQUE_FLOW_PER_NUMBER,
    },
    {
      label: t('Flow per mapping'),
      value: BuildNodeStrategy.UNIQUE_FLOW_PER_NUMBER_NUMBERPLAN,
    },
  ]
}

export function CallFlowBar({
  account,
  className,
  disabled,
  filter,
  onChange,
  onDownloadClick,
  onLayout,
  onSchemeBrowse,
  onSchemeChange,
  scheme,
}: CallFlowBarProps): JSX.Element {
  const views = useViews()
  const numbers = useNumbers(scheme?.id)

  const { t } = useTranslation()

  const selectedNmbrplan = filter.dialplans.map(id => ({
    label: scheme?.nmbrPlans?.find(plan => plan.id === id)?.name ?? '',
    value: id,
  }))

  const fndCurrNmbr =
    numbers.assigned.find(number => number.value === filter.number) ??
    numbers.unassigned.find(number => number.value === filter.number)

  const selectedNmbr = fndCurrNmbr ? [fndCurrNmbr] : []

  const currentView: {
    label: string
    value: BuildNodeStrategy
  } = views.find(view => view.value === filter.view) ?? (views[0] as { label: string; value: BuildNodeStrategy })

  const handleDialplansChange = (dialplans: string[]) => {
    onChange({
      ...filter,
      dialplans,
    })
  }
  const handleNmbrChange = (nmbr: string) => {
    onChange({
      ...filter,
      number: nmbr,
    })
  }

  const handleViewChange = (view: BuildNodeStrategy) => {
    onChange({
      ...filter,
      view,
    })
  }

  const handleClearNmbrplansClick = () => {
    onChange({
      ...filter,
      dialplans: [],
    })
  }

  const handleClearNmbrClick = () => {
    onChange({
      ...filter,
      number: undefined,
    })
  }

  const handleDownloadImageClick = () => onDownloadClick()

  return (
    <div
      className={cn(
        className,
        'flex flex-wrap w-full justify-between p-2 bg-[rgba(var(--surface-color-contrast))] items-center z-10 gap-2 shadow-card'
      )}
    >
      <CallFlowBar.Left>
        {account && (
          <Typography lineClamp={1} variant='body-2'>
            {account}
          </Typography>
        )}
        <SelectScheme
          disabled={disabled}
          value={scheme ? [{ label: scheme.name, value: scheme.id }] : []}
          onBrowse={onSchemeBrowse}
          onChange={onSchemeChange}
        />
        <div className='flex flex-row items-end'>
          <SelectField
            disabled={disabled}
            label={t('Numberplan')}
            placeholder={t('Show all numberplans')}
            value={selectedNmbrplan}
            onClChange={event => {
              handleDialplansChange(event.detail.value.map(v => v.value))
            }}
          >
            <Dropdown variant='menu' multiple>
              {scheme?.nmbrPlans?.map(plan => (
                <DropdownItem key={plan.id} label={plan.name} value={plan.id}>
                  <Checkbox slot='leading' />
                </DropdownItem>
              ))}
            </Dropdown>
          </SelectField>
          {!!selectedNmbrplan.length && (
            <Tooltip label={t('Clear numberplan')}>
              <IconButton
                className='pb-[3px] pl-0.5'
                iconName='action--close'
                variant='tertiary'
                onClick={handleClearNmbrplansClick}
              />
            </Tooltip>
          )}
        </div>
        <div className='flex flex-row items-end'>
          <SelectField
            disabled={disabled}
            label={t('Number')}
            placeholder={t('Show all numbers')}
            value={selectedNmbr.map(({ label, value }) => ({ label: label ?? value, value }))}
            closeOnSelect
            onClChange={event => {
              const newNumber = event.detail.value.map(v => v.value)[0]
              if (newNumber) handleNmbrChange(newNumber)
            }}
          >
            <Dropdown autoFocus showScroll>
              <DropdownGroup label={t('Assigned')}>
                {numbers.assigned.map(number => {
                  return <DropdownItem key={number.value} label={number.label ?? number.value} value={number.value} />
                })}
              </DropdownGroup>
              <DropdownGroup label={t('Unassigned')}>
                {numbers.unassigned.map(number => {
                  return (
                    <DropdownItem
                      key={number.value}
                      label={number.label ?? number.value}
                      value={number.value}
                      disabled
                    />
                  )
                })}
              </DropdownGroup>
            </Dropdown>
          </SelectField>
          {!!selectedNmbr.length && (
            <Tooltip label={t('Clear number')}>
              <IconButton
                className='pb-[3px] pl-0.5'
                iconName='action--close'
                variant='tertiary'
                onClick={handleClearNmbrClick}
              />
            </Tooltip>
          )}
        </div>
      </CallFlowBar.Left>

      <CallFlowBar.Right>
        <SelectField
          disabled={disabled}
          label={t('View')}
          value={[currentView ?? views[0]]}
          closeOnSelect
          onClChange={event => {
            const newValue = event.detail.value[0]?.value
            if (newValue) handleViewChange(newValue as BuildNodeStrategy)
          }}
        >
          <Dropdown variant='menu'>
            {views.map((view, index) => (
              <DropdownItem key={index} label={view.label} value={view.value} />
            ))}
          </Dropdown>
        </SelectField>
        <Tooltip label={t('Download flow image')} placement='bottom-end'>
          <IconButton
            className='pb-[3px]'
            iconName='action--download'
            variant='tertiary'
            onClick={handleDownloadImageClick}
          />
        </Tooltip>
        <Tooltip label={t('Snap to grid')} placement='bottom-end'>
          <IconButton
            className='pb-[3px]'
            disabled={disabled}
            iconName='view--grid'
            variant='tertiary'
            onClick={onLayout}
          />
        </Tooltip>
      </CallFlowBar.Right>
    </div>
  )
}

CallFlowBar.Left = function CallFlowBarLeft(
  props: object & { className?: string; children?: React.ReactNode[] }
): JSX.Element {
  return <div {...props} className={cn(props.className, 'flex flex-wrap justify-start gap-2 items-end')} />
}
CallFlowBar.Right = function CallFlowBarRight(
  props: object & { className?: string; children: React.ReactNode[] }
): JSX.Element {
  return <div {...props} className={cn(props.className, 'flex justify-end gap-2 items-end')} />
}

export default CallFlowBar
