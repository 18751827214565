import { generatePath } from 'react-router-dom'

export enum RoutePaths {
  MAIN = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  USER = '/user',
  ACCOUNT = '/a/:accountId',
  SCHEME = '/a/:accountId/s/:id',
  SCHEME_CALLFLOW = '/a/:accountId/s/:id/f',
  SCHEMES = '/a/:accountId/s',
  NUMBERS = '/a/:accountId/n',
}

export function generateLink(
  route: RoutePaths,
  pathParams?: {
    accountId?: string | null
    id?: string | null
    highlight?: string
  },
  queryParams?: Record<string, string | number | null>
) {
  const pathParameters = {
    accountId: pathParams?.accountId || null,
    id: pathParams?.id || null,
  }

  const path = `${generatePath(route, pathParameters)}`
  if (queryParams) {
    const queryParameters = Object.entries(queryParams).reduce(
      (acc, [key, value]) => {
        if (value !== null) {
          acc[key] = value.toString()
        }

        return acc
      },
      {} as Record<string, string>
    )

    return `${path}?${new URLSearchParams(queryParameters).toString()}`
  }
  return path
}
