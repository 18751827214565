import type { JSX } from 'react'

import { useNavigate } from 'react-router-dom'

import { IconButton } from '@/ui'

export const BackButton = (props = {}): JSX.Element => {
  const navigateTo = useNavigate()
  return (
    <IconButton
      {...props}
      className='absolute left-4 top-4'
      iconName='micro--arrow--left'
      variant='tertiary'
      onClick={() => {
        navigateTo(-1)
      }}
    />
  )
}

export default BackButton
