// CurrentAccountContext.tsx
import React, { createContext, type JSX, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'

import { useGetAccountQuery } from '../types/graphqlOperations'
import { type AccountFragment } from '../types/graphqlTypes'

interface CurrentAccountContextType {
  currentAccount: AccountFragment | null // replace Account with your actual type
  setCurrentAccount: (account: AccountFragment | null) => void // replace Account with your actual type
}

export const CurrentAccountContext = createContext<CurrentAccountContextType>({
  currentAccount: null,
  setCurrentAccount: () => {},
})

const STORE_KEY_ACCOUNT = 'vcfd_account'

/**
 * current account is the account that is currently being viewed
 * the account id is stored in the url for bookmarking and sharing
 * an account must always be selected and only accounts which are
 * sub accounts of the current user's associated account or the user's account
 * can be viewed
 */
export const CurrentAccountContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { accountId } = useParams()
  const [currentAccount, setCurrentAccount] = useLocalStorage<AccountFragment | null>(STORE_KEY_ACCOUNT, null)

  const { data } = useGetAccountQuery({
    skip: !accountId,
    ...(accountId
      ? {
          variables: {
            id: accountId,
          },
        }
      : {}),
  })

  useEffect(() => {
    if (data?.account) {
      setCurrentAccount(data.account)
      document.title = data.account.name
    }
    return () => {
      document.title = ''
    }
  }, [data, setCurrentAccount])

  const value = React.useMemo(() => {
    return {
      currentAccount,
      setCurrentAccount,
    }
  }, [currentAccount, setCurrentAccount])

  return <CurrentAccountContext.Provider value={value}>{children}</CurrentAccountContext.Provider>
}
