import * as React from 'react'

import { cn } from '../utils/uiUtils'

export interface CardProps extends React.ButtonHTMLAttributes<HTMLDivElement> {}

const Card = React.forwardRef<HTMLDivElement, CardProps>(({ children, className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'bg-[rgb(var(--surface-color-contrast))]',
        'rounded-[var(--border-radius-large)]',
        'shadow-lg px-10 py-8',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
})

Card.displayName = 'Card'

export { Card }
