import { type JSX, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { useLogout } from '../utils/authenticationUtils'
import { RoutePaths } from '../utils/routeUtiles'

export const LogOutView = (): JSX.Element => {
  const logout = useLogout()

  useEffect(() => {
    logout()
  })

  return <Navigate to={RoutePaths.LOGIN} replace />
}

export default LogOutView
