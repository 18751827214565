import type { JSX } from 'react'

import { useTranslation } from 'react-i18next'
import { type Node, type NodeProps } from 'reactflow'

import { FlowNodeAddonProps, NodeDataBase } from '../../../../types/nodes'
import { FlowNode } from '../FlowNode'

export interface NmbrNodeData extends NodeDataBase {
  pstnNmbr: string
  addons?: Array<
    {
      type: 'nmbrPlan'
    } & FlowNodeAddonProps
  >
}

export const NmbrNode = (props: NodeProps<NmbrNodeData>): JSX.Element => {
  const { t } = useTranslation()
  const { data } = props
  return (
    <FlowNode
      {...props}
      addons={data.addons?.reduce<FlowNodeAddonProps[]>((prev, AddonProps) => {
        // TODO: these types should come from a shared enum produced by the server schema.graphql
        if (AddonProps.type === 'nmbrPlan') {
          return [
            ...prev,
            {
              icon: 'action--edit--alt',
              ...AddonProps,
            },
          ]
        }
        console.warn(`Unknown addon type: ${AddonProps.type} passed in NmbrNode data `, data)

        return prev
      }, [])}
      body={{ children: data.pstnNmbr }}
      title={{
        icon: 'communication--device--mobile',
        label: t('Number'),
      }}
      isRoot
    />
  )
}

export default NmbrNode

export interface NmbrNodeType extends Node<NmbrNodeData, 'nmbr'> {}
