import { type JSX, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'

import { IconButton, Pictogram, Tooltip } from '@/ui'

import { DarkModeToggleButton } from '../components/DarkModetoggleButton'
import LanguageSelector from '../components/LanguageSelector'
import { Logo } from '../components/Logo'
import SelectAccount from '../components/SelectAccount'
import { useLogout } from '../utils/authenticationUtils'
import { useCurrentUser } from '../utils/currentUserUtils'
import { generateLink, RoutePaths } from '../utils/routeUtiles'

export const MainLayout = (): JSX.Element | null => {
  const user = useCurrentUser()
  const hideAccountSelect = !!useMatch(RoutePaths.MAIN)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const logout = useLogout()

  const handleAccountChange = useCallback((accountId: string) => {
    navigate(generateLink(RoutePaths.ACCOUNT, { accountId }))
  }, [])

  const handleBrowseClick = useCallback(() => {
    navigate(generateLink(RoutePaths.MAIN))
  }, [navigate])

  return (
    <>
      <div className='flex flex-col items-center p-1 md:flex-row md:justify-between'>
        <Logo className='m-1 md:m-10' />

        <div className='flex h-fit w-full items-center justify-end gap-2 rounded-sm bg-[rgb(var(--surface-color-contrast))] p-1 pr-2 shadow-card md:w-fit'>
          {user?.isWholeSaler && !hideAccountSelect && (
            <SelectAccount onBrowse={handleBrowseClick} onChange={handleAccountChange} />
          )}

          <LanguageSelector />

          <DarkModeToggleButton />

          <Tooltip label={t('Logout')}>
            <IconButton iconName='action--leave' variant='tertiary' onClick={logout} />
          </Tooltip>
        </div>
      </div>

      <Pictogram
        className='absolute left-0 top-0 size-full object-cover'
        name='background--connecting'
        style={{ '--pictogram-size': '100%', zIndex: '-1' }}
      />

      <div className='flex items-center justify-center p-2'>
        <Outlet />
      </div>
    </>
  )
}
export default MainLayout
