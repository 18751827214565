import type { ReactNode } from 'react'

import { Bar, Divider, IconButton, ScrollWrapper, Typography } from '@/ui'

import BlockingLoader from './BlockingLoader'

interface Props {
  title: string
  onClose: () => void
  children: ReactNode
  loading?: boolean
}

const GroupHeading = props => (
  <Typography
    {...props}
    style={{
      '--typography-weight': 400,
    }}
    variant='body-1'
  />
)

const GroupDivider = props => <Divider {...props} spacing={1} />

export const PanelLayout = ({ children, loading, onClose, title }: Props): JSX.Element => {
  return (
    <>
      <Bar variant='tertiary' action>
        <Typography slot='leading' style={{ '--typography-weight': 700 }} noWrap>
          {title}
        </Typography>
        <IconButton iconName='action--close' slot='trailing' variant='tertiary' onClick={onClose} />
      </Bar>
      <ScrollWrapper>
        {loading && <BlockingLoader />}
        <div className='flex flex-col gap-5 px-8 py-4'>{children}</div>
      </ScrollWrapper>
    </>
  )
}

PanelLayout.GroupHeading = GroupHeading
PanelLayout.GroupDivider = GroupDivider

export default PanelLayout
