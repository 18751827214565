import React, { type ContextType } from 'react'

import { CurrentUserContext } from '../contexts/CurrentUserContext'
import { type UserFragment } from '../types/graphqlTypes'

export const useCurrentUserContext = (): ContextType<typeof CurrentUserContext> => {
  return React.useContext(CurrentUserContext)
}

export const useCurrentUser = (): UserFragment | null => {
  const { currentUser } = useCurrentUserContext()

  if (!currentUser) return null

  return currentUser
}
