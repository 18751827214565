import type { JSX } from 'react'

import { useTranslation } from 'react-i18next'
import { type NodeProps } from 'reactflow'

import { FlowNodeAddonProps, NodeDataBase } from '../../../../types/nodes'
import { FlowNode } from '../FlowNode'

export interface BotNodeData extends NodeDataBase {
  addons?: Array<
    {
      type: string
    } & FlowNodeAddonProps
  >
}

export const BotNode = (props: NodeProps<BotNodeData>): JSX.Element => {
  const { t } = useTranslation()
  const { data } = props
  return (
    <FlowNode
      {...props}
      body={{ children: data.title }}
      title={{
        icon: 'communication--virtual--assistant',
        label: t('Bot'),
        nmbr: data.extensionNmbr,
      }}
    />
  )
}

export default BotNode
