import React, { type JSX, ReactNode } from 'react'

export enum AppMode {
  embedded = 'embedded',
  standalone = 'standalone',
}

export interface AppModeContextProps {
  mode: AppMode
  setMode: (mode: AppMode) => void
}

export const AppModeContext = React.createContext<AppModeContextProps>({
  mode: AppMode.standalone,
  setMode: (mode: AppMode) => mode,
})

function calculateInitialMode(): AppMode {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  const mode = params.get('embed') ? AppMode.embedded : AppMode.standalone

  // Remove 'embed' parameter from the URL
  if (params.has('embed')) {
    params.delete('embed')
    url.search = params.toString()
    window.history.replaceState({}, '', url.toString())
  }

  return mode
}

export const AppModeContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [mode, setMode] = React.useState<AppMode>(calculateInitialMode())

  const value = React.useMemo(() => ({ mode, setMode }), [mode])

  return <AppModeContext.Provider value={value}>{children}</AppModeContext.Provider>
}
