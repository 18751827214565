import { useTranslation } from 'react-i18next'
import { type NodeProps } from 'reactflow'

import { FlowNodeAddonProps, NodeDataBase } from '../../../../types/nodes'
import { FlowNode } from '../FlowNode'

export interface UserNodeData extends NodeDataBase {
  addons?: Array<
    {
      type: 'noAnswerForward' | 'busyForward'
    } & FlowNodeAddonProps
  >
}

export const UserNode = (props: NodeProps<UserNodeData>) => {
  const { t } = useTranslation()
  const { data } = props
  return (
    <FlowNode
      {...props}
      addons={data.addons?.reduce<FlowNodeAddonProps[]>((prev, AddonProps) => {
        // TODO: these types should come from a shared enum produced by the server schema.graphql
        if (AddonProps.type === 'noAnswerForward') {
          return [
            ...prev,
            {
              icon: 'communication--call--forward--no--answer',
              label: t('No Answer Forward'),
              ...AddonProps,
            },
          ]
        } else if (AddonProps.type === 'busyForward') {
          return [
            ...prev,
            {
              icon: 'communication--call--forward--busy',
              label: t('On Busy Forward'),
              ...AddonProps,
            },
          ]
        }
        console.warn(`Unknown addon type: ${AddonProps.type} passed in ${props.type} data `, data)

        return prev
      }, [])}
      body={{ children: data.title }}
      title={{
        icon: 'generic--user--user',
        label: t('User'),
        nmbr: data.extensionNmbr,
      }}
    />
  )
}

export default UserNode
