import { RuntimeConfig } from '../types/custom'

export function ensureRuntimeConfig() {
  // Cast window to any to bypass TypeScript's type checking,
  // since runtimeConfig is a custom property not known to TypeScript by default
  const win = window as unknown as object & { runtimeConfig: RuntimeConfig }

  if (!win.runtimeConfig) {
    win.runtimeConfig = {
      noConfig: true,
    } as RuntimeConfig
  }
}
