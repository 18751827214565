import { type JSX, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'

import { type SortInput, SortOrder } from '@/types'
import {
  Card,
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHead,
  DataTableHeadCell,
  DataTableHeadRow,
  DataTableRow,
  IconButton,
  Typography,
} from '@/ui'

import BackButton from '../components/BackButton'
import { DEFAULT_PAGE_SIZE, PaginationControls } from '../components/PaginationControls'
import { SchemeFlowLink } from '../components/SchemeLink'
import SortableDTHeadCell from '../components/SortableTableHeadCell'
import { useGetNmbrsQuery } from '../types/graphqlOperations'
import formatInternationalPhoneNumber from '../utils/phoneNumberUtils'

const STORE_KEY_PAGE_SIZE = 'vcfd_BrowseNmbrsMainView_pageSize'

export const BrowseNmbrsMainView = (): JSX.Element => {
  const { t } = useTranslation()
  const { accountId } = useParams()
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useLocalStorage<number>(STORE_KEY_PAGE_SIZE, DEFAULT_PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [sort, setSort] = useState<SortInput>({
    field: 'value',
    order: SortOrder.Asc,
  })
  // TODO: handle loading and error
  const { data, error, loading } = useGetNmbrsQuery({
    skip: !accountId,
    ...(accountId && {
      variables: {
        sort,
        pagination: {
          limit: pageSize,
          page,
        },
        accountId: accountId,
      },
    }),
  })

  const { next, prev } = useMemo(() => {
    if (data?.numbers && page && pageSize) {
      setTotalPages(Math.ceil(data.numbers.totalCount / pageSize))
      return {
        next:
          page < totalPages
            ? () => {
                setPage(page + 1)
              }
            : undefined,
        prev:
          page > 1
            ? () => {
                setPage(page - 1)
              }
            : undefined,
        totalPages,
      }
    }
    return {}
  }, [data, page, pageSize, totalPages])

  return (
    <Card className='relative flex size-full flex-col items-start justify-center gap-10 md:w-3/4 md:flex-row'>
      <BackButton />
      <div className='flex w-full flex-col'>
        <Typography variant='h4' gutterBottom>
          {t('Browse Numbers')}
        </Typography>
        <DataTable
          style={{
            border: 'var(--border-width) solid rgb(var(--color-neutral-300))',
          }}
        >
          <DataTableHead>
            <DataTableHeadRow>
              <SortableDTHeadCell field='value' setSort={setSort} sort={sort}>
                {t('Number')}
              </SortableDTHeadCell>
              <DataTableHeadCell>{t('Schema')}</DataTableHeadCell>
              <DataTableHeadCell>{t('Actions')}</DataTableHeadCell>
            </DataTableHeadRow>
          </DataTableHead>
          <DataTableBody>
            {data?.numbers.nmbrs.map((number, index) => (
              <DataTableRow key={number.id} zebra={index % 2 === 0 ? 1 : 0}>
                <DataTableCell>{formatInternationalPhoneNumber(number.value)}</DataTableCell>
                <DataTableCell>{number.scheme?.name}</DataTableCell>
                <DataTableCell>
                  {number.scheme && (
                    <SchemeFlowLink
                      accountId={accountId}
                      highlight={`number:${number.value}`}
                      schemeId={number.scheme.id}
                    >
                      <IconButton iconName='action--edit--alt' variant='tertiary' />
                    </SchemeFlowLink>
                  )}
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
          <div className='flex justify-end py-2' slot='footer'>
            <PaginationControls
              next={next}
              page={page}
              pageSize={pageSize}
              prev={prev}
              totalPages={totalPages}
              onPage={setPage}
              onPageSize={size => {
                setPageSize(size)
                localStorage.setItem(STORE_KEY_PAGE_SIZE, size.toString())
              }}
            />
          </div>
        </DataTable>
      </div>
    </Card>
  )
}

export default BrowseNmbrsMainView
