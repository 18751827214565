import { useCallback, useMemo } from 'react'

import { type SortInput, SortOrder } from '@/types'
import { DataTableHeadCell, IconButton } from '@/ui'

export const SortableDTHeadCell = ({
  children,
  field,
  setSort,
  sort,
}: {
  field: string
  setSort: (sort: { field: string; order: SortOrder }) => void
  sort: SortInput | null
  children: React.ReactNode
}) => {
  const onClick = useCallback(() => {
    setSort({
      field,
      order: sort?.order === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc,
    })
  }, [field, sort, setSort])

  const icon = useMemo(() => {
    if (sort?.field === field) {
      return sort.order === SortOrder.Asc ? 'navigation--sort--up' : 'navigation--sort--down'
    }
    return 'navigation--sort--up--down'
  }, [sort])

  return (
    <DataTableHeadCell onClick={onClick}>
      <div className='container flex flex-nowrap items-center justify-between p-0'>
        <span>{children}</span>
        <IconButton iconName={icon} variant='tertiary' small></IconButton>
      </div>
    </DataTableHeadCell>
  )
}

export default SortableDTHeadCell
