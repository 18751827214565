import { type JSX, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { SortOrder } from '@/types'
import { Divider, Dropdown, DropdownGroup, DropdownItem, Icon, SelectField, type SelectFieldProps } from '@/ui'

import { useGetSchemesQuery } from '../types/graphqlOperations'
import { useCurrentAccount } from '../utils/currentAccountUtils'

export interface SelectSchemeProps extends Omit<SelectFieldProps, 'onChange'> {
  onBrowse: () => void
  onChange: (accountId: string) => void
}

export const SelectScheme = ({ onBrowse, onChange, value, ...SelectProps }: SelectSchemeProps): JSX.Element => {
  const { t } = useTranslation()
  const account = useCurrentAccount()

  const schemesQuery = useGetSchemesQuery({
    skip: !account?.id,
    ...(account && {
      variables: {
        accountId: account?.id,
        sort: { field: 'usedAt', order: SortOrder.Asc },
        pagination: { limit: 3, page: 0 },
      },
    }),
  })

  const handleChange = useCallback(
    (event: Parameters<NonNullable<SelectFieldProps['onClChange']>>[0]) => {
      const value = event.detail.value[0]?.value
      if (value === 'browse') {
        onBrowse()
        return
      } else if (value) {
        onChange(value)
      }
    },
    [onBrowse, onChange]
  )

  return (
    <SelectField
      disabled={schemesQuery.loading}
      label={t('Scheme')}
      style={{
        '--field-width': 'calc(30 * var(--size-1))',
      }}
      value={value}
      closeOnSelect
      onClChange={handleChange}
      {...SelectProps}
    >
      <Dropdown>
        <DropdownGroup label={t('Recent schemes')}>
          {schemesQuery.data?.schemes.schemes.map(scheme => (
            <DropdownItem
              key={scheme.id}
              label={scheme.name || undefined}
              selected={value?.[0]?.value === scheme.id}
              value={scheme.id}
            >
              <Icon name='action--edit--alt' slot='leading' />
            </DropdownItem>
          ))}
        </DropdownGroup>
        <Divider spacing={1} />
        <DropdownItem label={t('Browse all schemes')} value='browse'>
          <Icon name='file--folder' slot='leading' />
        </DropdownItem>
      </Dropdown>
    </SelectField>
  )
}

export default SelectScheme
