import type { JSX } from 'react'

import { useTranslation } from 'react-i18next'

import { cn, Icon, Typography } from '@/ui'

interface Props {
  className?: string
  /**
   * show text below the logo instead of next to it
   */
  bottom?: boolean
  textProps?: React.ComponentProps<typeof Typography>
  iconProps?: React.ComponentProps<typeof Icon>
  isInNav?: boolean
  slot?: string
}

export const Logo = ({
  bottom,
  className,
  iconProps: IconProps,
  isInNav,
  slot,
  textProps: TypographyProps,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const text = bottom ? t('CallFlow Designer') : t('CallFlow Designer').split(' ').join('<br />')

  return (
    <div className={cn('flex gap-1', bottom && 'flex-col', !bottom && 'items-center', className)} slot={slot}>
      <Icon
        {...IconProps}
        className='text-center'
        name='generic--branch--alt'
        size={IconProps?.size ?? 10}
        style={{
          '--icon-color': isInNav ? 'rgb(var(--color-neutral-50s))' : 'rgb(var(--color-brand-600))',
        }}
      />

      <Typography
        {...TypographyProps}
        className={cn(bottom && 'text-nowrap text-center')}
        variant={TypographyProps?.variant ?? 'h4'}
      >
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </Typography>
    </div>
  )
}
