import { type JSX, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { AuthenticationToken } from '@/types'
import { Button, Card, cn, ContainerCentered, Pictogram, Typography } from '@/ui'

import { Logo } from '../components/Logo'
import { TermsAndPrivacy } from '../components/TermsAndPrivacy'
import { useIsAuthenticated, useSaveAuthenticationToken } from '../utils/authenticationUtils'
import { RoutePaths } from '../utils/routeUtiles'

export const LoginView = (): JSX.Element => {
  const isAuthenticated = useIsAuthenticated()
  const saveAuthCode = useSaveAuthenticationToken()
  const { t } = useTranslation()

  const [isError, setIsError] = useState(false)
  const winRef = useRef<Window | null>(null)

  const handleClick = () => {
    if (winRef.current) {
      winRef.current.close()
    }

    const width = 500
    const height = 500

    // Calculate the position of the new window to be in the center of the screen
    const left = window.screenLeft + window.outerWidth / 2 - width / 2
    const top = window.screenTop + window.outerHeight / 2 - height / 2

    winRef.current = window.open(
      `${window.runtimeConfig?.API_URL}/login`,
      'newWindow',
      `width=${width},height=${height},left=${left},top=${top}`
    )
  }

  useEffect(() => {
    const handleLoginResponse = (event: MessageEvent<{ type: 'error' | 'success'; data: string }>) => {
      if (event.data.type === 'error') {
        console.error('Received error from login window:', event.data.data)
        setIsError(true)
      } else if (event.data.type === 'success') {
        const tokenSet: AuthenticationToken = JSON.parse(event.data.data)
        console.debug('Received token set from login window:', tokenSet)
        saveAuthCode(tokenSet)
      }
    }

    window.addEventListener('message', handleLoginResponse, false)

    return () => {
      window.removeEventListener('message', handleLoginResponse)
    }
  })

  if (isAuthenticated) return <Navigate to={RoutePaths.MAIN} replace />

  return (
    <>
      <Pictogram
        className='absolute'
        name='background--connecting'
        style={{ '--pictogram-size': '100%', zIndex: '-1' }}
      />
      <ContainerCentered>
        <Card>
          <Logo className='flex justify-center gap-6' bottom />
          <span className={cn('flex flex-col items-center mt-6 gap-6')}>
            <Button onClick={handleClick}>
              <Typography className='uppercase'>{t('Login with Operator')}</Typography>
            </Button>
          </span>
          {isError && <Typography color='error'>There was an error logging in</Typography>}
        </Card>
        <TermsAndPrivacy />
      </ContainerCentered>
    </>
  )
}

export default LoginView
