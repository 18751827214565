import type { ModuleConfig } from '../../../../types/nodes'

import Node from './ForwardNode'
import Panel from './ForwardPanel'

const callFlowModule: Partial<ModuleConfig> = {
  nodeTypes: {
    forward: Node,
  },
  panelTypes: {
    forward: Panel,
  },
}

export default callFlowModule
