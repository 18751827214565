import { type JSX, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDarkMode } from 'usehooks-ts'

import { BarButton, IconButton, Tooltip } from '@/ui'

import { darkTheme, lightTheme } from '../config/ccl'

interface Props {
  inNavbar?: boolean
}

export const DarkModeToggleButton = ({ inNavbar }: Props): JSX.Element => {
  const { isDarkMode, toggle } = useDarkMode()
  const { t } = useTranslation()

  useEffect(() => {
    if (isDarkMode) {
      darkTheme()
    } else {
      lightTheme()
    }
  }, [isDarkMode])

  const button = useMemo(() => {
    if (inNavbar) {
      return <BarButton iconName={isDarkMode ? 'view--dark' : 'view--light'} onClick={toggle} />
    }
    return <IconButton iconName={isDarkMode ? 'view--dark' : 'view--light'} variant='tertiary' onClick={toggle} />
  }, [inNavbar, isDarkMode, toggle])

  return (
    <Tooltip label={isDarkMode ? t('Disable darkmode') : t('Enable darkmode')} placement='bottom-end'>
      {button}
    </Tooltip>
  )
}
