import * as Types from './graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const AccountFragmentDoc = gql`
    fragment Account on Account {
  id
  name
  accountNumber
}
    `;
export const GetNmbrFragmentDoc = gql`
    fragment GetNmbr on Nmbr {
  id
  value
}
    `;
export const GetNmbrsFragmentDoc = gql`
    fragment GetNmbrs on Nmbr {
  id
  value
  scheme {
    id
    name
  }
}
    `;
export const NmbrsSearchResultFragmentDoc = gql`
    fragment NmbrsSearchResult on NmbrSearchCategory {
  id
  label
  items {
    id
    value
    scheme {
      id
      name
    }
  }
}
    `;
export const GetSchemeFragmentDoc = gql`
    fragment GetScheme on Scheme {
  id
  name
  description
  schedule {
    id
    nmbrPlan {
      id
      name
    }
    start
    end
    active
  }
  override {
    id
    nmbrPlan {
      id
      name
    }
    start
    end
  }
}
    `;
export const GetSchemeGraphFragmentDoc = gql`
    fragment GetSchemeGraph on Scheme {
  id
  name
  description
  nmbrPlans {
    id
    name
    mapping {
      extension {
        ... on Bot {
          extension
          extension_id
        }
        ... on Timeplan {
          extension
          extension_id
        }
        ... on Forward {
          extension
          extension_id
        }
        ... on Authentication {
          extension
          extension_id
        }
        ... on Lbr {
          extension
          extension_id
        }
        ... on Extensionpicker {
          extension
          extension_id
        }
        ... on Queue {
          extension
          extension_id
        }
        ... on Ivr {
          extension
          extension_id
        }
        ... on Autoanswer {
          extension
          extension_id
        }
        ... on Voicemail {
          extension
          extension_id
        }
        ... on Group {
          extension
          extension_id
        }
        ... on Usr {
          extension
          extension_id
        }
      }
      nmbr {
        id
        value
      }
    }
  }
  extensions {
    ... on Bot {
      extension_id
      extension
      name
      type
      bot_type
    }
    ... on Timeplan {
      extension_id
      extension
      name
      type
      options {
        start
        end
        extension_id
        extension
        description
      }
      fallback {
        ... on ExtensionDestination {
          extension_id
          extension
          prefix
        }
        ... on NumberDestination {
          number_id
          number
        }
        ... on FreeNumberDestination {
          destination_number
          timeout
        }
      }
    }
    ... on Forward {
      extension_id
      extension
      name
      type
      destination {
        ... on ExtensionDestination {
          extension_id
          extension
          prefix
        }
        ... on NumberDestination {
          number_id
          number
        }
        ... on FreeNumberDestination {
          destination_number
          timeout
        }
      }
    }
    ... on Authentication {
      extension_id
      extension
      name
      type
    }
    ... on Lbr {
      extension_id
      extension
      name
      type
      lbr_type
      fallback_anonymous {
        ... on ExtensionDestination {
          extension_id
          extension
          prefix
        }
        ... on NumberDestination {
          number_id
          number
        }
        ... on FreeNumberDestination {
          destination_number
          timeout
        }
      }
      fallback {
        ... on ExtensionDestination {
          extension_id
          extension
          prefix
        }
        ... on NumberDestination {
          number_id
          number
        }
        ... on FreeNumberDestination {
          destination_number
          timeout
        }
      }
    }
    ... on Extensionpicker {
      extension_id
      extension
      name
      type
      fallback {
        extension_id
        extension
        prefix
      }
    }
    ... on Queue {
      extension_id
      extension
      name
      type
      strategy
      allow_individual_forwards
      idle {
        ... on ExtensionDestination {
          extension_id
          extension
          prefix
        }
        ... on NumberDestination {
          number_id
          number
        }
        ... on FreeNumberDestination {
          destination_number
          timeout
        }
      }
      forward {
        ... on ExtensionDestination {
          extension_id
          extension
          prefix
        }
        ... on NumberDestination {
          number_id
          number
        }
        ... on FreeNumberDestination {
          destination_number
          timeout
        }
      }
      members {
        extension_id
        extension
      }
    }
    ... on Ivr {
      extension_id
      extension
      name
      type
      options {
        option
        prefix
        extension_id
        extension
      }
      forward {
        ... on ExtensionDestination {
          extension_id
          extension
          prefix
        }
        ... on NumberDestination {
          number_id
          number
        }
        ... on FreeNumberDestination {
          destination_number
          timeout
        }
      }
    }
    ... on Autoanswer {
      extension_id
      extension
      name
      type
    }
    ... on Voicemail {
      extension_id
      extension
      name
      type
    }
    ... on Group {
      extension_id
      extension
      name
      type
      allow_individual_forwards
      members {
        extension_id
        extension
      }
      forwards {
        internal {
          callerid
          all {
            ... on ExtensionDestination {
              extension_id
              extension
              prefix
            }
            ... on NumberDestination {
              number_id
              number
            }
            ... on FreeNumberDestination {
              destination_number
              timeout
            }
          }
          noanswer {
            ... on ExtensionDestination {
              extension_id
              extension
              prefix
            }
            ... on NumberDestination {
              number_id
              number
            }
            ... on FreeNumberDestination {
              destination_number
              timeout
            }
          }
          busy {
            ... on ExtensionDestination {
              extension_id
              extension
              prefix
            }
            ... on NumberDestination {
              number_id
              number
            }
            ... on FreeNumberDestination {
              destination_number
              timeout
            }
          }
        }
        external {
          callerid
          all {
            ... on ExtensionDestination {
              extension_id
              extension
              prefix
            }
            ... on NumberDestination {
              number_id
              number
            }
            ... on FreeNumberDestination {
              destination_number
              timeout
            }
          }
          noanswer {
            ... on ExtensionDestination {
              extension_id
              extension
              prefix
            }
            ... on NumberDestination {
              number_id
              number
            }
            ... on FreeNumberDestination {
              destination_number
              timeout
            }
          }
          busy {
            ... on ExtensionDestination {
              extension_id
              extension
              prefix
            }
            ... on NumberDestination {
              number_id
              number
            }
            ... on FreeNumberDestination {
              destination_number
              timeout
            }
          }
        }
      }
    }
    ... on Usr {
      extension_id
      extension
      name
      type
    }
  }
}
    `;
export const GetSchemesFragmentDoc = gql`
    fragment GetSchemes on Scheme {
  id
  name
  nmbrPlans {
    id
    name
  }
  override {
    id
    nmbrPlan {
      id
      name
    }
    start
    end
  }
}
    `;
export const AuthenticationTokenFragmentDoc = gql`
    fragment AuthenticationToken on AuthenticationToken {
  accessToken
  refreshToken
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  accountId
  firstName
  lastName
  prefix
  displayName
  languageCode
  timeZone
  userName
  isWholeSaler
}
    `;
export const GetAccountDocument = gql`
    query GetAccount($id: ID!) {
  account(id: $id) {
    ...Account
  }
}
    ${AccountFragmentDoc}`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAccountQuery, Types.GetAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAccountQuery, Types.GetAccountQueryVariables>(GetAccountDocument, options);
      }
export function useGetAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAccountQuery, Types.GetAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAccountQuery, Types.GetAccountQueryVariables>(GetAccountDocument, options);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<Types.GetAccountQuery, Types.GetAccountQueryVariables>;
export const GetAccountsDocument = gql`
    query GetAccounts($filter: AccountsFilterInput, $sort: SortInput, $pagination: PaginationInput, $accountId: ID!) {
  accounts(
    filter: $filter
    sort: $sort
    pagination: $pagination
    accountId: $accountId
  ) {
    totalCount
    accounts {
      ...Account
    }
  }
}
    ${AccountFragmentDoc}`;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAccountsQuery, Types.GetAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAccountsQuery, Types.GetAccountsQueryVariables>(GetAccountsDocument, options);
      }
export function useGetAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAccountsQuery, Types.GetAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAccountsQuery, Types.GetAccountsQueryVariables>(GetAccountsDocument, options);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<Types.GetAccountsQuery, Types.GetAccountsQueryVariables>;
export const GetNmbrDocument = gql`
    query GetNmbr($id: ID!, $accountId: ID!) {
  number(id: $id, accountId: $accountId) {
    ...GetNmbr
  }
}
    ${GetNmbrFragmentDoc}`;

/**
 * __useGetNmbrQuery__
 *
 * To run a query within a React component, call `useGetNmbrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNmbrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNmbrQuery({
 *   variables: {
 *      id: // value for 'id'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetNmbrQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNmbrQuery, Types.GetNmbrQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNmbrQuery, Types.GetNmbrQueryVariables>(GetNmbrDocument, options);
      }
export function useGetNmbrLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNmbrQuery, Types.GetNmbrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNmbrQuery, Types.GetNmbrQueryVariables>(GetNmbrDocument, options);
        }
export type GetNmbrQueryHookResult = ReturnType<typeof useGetNmbrQuery>;
export type GetNmbrLazyQueryHookResult = ReturnType<typeof useGetNmbrLazyQuery>;
export type GetNmbrQueryResult = Apollo.QueryResult<Types.GetNmbrQuery, Types.GetNmbrQueryVariables>;
export const GetNmbrsDocument = gql`
    query GetNmbrs($filter: NmbrFilterInput, $sort: SortInput, $pagination: PaginationInput, $accountId: ID!) {
  numbers(
    filter: $filter
    sort: $sort
    pagination: $pagination
    accountId: $accountId
  ) {
    totalCount
    nmbrs {
      ...GetNmbrs
    }
  }
}
    ${GetNmbrsFragmentDoc}`;

/**
 * __useGetNmbrsQuery__
 *
 * To run a query within a React component, call `useGetNmbrsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNmbrsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNmbrsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetNmbrsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNmbrsQuery, Types.GetNmbrsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNmbrsQuery, Types.GetNmbrsQueryVariables>(GetNmbrsDocument, options);
      }
export function useGetNmbrsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNmbrsQuery, Types.GetNmbrsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNmbrsQuery, Types.GetNmbrsQueryVariables>(GetNmbrsDocument, options);
        }
export type GetNmbrsQueryHookResult = ReturnType<typeof useGetNmbrsQuery>;
export type GetNmbrsLazyQueryHookResult = ReturnType<typeof useGetNmbrsLazyQuery>;
export type GetNmbrsQueryResult = Apollo.QueryResult<Types.GetNmbrsQuery, Types.GetNmbrsQueryVariables>;
export const NmbrsByCategoriesDocument = gql`
    query NmbrsByCategories($searchPattern: String!, $category1: String!, $category2: String!, $accountId: ID!) {
  category1: numbersByCategory(
    searchPattern: $searchPattern
    category: $category1
    accountId: $accountId
  ) {
    ...NmbrsSearchResult
  }
  category2: numbersByCategory(
    searchPattern: $searchPattern
    category: $category2
    accountId: $accountId
  ) {
    ...NmbrsSearchResult
  }
}
    ${NmbrsSearchResultFragmentDoc}`;

/**
 * __useNmbrsByCategoriesQuery__
 *
 * To run a query within a React component, call `useNmbrsByCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNmbrsByCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNmbrsByCategoriesQuery({
 *   variables: {
 *      searchPattern: // value for 'searchPattern'
 *      category1: // value for 'category1'
 *      category2: // value for 'category2'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useNmbrsByCategoriesQuery(baseOptions: Apollo.QueryHookOptions<Types.NmbrsByCategoriesQuery, Types.NmbrsByCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NmbrsByCategoriesQuery, Types.NmbrsByCategoriesQueryVariables>(NmbrsByCategoriesDocument, options);
      }
export function useNmbrsByCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NmbrsByCategoriesQuery, Types.NmbrsByCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NmbrsByCategoriesQuery, Types.NmbrsByCategoriesQueryVariables>(NmbrsByCategoriesDocument, options);
        }
export type NmbrsByCategoriesQueryHookResult = ReturnType<typeof useNmbrsByCategoriesQuery>;
export type NmbrsByCategoriesLazyQueryHookResult = ReturnType<typeof useNmbrsByCategoriesLazyQuery>;
export type NmbrsByCategoriesQueryResult = Apollo.QueryResult<Types.NmbrsByCategoriesQuery, Types.NmbrsByCategoriesQueryVariables>;
export const NmbrsByCategoryDocument = gql`
    query NmbrsByCategory($searchPattern: String!, $category: String!, $accountId: ID!) {
  numbersByCategory(
    searchPattern: $searchPattern
    category: $category
    accountId: $accountId
  ) {
    ...NmbrsSearchResult
  }
}
    ${NmbrsSearchResultFragmentDoc}`;

/**
 * __useNmbrsByCategoryQuery__
 *
 * To run a query within a React component, call `useNmbrsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNmbrsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNmbrsByCategoryQuery({
 *   variables: {
 *      searchPattern: // value for 'searchPattern'
 *      category: // value for 'category'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useNmbrsByCategoryQuery(baseOptions: Apollo.QueryHookOptions<Types.NmbrsByCategoryQuery, Types.NmbrsByCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NmbrsByCategoryQuery, Types.NmbrsByCategoryQueryVariables>(NmbrsByCategoryDocument, options);
      }
export function useNmbrsByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NmbrsByCategoryQuery, Types.NmbrsByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NmbrsByCategoryQuery, Types.NmbrsByCategoryQueryVariables>(NmbrsByCategoryDocument, options);
        }
export type NmbrsByCategoryQueryHookResult = ReturnType<typeof useNmbrsByCategoryQuery>;
export type NmbrsByCategoryLazyQueryHookResult = ReturnType<typeof useNmbrsByCategoryLazyQuery>;
export type NmbrsByCategoryQueryResult = Apollo.QueryResult<Types.NmbrsByCategoryQuery, Types.NmbrsByCategoryQueryVariables>;
export const GetSchemeDocument = gql`
    query GetScheme($id: ID!, $accountId: ID!) {
  scheme(id: $id, accountId: $accountId) {
    ...GetScheme
  }
}
    ${GetSchemeFragmentDoc}`;

/**
 * __useGetSchemeQuery__
 *
 * To run a query within a React component, call `useGetSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetSchemeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSchemeQuery, Types.GetSchemeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSchemeQuery, Types.GetSchemeQueryVariables>(GetSchemeDocument, options);
      }
export function useGetSchemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSchemeQuery, Types.GetSchemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSchemeQuery, Types.GetSchemeQueryVariables>(GetSchemeDocument, options);
        }
export type GetSchemeQueryHookResult = ReturnType<typeof useGetSchemeQuery>;
export type GetSchemeLazyQueryHookResult = ReturnType<typeof useGetSchemeLazyQuery>;
export type GetSchemeQueryResult = Apollo.QueryResult<Types.GetSchemeQuery, Types.GetSchemeQueryVariables>;
export const GetSchemesDocument = gql`
    query GetSchemes($filter: SchemeFilterInput, $sort: SortInput, $pagination: PaginationInput, $accountId: ID!) {
  schemes(
    filter: $filter
    sort: $sort
    pagination: $pagination
    accountId: $accountId
  ) {
    totalCount
    schemes {
      ...GetSchemes
    }
  }
}
    ${GetSchemesFragmentDoc}`;

/**
 * __useGetSchemesQuery__
 *
 * To run a query within a React component, call `useGetSchemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetSchemesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSchemesQuery, Types.GetSchemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSchemesQuery, Types.GetSchemesQueryVariables>(GetSchemesDocument, options);
      }
export function useGetSchemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSchemesQuery, Types.GetSchemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSchemesQuery, Types.GetSchemesQueryVariables>(GetSchemesDocument, options);
        }
export type GetSchemesQueryHookResult = ReturnType<typeof useGetSchemesQuery>;
export type GetSchemesLazyQueryHookResult = ReturnType<typeof useGetSchemesLazyQuery>;
export type GetSchemesQueryResult = Apollo.QueryResult<Types.GetSchemesQuery, Types.GetSchemesQueryVariables>;
export const GetSchemeGraphDocument = gql`
    query GetSchemeGraph($id: ID!, $accountId: ID!) {
  scheme(id: $id, accountId: $accountId) {
    ...GetSchemeGraph
  }
}
    ${GetSchemeGraphFragmentDoc}`;

/**
 * __useGetSchemeGraphQuery__
 *
 * To run a query within a React component, call `useGetSchemeGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemeGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemeGraphQuery({
 *   variables: {
 *      id: // value for 'id'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetSchemeGraphQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSchemeGraphQuery, Types.GetSchemeGraphQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSchemeGraphQuery, Types.GetSchemeGraphQueryVariables>(GetSchemeGraphDocument, options);
      }
export function useGetSchemeGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSchemeGraphQuery, Types.GetSchemeGraphQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSchemeGraphQuery, Types.GetSchemeGraphQueryVariables>(GetSchemeGraphDocument, options);
        }
export type GetSchemeGraphQueryHookResult = ReturnType<typeof useGetSchemeGraphQuery>;
export type GetSchemeGraphLazyQueryHookResult = ReturnType<typeof useGetSchemeGraphLazyQuery>;
export type GetSchemeGraphQueryResult = Apollo.QueryResult<Types.GetSchemeGraphQuery, Types.GetSchemeGraphQueryVariables>;
export const RefreshAccessTokenDocument = gql`
    mutation RefreshAccessToken($input: RefreshAccessTokenInput!) {
  refreshAccessToken(input: $input) {
    authenticationToken {
      ...AuthenticationToken
    }
  }
}
    ${AuthenticationTokenFragmentDoc}`;
export type RefreshAccessTokenMutationFn = Apollo.MutationFunction<Types.RefreshAccessTokenMutation, Types.RefreshAccessTokenMutationVariables>;

/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.RefreshAccessTokenMutation, Types.RefreshAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RefreshAccessTokenMutation, Types.RefreshAccessTokenMutationVariables>(RefreshAccessTokenDocument, options);
      }
export type RefreshAccessTokenMutationHookResult = ReturnType<typeof useRefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationResult = Apollo.MutationResult<Types.RefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationOptions = Apollo.BaseMutationOptions<Types.RefreshAccessTokenMutation, Types.RefreshAccessTokenMutationVariables>;
export const UserDocument = gql`
    query User($id: ID!, $accountId: ID!) {
  user(id: $id, accountId: $accountId) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<Types.UserQuery, Types.UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserQuery, Types.UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserQuery, Types.UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserQuery, Types.UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<Types.UserQuery, Types.UserQueryVariables>;