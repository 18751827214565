import React, { type JSX, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useOnClickOutside } from 'usehooks-ts'

import { Bar, BarButton, Dropdown, DropdownGroup, DropdownItem, Icon, Popover, Tooltip } from '@/ui'

import { useCurrentAccount } from '../utils/currentAccountUtils'
import { useCurrentUser } from '../utils/currentUserUtils'
import { generateLink, RoutePaths } from '../utils/routeUtiles'

import { DarkModeToggleButton } from './DarkModetoggleButton'
import LanguageSelector from './LanguageSelector'
import { Logo as AppLogo } from './Logo'

export interface NavBarProps {
  children?: React.ReactNode
}

export function NavBar({ children }: NavBarProps): JSX.Element {
  return (
    <Bar className='shadow-card' navbar>
      <Logo className='ml-8' isInNav />
      {children}
      <span slot='trailing'>
        <LanguageSelector />
      </span>
      <span slot='trailing'>
        <DarkModeToggleButton inNavbar />
      </span>
      <UserMenu />
    </Bar>
  )
}

export default NavBar

function UserMenu(): JSX.Element | null {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()

  const [isOpen, setIsOpen] = React.useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate()
  const user = useCurrentUser()

  const toggleIsOpen = (): void => {
    setIsOpen(prevIsOpen => !prevIsOpen)
  }

  const handleClose = (): void => {
    setIsOpen(false)
  }

  useOnClickOutside(ref, handleClose)

  if (!currentUser) return null

  return (
    <div ref={ref} className="['--typography-color':initial]" slot='trailing'>
      <Popover open={isOpen} shift>
        <Tooltip label={t('Account options')} placement='bottom-end'>
          <BarButton iconName='generic--user--user' onClick={toggleIsOpen} />
        </Tooltip>

        <Dropdown open={isOpen} slot='popover' closeOnSelect>
          <DropdownGroup label={currentUser.displayName}>
            {user?.isWholeSaler && (
              <DropdownItem
                label={t('Switch Account')}
                value={RoutePaths.MAIN}
                onClick={() => {
                  navigate(RoutePaths.MAIN)
                }}
              >
                <Icon name={'communication--call--swap'} slot='leading'></Icon>
              </DropdownItem>
            )}
            <DropdownItem
              label={t('Log out')}
              value={RoutePaths.MAIN}
              onClick={() => {
                navigate(RoutePaths.LOGOUT)
              }}
            >
              <Icon name={'action--leave'} slot='leading'></Icon>
            </DropdownItem>
          </DropdownGroup>
        </Dropdown>
      </Popover>
    </div>
  )
}

function Logo({ className, isInNav }: { className?: string; isInNav?: boolean }): JSX.Element {
  const account = useCurrentAccount()

  const logo = (
    <AppLogo className={className} iconProps={{ size: 4 }} isInNav={isInNav} textProps={{ variant: 'microcopy' }} />
  )

  if (!account) return <span slot='leading'>{logo}</span>

  return (
    <Link
      className='flex justify-center'
      slot='leading'
      to={generateLink(RoutePaths.ACCOUNT, {
        accountId: account.id,
      })}
    >
      {logo}
    </Link>
  )
}
