import * as React from 'react'

import { cn } from '../utils/uiUtils'

export interface ContainerCenteredProps extends React.ButtonHTMLAttributes<HTMLDivElement> {}

const ContainerCentered = React.forwardRef<HTMLDivElement, ContainerCenteredProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn('flex justify-center py-40 h-screen w-screen', className)} {...props}>
        <div className='w-full max-w-lg'>{children}</div>
      </div>
    )
  }
)

ContainerCentered.displayName = 'ContainerCentered'

export { ContainerCentered }
