import type { JSX } from 'react'

import { Maybe } from '@/types'
import { IconButton, Typography } from '@/ui'

export interface AudiofileLayoutProps {
  children?: React.ReactNode
}

const AudiofileLayout = ({ children }: AudiofileLayoutProps) => (
  <div className='flex'>
    <div className='flex grow'>{children}</div>
    <div className='flex grow-0'>
      <IconButton iconName='communication--av--player--play--alt' slot='trailing' variant='tertiary' />
    </div>
  </div>
)

export interface FormValueProps {
  label: string
  value?: Maybe<string> | undefined | number | boolean | Array<{ label: string; value: string }>
  isBoolean?: boolean
  isSelect?: boolean
  isAudiofile?: boolean
}

export const FormValue = (props: FormValueProps): JSX.Element => {
  const { isAudiofile, isBoolean, isSelect, label, value } = props
  let unpackedValue: string | number | boolean | undefined = 'unkown value'
  if (isBoolean) {
    unpackedValue = value ? 'Yes' : 'No'
  } else if (isSelect) {
    unpackedValue = value instanceof Array ? value?.[0]?.label ?? '' : ''
  } else if (typeof value !== 'object') {
    unpackedValue = value
  } else {
    console.error('Invalid combination of props for FormValue ', props)
  }

  const inner = (
    <div className='flex flex-col'>
      <Typography
        className='w-full'
        lineClamp={1}
        style={{
          '--typography-weight': 400,
          '--typography-color': 'rgb(var(--color-neutral-600))',
        }}
        variant='body-1'
      >
        {label}:
      </Typography>
      <Typography className='w-full' style={{ '--typography-weight': 600 }}>
        {unpackedValue}
      </Typography>
    </div>
  )

  return isAudiofile ? <AudiofileLayout>{inner}</AudiofileLayout> : inner
}

export default FormValue
