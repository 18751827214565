import type { JSX } from 'react'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'

import { Dropdown, DropdownItem, SelectField, SelectFieldProps } from '@/ui'

import { availableLanguages } from '../config/i18nConfig'
import { LocalStorage as LocalStorageEnum } from '../config/local-storage'

const LanguageSelector = (): JSX.Element => {
  const { i18n } = useTranslation()
  const [lang, setLanguage] = useLocalStorage(LocalStorageEnum.LANGUAGE, i18n.language)

  const handleChangeLanguage = async (event: Parameters<NonNullable<SelectFieldProps['onClChange']>>[0]) => {
    const selectedLanguage = event.detail.value[0]?.value as string

    await i18n.changeLanguage(selectedLanguage)

    return setLanguage(selectedLanguage)
  }

  const options = useMemo(
    () =>
      Object.entries(availableLanguages).map(([key, value]) => ({
        value: key,
        label: value,
      })),
    []
  )

  const value = useMemo(() => {
    if (!availableLanguages[lang]) {
      console.warn(`Language ${lang} is not available`)
      return []
    }
    return [
      {
        value: lang,
        label: availableLanguages[lang],
      },
    ]
  }, [lang])

  return (
    <SelectField
      className='pr-1'
      style={{ '--typography-color': 'rgb(var(--font-color-onlight-default))' }}
      value={value}
      closeOnSelect
      fullWidth
      onClChange={handleChangeLanguage}
    >
      <Dropdown>
        {options.map(option => (
          <DropdownItem key={option.value} {...option} />
        ))}
      </Dropdown>
    </SelectField>
  )
}

export default LanguageSelector
