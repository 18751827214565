import React, { type JSX, ReactNode } from 'react'

import { useUserQuery } from '../types/graphqlOperations'
import { type UserFragment } from '../types/graphqlTypes'
import { useDecodedAccessToken } from '../utils/authenticationUtils'

interface CurrentUserContextProps {
  currentUser: UserFragment | null
  loading: boolean
  error: Error | undefined
}

export const CurrentUserContext = React.createContext<CurrentUserContextProps>({
  currentUser: null,
  loading: false,
  error: undefined,
})

export const CurrentUserContextProvider: React.FC<{
  children: ReactNode
}> = ({ children }): JSX.Element => {
  const decodedAccessToken = useDecodedAccessToken()
  const { data, error, loading } = useUserQuery({
    variables: {
      id: decodedAccessToken?.sub ?? '',
      accountId: decodedAccessToken?.account_id ?? '',
    },
    skip: !decodedAccessToken,
  })
  const currentUser = data?.user ?? null

  const value = React.useMemo(
    () => ({
      currentUser,
      loading,
      error,
    }),
    [currentUser, loading, error]
  )

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>
}
