import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Group } from '@/types'
import { List, ListItem, ScrollWrapper, Typography } from '@/ui'

import FormValue from '../../../../components/FormValue'
import PanelLayout from '../../../../components/PanelLayout'
import { type PanelProps } from '../../../../types/nodes'
import { useCurrentAccount } from '../../../../utils/currentAccountUtils'
import { ForwardFields } from '../../../../utils/extensionFormFieldHelpers'

import { GroupNodeData } from './GroupNode'

const GET_EXTENSION = gql`
  query Extension($id: ID!, $accountId: ID!) {
    extension(id: $id, accountId: $accountId) {
      ... on Group {
        type
        name
        extension
        members {
          extension_id
          extension
          name
        }
        allow_individual_forwards
        forwards {
          internal {
            callerid
            all {
              ... on ExtensionDestination {
                extension_id
                extension
                prefix
              }
              ... on NumberDestination {
                number_id
                number
              }
              ... on FreeNumberDestination {
                destination_number
                timeout
              }
            }
            noanswer {
              ... on ExtensionDestination {
                extension_id
                extension
                prefix
              }
              ... on NumberDestination {
                number_id
                number
              }
              ... on FreeNumberDestination {
                destination_number
                timeout
              }
            }
            busy {
              ... on ExtensionDestination {
                extension_id
                extension
                prefix
              }
              ... on NumberDestination {
                number_id
                number
              }
              ... on FreeNumberDestination {
                destination_number
                timeout
              }
            }
          }
          external {
            callerid
            all {
              ... on ExtensionDestination {
                extension_id
                extension
                prefix
              }
              ... on NumberDestination {
                number_id
                number
              }
              ... on FreeNumberDestination {
                destination_number
                timeout
              }
            }
            noanswer {
              ... on ExtensionDestination {
                extension_id
                extension
                prefix
              }
              ... on NumberDestination {
                number_id
                number
              }
              ... on FreeNumberDestination {
                destination_number
                timeout
              }
            }
            busy {
              ... on ExtensionDestination {
                extension_id
                extension
                prefix
              }
              ... on NumberDestination {
                number_id
                number
              }
              ... on FreeNumberDestination {
                destination_number
                timeout
              }
            }
          }
        }
      }
    }
  }
`

export interface GroupPanelProps extends PanelProps<GroupNodeData> {}

export const GrpPanel = ({
  node: {
    data: {
      extension: { extension_id: extensionId },
    },
  },
  onClose,
}: GroupPanelProps) => {
  const { t } = useTranslation()
  const account = useCurrentAccount()

  const { data, loading } = useQuery<{ extension: Group }>(GET_EXTENSION, {
    variables: {
      id: extensionId,
      accountId: account?.id,
    },
  })

  const extension = data?.extension

  return (
    <PanelLayout loading={loading} title={t('Group')} onClose={onClose}>
      <PanelLayout.GroupHeading>{t('General')}</PanelLayout.GroupHeading>
      <FormValue label={t('Name')} value={extension?.name} />
      <FormValue label={t('Extension number')} value={extension?.extension} />
      <PanelLayout.GroupDivider />
      <PanelLayout.GroupHeading>{t('Members')}</PanelLayout.GroupHeading>
      {extension?.members ? (
        <ScrollWrapper maxHeight={28}>
          <List>
            {extension?.members?.map(member => (
              <ListItem
                key={member.extension}
                primaryText={member.name ?? member.extension?.toString() ?? 'Unknown'}
                secondaryText={member.name ? member.extension?.toString() ?? '' : ''}
              />
            ))}
          </List>
        </ScrollWrapper>
      ) : (
        <Typography variant='microcopy'>{t('No members')}</Typography>
      )}

      <PanelLayout.GroupDivider />
      <PanelLayout.GroupHeading>{t('Configuration')}</PanelLayout.GroupHeading>
      <FormValue label={t('Allow indiviual forwards')} value={extension?.allow_individual_forwards} isBoolean />
      <PanelLayout.GroupDivider />
      <ForwardFields forwards={extension?.forwards} />
    </PanelLayout>
  )
}

export default GrpPanel
