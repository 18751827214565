import type { CallflowNode, FlowNodeAddonProps as Addon, Nodes, NumberplanMap, Positions } from '../types/nodes'

import dagre from 'dagre'
import { toPng } from 'html-to-image'
import _, { uniqueId } from 'lodash'
import { RefObject, useCallback, useState } from 'react'
import { DefaultEdgeOptions, type Edge, MarkerType, type Node } from 'reactflow'

import {
  Destination,
  Extension,
  type Extensionpicker,
  type Group,
  type Ivr,
  type Lbr,
  type Mapping,
  Maybe,
  type NmbrPlan,
  type Queue,
  type Scheme,
  type Timeplan,
  type TimeplanOption,
} from '@/types'

import { BuildNodeStrategy, DomainFilterCriteria, GraphFilterCriteria } from '../types/filters'

import formatInternationalPhoneNumber from './phoneNumberUtils'

/**
 * creates a layout and returns nodes and edges with positions
 * @param
 * @returns
 */
export function calculatePositions({
  edges = [],
  nodes = [],
  nodesep = 100,
  rankdir = 'LR',
  ranksep = 300,
}: {
  nodes: Nodes
  edges: Edge[]
  rankdir?: 'LR' | 'TB'
  nodesep?: number
  ranksep?: number
}) {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  // Card min and max width is now 20rem = 320px + spacingInPx as buffer
  const nodeWidth = 320
  const nodeHeight = 90

  dagreGraph.setGraph({
    edgesep: 50,
    rankdir,
    nodesep,
    ranksep,
  })

  nodes.forEach(node => {
    const height = nodeHeight + (node.data.addons?.length ?? 0) * 40

    dagreGraph.setNode(node.id, {
      width: nodeWidth,
      height,
    })
  })

  edges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target)
  })
  dagre.layout(dagreGraph)

  const positions: Positions = nodes.reduce((acc, node) => {
    const nodeWithPosition = dagreGraph.node(node.id)

    return {
      ...acc,
      [node.id]: {
        x: node.type === 'nmbr' ? 0 : nodeWithPosition.x - nodeWithPosition.width / 2,
        y: nodeWithPosition.y - nodeWithPosition.height / 2,
      },
    }
  }, {})

  return positions
}

/**
 * find all paths from the selected node to the root nodes
 * uses the depth first search algorithm to find all paths
 * @param param0
 * @returns
 */
function findPathsToRoot({ edges, nodeId, nodes }: { nodes: Nodes; edges: Edge[]; nodeId: string }): string[][] {
  const paths: string[][] = []
  const visited = new Set<string>()

  function dfs(currentNodeId: string, path: string[]) {
    visited.add(currentNodeId)
    path.push(currentNodeId)

    const node = nodes.find(node => node.id === currentNodeId)
    if (node) {
      const incomingEdges = edges.filter(edge => edge.target === currentNodeId)
      if (incomingEdges.length === 0) {
        paths.push([...path])
      } else {
        incomingEdges.forEach(edge => {
          const sourceNode = nodes.find(node => node.id === edge.source)
          if (sourceNode && !visited.has(sourceNode.id)) {
            dfs(sourceNode.id, path)
          }
        })
      }
    }

    path.pop()
    visited.delete(currentNodeId)
  }

  const startNode = nodes.find(node => node.id === nodeId)
  if (startNode) {
    dfs(startNode.id, [])
  }

  return paths
}

/**
 * this function modifies the nodes and edges to add / remove
 * highlight the selected node and its connected nodes and edges
 * the changes are directly applied to the nodes and edges by deepmerging
 */

function getDefaultEdgeStyle() {
  return {
    selected: false,
    animated: false,
  }
}

function getHighlightEdgeStyle() {
  return {
    selected: true,
    animated: true,
  }
}

const applyPathHighlightStyle = ({ edges, nodes, remove }: { nodes: Nodes; edges: Edge[]; remove?: boolean }): void => {
  if (remove) {
    edges.forEach(edge => {
      _.merge(edge, getDefaultEdgeStyle())
    })

    nodes.forEach(node => {
      node.data.addons?.forEach(addon => {
        addon.highlight = false
      })
      node.data = { ...node.data }
    })
  } else {
    edges.forEach(edge => {
      _.merge(edge, getHighlightEdgeStyle())

      const node = nodes.find(node => node.id === edge.source)
      if (node) {
        const addon = node.data.addons?.find(addon => addon.id === edge.sourceHandle)

        if (addon) {
          // NOTE: we need to clone the data object to trigger a re-render
          node.data = { ...node.data, highlight: true }
        }
      }
    })
  }
}

const filterPathElements = ({
  edges,
  exclude,
  nodes,
  pathsToRoots,
}: {
  nodes: Nodes
  edges: Edge[]
  pathsToRoots: string[][]
  exclude?: boolean
}): { nodes: Nodes; edges: Edge[] } => {
  // Flatten the paths array and remove duplicates
  const pathNodes = [...new Set(pathsToRoots.flat())]

  // Find all edges that connect the path nodes
  const pathEdges = edges.filter(edge => pathNodes.includes(edge.source) && pathNodes.includes(edge.target))

  if (exclude) {
    // Select all nodes and edges that are not part of the paths
    const outPathNodes = nodes.filter(node => !pathNodes.includes(node.id))
    const outPathEdges = edges.filter(edge => !pathEdges.includes(edge))

    return { nodes: outPathNodes, edges: outPathEdges }
  }
  // Select all nodes and edges that are part of the paths
  const inPathNodes = nodes.filter(node => pathNodes.includes(node.id))
  const inPathEdges = edges.filter(edge => pathEdges.includes(edge))

  return { nodes: inPathNodes, edges: inPathEdges }
}

/**
 * returns the nodes and egdes with highlighting for the edges and addons
 * which are part of the paths to roots from the selected nodeid
 * @param
 * @returns
 */
export const applyAllHighlights = ({
  edges,
  loops = [],
  nodeId,
  nodes,
}: {
  nodes: Nodes
  edges: Edge[]
  nodeId?: string | null
  loops?: Array<{ nodes: Nodes; edges: Edge[] }>
}): { nodes: Nodes; edges: Edge[] } => {
  // Get all paths from the selected node to the root nodes, can also be empty
  const pathsToRoots = nodeId ? findPathsToRoot({ nodeId, edges, nodes }) : []

  // Remove all props for non-path nodes and edges
  applyPathHighlightStyle({
    nodes,
    edges,
    remove: true,
  })

  // if a node is selected we need to highlight it and all connected edges and nodes
  if (nodeId) {
    applyPathHighlightStyle(filterPathElements({ nodes, edges, pathsToRoots }))
  }

  applyLoopyStyle(filterLoopElements({ nodes, edges, loops }))

  return { nodes, edges }
}

/**
 * finds all loops in the graph
 * @returns
 */
export function findLoops({ edges, nodes }: { nodes: Nodes; edges: Edge[] }): Array<{ nodes: Nodes; edges: Edge[] }> {
  const adjacencyList = new Map<string, string[]>()

  // Create adjacency list
  nodes.forEach(node => adjacencyList.set(node.id, []))
  edges.forEach(edge => adjacencyList.get(edge.source)?.push(edge.target))

  const visited = new Set<string>()
  const path: string[] = []
  const loops: Array<{ nodes: Nodes; edges: Edge[] }> = []

  nodes.forEach(node => {
    if (!visited.has(node.id)) {
      dfs(node.id)
    }
  })

  return loops

  function dfs(nodeId: string): void {
    if (path.includes(nodeId)) {
      const loopNodeIds = path.slice(path.indexOf(nodeId))
      const loopNodes = nodes.filter(node => loopNodeIds.includes(node.id))
      const loopEdges = edges.filter(edge => loopNodeIds.includes(edge.source) && loopNodeIds.includes(edge.target))
      loops.push({ nodes: loopNodes, edges: loopEdges })
      return
    }

    if (visited.has(nodeId)) {
      return
    }

    visited.add(nodeId)
    path.push(nodeId)

    const neighbors = adjacencyList.get(nodeId) || []
    neighbors.forEach(neighbor => {
      dfs(neighbor)
    })

    path.pop()
  }
}

export function filterLoopElements({
  edges,
  loops,
}: {
  nodes: Nodes
  edges: Edge[]
  loops: Array<{ nodes: Nodes; edges: Edge[] }>
}): {
  nodes: Nodes
  edges: Edge[]
} {
  const allLoopedEdgeIds = loops.reduce<string[]>((acc, { edges: loopEdges }) => {
    acc.push(...loopEdges.map(e => e.id))
    return acc
  }, [])

  const loopedEdges = edges.filter(edge => allLoopedEdgeIds.includes(edge.id))

  return { nodes: [], edges: loopedEdges }
}

/**
 * applies the loopy style to elements that are part of a loop
 */
function applyLoopyStyle({ edges }: { nodes: Nodes; edges: Edge[] }): void {
  edges.forEach(edge => {
    edge.selected = true
    edge.style = {
      ...edge.style,
      stroke: 'rgb(var(--system-color-error-main))',
    }
  })
}

/**
 * filters out all nodes and edges that are not connected to the root "nmbr" nodes
 * @param nodes
 * @param edges
 * @returns
 */
export function filterNodesAndEdgesByConnectedState({ edges, nodes }: { nodes: Nodes; edges: Edge[] }): {
  connected: { nodes: Nodes; edges: Edge[] }
  unconnected: { nodes: Nodes; edges: Edge[] }
} {
  const rootNodes = nodes.filter(node => node.type === 'nmbr')
  const visited = new Set<string>()

  function dfs(node: Node) {
    visited.add(node.id)
    edges
      .filter(edge => edge.source === node.id)
      .forEach(edge => {
        const targetNode = nodes.find(n => n.id === edge.target)
        if (targetNode && !visited.has(targetNode.id)) {
          dfs(targetNode)
        }
      })
  }

  rootNodes.forEach(rootNode => {
    dfs(rootNode)
  })

  return {
    connected: {
      nodes: nodes.filter(node => visited.has(node.id)),
      edges: edges.filter(edge => visited.has(edge.source) && visited.has(edge.target)),
    },
    unconnected: {
      nodes: nodes.filter(node => !visited.has(node.id)),
      edges: edges.filter(edge => !visited.has(edge.source) || !visited.has(edge.target)),
    },
  }
}

/**
 * Apply filtering to domain model, does not modify the original scheme!
 * by applying hidden flags to extensions and numberplans which are not supposed to be shown in the graph
 * the result scheme should be usable as input for the graph layouting algorithm
 * @param scheme
 * @param filter
 * @returns new scheme
 */
export function filterDomainModel(scheme: Scheme, filter: DomainFilterCriteria): Scheme {
  /**
   * create deep copy because the original scheme is immutable object
   */
  const newScheme: Scheme = JSON.parse(JSON.stringify(scheme)) // deep copy

  /**
   * reduce the numberplans by hiding them if they are not in the filter
   * this will also hide the extensions that are no longer connected to a root nmbr node
   */
  if (filter.dialplans.length) {
    newScheme.nmbrPlans = newScheme.nmbrPlans.reduce((acc, plan) => {
      if (filter.dialplans.includes(plan.id)) {
        acc.push(plan)
      }
      return acc
    }, [] as NmbrPlan[])
  }

  if (filter.number) {
    // remove the numbers that are not in the filter
    newScheme.nmbrPlans = newScheme.nmbrPlans.reduce((acc, nmbrPlan) => {
      nmbrPlan.mapping = nmbrPlan.mapping.filter(mapping => mapping.nmbr.id === filter.number)

      if (nmbrPlan.mapping.length) {
        acc.push(nmbrPlan)
      }
      return acc
    }, [] as NmbrPlan[])
  }

  /**
   * remove members from queus and groups that are not in the filter
   */
  newScheme.extensions = newScheme.extensions?.map(ext => {
    if (ext.type === 'grp' || ext.type === 'queue') {
      return {
        ...ext,
        members: [],
      }
    }
    return ext
  }) as Maybe<Extension[]>

  return newScheme
}

export function filterGraph(data: { nodes: Nodes; edges: Edge[] }, filter: GraphFilterCriteria) {
  if (!filter.showUnconnected) {
    // find all unconnected nodes
    const { unconnected } = filterNodesAndEdgesByConnectedState(data)
    data.nodes = data.nodes.filter(node => !unconnected.nodes.includes(node))
    data.edges = data.edges.filter(edge => !unconnected.edges.includes(edge))
  }

  return data
}

/**
 * create map between numbers and nmbrPlans and the extension mapping for the number for this
 * specificing nmbrPlan
 */
export function mapNumberToNmbrPlanMappings(scheme: Scheme) {
  return scheme.nmbrPlans.reduce(
    (acc, plan) => {
      return plan.mapping.reduce((innerAcc, mapping) => {
        if (!innerAcc[mapping.nmbr.id]) {
          innerAcc[mapping.nmbr.id] = []
        }
        innerAcc[mapping.nmbr.id]?.push({
          plan,
          mapping,
        })
        return innerAcc
      }, acc)
    },
    {} as Record<string, Array<{ plan: NmbrPlan; mapping: Mapping }>>
  )
}

type SchemaToDataStrategy = (scheme: Scheme, positions?: Positions) => { nodes: Nodes; edges: Edge[] }

const strategies: Record<BuildNodeStrategy, SchemaToDataStrategy> = {
  [BuildNodeStrategy.FULLY_INTERCONNECTING]: buildNodesStrategyFullyInterconnecting,
  [BuildNodeStrategy.UNIQUE_FLOW_PER_NUMBER_NUMBERPLAN]: buildNodesStrategyUniqueFlowPerNumberNumberplan,
  [BuildNodeStrategy.UNIQUE_FLOW_PER_NUMBER]: buildNodesStrategyUniqueFlowPerNumber,
}

/**
 * maps schema to collection of nodes and edges which represent the graph structure of the schema
 */
export function buildNodes(
  scheme: Scheme,
  positions: Positions = {},
  strategy: BuildNodeStrategy = BuildNodeStrategy.FULLY_INTERCONNECTING
) {
  console.debug(`Building nodes with strategy: ${strategy}`)
  return strategies[strategy](scheme, positions)
}

/**
 * strategy for building a fully connected, non duplicate containing graph
 * @param scheme
 * @param positions
 * @returns
 */
function buildNodesStrategyFullyInterconnecting(
  scheme: Scheme,
  positions: Positions = {}
): {
  nodes: Nodes
  edges: Edge[]
} {
  const nmbrPlanMappings = mapNumberToNmbrPlanMappings(scheme)
  const nmbrNodes = buildNmbrNodes(nmbrPlanMappings, positions)
  const extensionNodes = buildExtensionNodes(scheme.extensions, positions)

  return {
    nodes: [...nmbrNodes.nodes, ...(extensionNodes?.nodes ?? [])] as Nodes,
    edges: [...nmbrNodes.edges, ...(extensionNodes?.edges ?? [])],
  }
}

function buildNodesStrategyUniqueFlowPerNumber(scheme: Scheme, positions: Positions = {}) {
  const nmbrPlanMappings = mapNumberToNmbrPlanMappings(scheme)
  const nmbrNodes = buildNmbrNodes(nmbrPlanMappings, positions)
  const extensionNodes = buildExtensionNodes(scheme.extensions, positions)

  const result: typeof nmbrNodes = { nodes: [], edges: [] }

  for (const [nmbrId] of Object.entries(nmbrPlanMappings)) {
    const nmbrNode = JSON.parse(JSON.stringify(nmbrNodes.nodes.find(node => node.id === nmbrId))) as CallflowNode

    if (!nmbrNode) {
      console.error(`No nmbr node found for id: ${nmbrId}`)
      continue
    }
    const prefix = `${nmbrId}`
    const extensionNetwork = duplicateAndUniqifyNodesAndEdges(extensionNodes, prefix)
    extensionNetwork.nodes.push(nmbrNode)

    const edges = nmbrNodes.edges.filter(edge => edge.source === nmbrNode.id)
    extensionNetwork.edges.push(
      ...edges.map(edge => ({
        ...edge,
        target: `${prefix}-${edge.target}`,
      }))
    )
    // Filter out all extensions that are not connected to nmbr/plan root
    const { connected } = filterNodesAndEdgesByConnectedState(extensionNetwork)

    result.nodes.push(...connected.nodes)
    result.edges.push(...connected.edges)
  }

  return result
}

/**
 * the idea is to have a copy of the full extensions network of nodes and edges
 * and filter out all nodes / edges that are not connected to the nmbr/plan root
 * than merge the results per mapping into a big collection where
 * each root has its own connected network of extensions
 */
function buildNodesStrategyUniqueFlowPerNumberNumberplan(scheme: Scheme, positions: Positions = {}) {
  const nmbrPlanMappings = mapNumberToNmbrPlanMappings(scheme)
  const nmbrNodes = buildNmbrNodes(nmbrPlanMappings, positions)
  const extensionNodes = buildExtensionNodes(scheme.extensions, positions)

  const result: typeof nmbrNodes = { nodes: [], edges: [] }

  /**
   * for each of the nr mappings we duplicate the extension network
   * such that the connected network will be unique for the root
   */
  for (const [nmbrId, mappings] of Object.entries(nmbrPlanMappings)) {
    const nmbrNode = JSON.parse(JSON.stringify(nmbrNodes.nodes.find(node => node.id === nmbrId))) as CallflowNode

    if (!nmbrNode) {
      console.error(`No nmbr node found for id: ${nmbrId}`)
      continue
    }

    for (const map of mappings) {
      const prefix = `${nmbrId}-${map.plan.id}`
      const extensionNetwork = duplicateAndUniqifyNodesAndEdges(extensionNodes, prefix)

      extensionNetwork.nodes.push(nmbrNode)

      // find edge from nmbrPlanAddon to extension
      const addon = nmbrNode.data.addons.find(addon => addon.type === 'nmbrPlan' && addon.plan?.id === map.plan.id)
      if (!addon) {
        console.error(`No addon found for plan ${map.plan.id}`)
        continue
      }

      const edge = nmbrNodes.edges.find(edge => edge.sourceHandle === addon.id)
      if (!edge) {
        console.error(`No edge found for addon ${addon.id}`)
        continue
      }

      extensionNetwork.edges.push({
        ...edge,
        target: `${prefix}-${edge.target}`,
      })

      // Filter out all extensions that are not connected to nmbr/plan root
      const { connected } = filterNodesAndEdgesByConnectedState(extensionNetwork)

      result.nodes.push(...connected.nodes)
      result.edges.push(...connected.edges)
    }
  }
  return result
}

/**
 * @param data
 * @param prefix
 * @returns duplicated data
 */
export function duplicateAndUniqifyNodesAndEdges(
  data?: { nodes: Nodes; edges: Edge[] },
  prefix: string = `pr${uniqueId()}`
): { nodes: Nodes; edges: Edge[] } {
  if (!data) return { nodes: [], edges: [] }

  const duplicatedData = JSON.parse(JSON.stringify(data)) as { nodes: Nodes; edges: Edge[] }
  prefixifyNodesAndEdges(duplicatedData, prefix)
  return duplicatedData
}

/**
 * prefixes relevant id's on node and edge objects
 * @param data
 * @param prefix
 * @returns modified data
 */
export function prefixifyNodesAndEdges(data: { nodes: Nodes; edges: Edge[] }, prefix: string) {
  data.nodes.forEach(node => {
    node.id = `${prefix}-${node.id}`
    node.data.addons?.forEach(addon => {
      addon.id = `${prefix}-${addon.id}`
    })
  })

  data.edges.forEach(edge => {
    edge.id = `${prefix}-${edge.id}`
    edge.source = `${prefix}-${edge.source}`
    edge.target = `${prefix}-${edge.target}`
    edge.sourceHandle = `${prefix}-${edge.sourceHandle}`
  })
  return data
}

/**
 * build the number nodes and edges towards the first extensions
 * @param scheme
 * @param positions
 * @returns
 */
function buildNmbrNodes(mappedNmbrsAndPlans: NumberplanMap, positions: Positions) {
  const nodes: Nodes = []
  const edges: Edge[] = []

  const buildNode = buildNodeWithPositions(positions)

  for (const [, mappings] of Object.entries(mappedNmbrsAndPlans)) {
    if (!mappings.length) continue

    const nmbr = mappings[0]?.mapping.nmbr
    if (!nmbr) {
      console.error('No nmbr found')
      continue
    }

    const addons: Addon[] = []
    for (const mapping of mappings) {
      const { addon, edge } = buildAddonAndEdge('nmbrPlan', nmbr.id, mapping.mapping.extension.extension_id, {
        label: mapping.plan.name,
        plan: mapping.plan,
      })

      if (edge && addon) {
        addons.push(addon)
        edges.push(edge)
      }
    }

    const newNode = buildNode(nmbr.id, 'nmbr', {
      id: nmbr.id,
      pstnNmbr: formatInternationalPhoneNumber(nmbr.value) ?? '',
      addons,
    })

    if (newNode) {
      nodes.push(newNode)
    }
  }

  return { nodes, edges }
}

/**
 * certain node.type values are not allowed in react flow because they might conflict with reserved keywords
 * therefor map the type to a valid react flow node type
 * @param extType
 * @returns
 */
export function mapExtTypeToReactFlowNodeType(extType: string) {
  const typeReMap: Record<string, string> = {
    group: 'grp',
    timeplan: 'tbr',
  }
  return typeReMap[extType] ?? extType
}

/**
 * build the nodes and edges for the given extensions
 * and map the given positions on the nodes while at it
 * @param scheme
 * @param positions
 * @returns
 */
function buildExtensionNodes(extensions: Extension[] | null | undefined, positions: Positions = {}) {
  /**
   * curried function to build Node with position if available
   * @see buildNodeWithPositions
   */
  const buildNodeFn = buildNodeWithPositions(positions)

  /**
   * callback function to build the extension node with its
   * addons and edges to other extensions
   */
  const buildExtensionNodeAndEdgesFn = function <F extends Extension>(extension: F) {
    const { addons, edges, nodes } = buildAddonsAndEdges(extension, buildNodeFn)

    const mappedType = mapExtTypeToReactFlowNodeType(extension.type)

    return {
      nodes: [
        ...nodes,
        buildNodeFn(extension.extension_id, mappedType, {
          extensionNmbr: extension.extension,
          title: extension.name,
          addons,
          extension,
        }),
      ] as Nodes,
      edges,
    }
  }

  return extensions
    ?.filter(ext => !ext.hidden)
    .map(buildExtensionNodeAndEdgesFn)
    .reduce(
      (acc, { edges, nodes }) => {
        acc.nodes.push(...nodes)
        acc.edges.push(...edges)
        return acc
      },
      { nodes: [], edges: [] }
    )
}

const buildForwardAddonAndEdge = (buildNodeFn: ReturnType<typeof buildNodeWithPositions>) => {
  return function buildForwardAddonAndEdgeFn(
    type: string,
    source: string,
    forward: Destination,
    addonProps?: Addon,
    edgeProps?: DefaultEdgeOptions
  ): { addon: Addon; edge: Edge; node?: CallflowNode } {
    let targetExtId: string | undefined
    let node

    if (forward.__typename === 'FreeNumberDestination') {
      targetExtId = uniqueId()
      node = buildNodeFn(targetExtId, 'freeNumber', { ...forward, addons: [] as Addon[] })
    } else if (forward.__typename === 'ExtensionDestination') {
      targetExtId = forward.extension_id
    } else if (forward.__typename === 'NumberDestination') {
      targetExtId = forward.number_id
    } else {
      // TODO: Handle error more gracefully (e.g. sane default)
      throw new Error('No target extension id found')
    }

    const { addon, edge } = buildAddonAndEdge(type, source, targetExtId, addonProps, edgeProps)
    return {
      addon,
      edge,
      node,
    }
  }
}
/**
 * this is a big function which translates the different types of extensions
 * into a collection of addons and edges and the occasional node
 * @param extension
 * @param buildNodeFn this is a curried function to build a node taken into account positioning data
 * @returns
 */
function buildAddonsAndEdges<T extends Extension>(
  extension: T,
  buildNodeFn: ReturnType<typeof buildNodeWithPositions>
): {
  addons: Addon[]
  edges: Edge[]
  /**
   * the nodes returned here are not typical extensions by themselves but are results of
   * the addon and edge building process
   */
  nodes: Nodes
} {
  const { extension_id: extensionId } = extension

  const buildForwardAddonAndEdgeFn = buildForwardAddonAndEdge(buildNodeFn)

  const nodes: Nodes = []
  const edges: Edge[] = []
  const addons: Addon[] = []

  switch (extension.type) {
    case 'group': {
      const data = extension as Group

      if (data.forwards?.external?.all) {
        const { addon, edge, node } = buildForwardAddonAndEdgeFn(
          'allForward',
          extensionId,
          data.forwards?.external?.all,
          { internal: false }
        )
        addons.push(addon)
        edges.push(edge)
        node && nodes.push(node)
      }
      if (data.forwards?.external?.busy) {
        const { addon, edge, node } = buildForwardAddonAndEdgeFn(
          'busyForward',
          extensionId,
          data.forwards?.external?.busy,
          { internal: false }
        )
        addons.push(addon)
        edges.push(edge)
        node && nodes.push(node)
      }
      if (data.forwards?.external?.noanswer) {
        const { addon, edge, node } = buildForwardAddonAndEdgeFn(
          'noAnswerForward',
          extensionId,
          data.forwards?.external?.noanswer,
          { internal: false }
        )
        addons.push(addon)
        edges.push(edge)
        node && nodes.push(node)
      }
      if (data.forwards?.internal?.all) {
        const { addon, edge, node } = buildForwardAddonAndEdgeFn(
          'allForward',
          extensionId,
          data.forwards?.internal?.all,
          { internal: true }
        )
        addons.push(addon)
        edges.push(edge)
        node && nodes.push(node)
      }
      if (data.forwards?.internal?.busy) {
        const { addon, edge, node } = buildForwardAddonAndEdgeFn(
          'busyForward',
          extensionId,
          data.forwards?.internal?.busy,
          { internal: true }
        )
        addons.push(addon)
        edges.push(edge)
        node && nodes.push(node)
      }
      if (data.forwards?.internal?.noanswer) {
        const { addon, edge, node } = buildForwardAddonAndEdgeFn(
          'noAnswerForward',
          extensionId,
          data.forwards?.internal?.noanswer,
          { internal: true }
        )
        addons.push(addon)
        edges.push(edge)
        node && nodes.push(node)
      }

      if (data.expanded) {
        data.members?.forEach(member => {
          const { addon, edge } = buildAddonAndEdge('member', extensionId, member.extension_id, member)

          addons.push(addon)
          edges.push(edge)
        })
      }
      break
    }
    case 'ivr': {
      const data = extension as Ivr
      if (data.forward) {
        const { addon, edge, node } = buildForwardAddonAndEdgeFn('noOptionForward', extensionId, data.forward)
        addons.push(addon)
        edges.push(edge)
        node && nodes.push(node)
      }

      data.options?.forEach(option => {
        const { addon, edge } = buildAddonAndEdge('ivrOption', extensionId, option.extension_id, option)
        addons.push(addon)
        edges.push(edge)
      })
      break
    }
    case 'lbr': {
      const data = extension as Lbr
      if (data.lbr_type === 'cli') {
        if (data.fallback_anonymous) {
          const { addon, edge, node } = buildForwardAddonAndEdgeFn(
            'fallbackAnonymous',
            extensionId,
            data.fallback_anonymous
          )
          addons.push(addon)
          edges.push(edge)
          node && nodes.push(node)
        }
        if (data.fallback) {
          const { addon, edge, node } = buildForwardAddonAndEdgeFn('fallback', extensionId, data.fallback)
          addons.push(addon)
          edges.push(edge)
          node && nodes.push(node)
        }
      } else {
        console.error(`Unknown lbr type: ${data.lbr_type}`)
      }

      break
    }
    case 'queue': {
      const data = extension as Queue
      if (data.forward) {
        const { addon, edge, node } = buildForwardAddonAndEdgeFn('noAnswerForward', extensionId, data.forward)
        addons.push(addon)
        edges.push(edge)
        node && nodes.push(node)
      }
      if (data.idle) {
        const { addon, edge, node } = buildForwardAddonAndEdgeFn('noAgent', extensionId, data.idle)
        addons.push(addon)
        edges.push(edge)
        node && nodes.push(node)
      }
      if (data.expanded) {
        data.members?.forEach(member => {
          const { addon, edge } = buildAddonAndEdge('member', extensionId, member.extension_id, member)

          addons.push(addon)
          edges.push(edge)
        })
      }

      break
    }
    case 'timeplan': {
      const data = extension as Timeplan

      if (data.fallback) {
        const { addon, edge, node } = buildForwardAddonAndEdgeFn('failOver', extensionId, data.fallback)
        addons.push(addon)
        edges.push(edge)
        node && nodes.push(node)
      }
      const options = data.options ?? []
      options.forEach((option: TimeplanOption) => {
        const { addon, edge } = buildAddonAndEdge('scheduleOption', extensionId, option.extension_id, {
          start: option.start,
          end: option.end,
        })
        addons.push(addon)
        edges.push(edge)
      })

      break
    }
    case 'extensionpicker': {
      const data = extension as Extensionpicker

      if (data.fallback) {
        const { addon, edge, node } = buildForwardAddonAndEdgeFn('noAnswerForward', extensionId, data.fallback)
        addons.push(addon)
        edges.push(edge)
        node && nodes.push(node)
      }
      break
    }

    default:
  }

  return { addons, edges, nodes }
}

/**
 * returns curry function to build Node and pass in position if available in positions
 * @param positions
 * @returns
 */
function buildNodeWithPositions(positions: Positions) {
  return function buildNodeWithPositionsFn<X extends CallflowNode['data']>(
    id: string,
    type: CallflowNode['type'],
    data: X
  ): CallflowNode {
    const position = positions[id] ?? { x: 0, y: 0 }
    console.debug(`buildNode ${id}`, { id, type, data, position })
    return {
      id,
      type,
      position,
      data,
    }
  }
}

function buildAddon(type: string, addonProps?: object) {
  const addonId = uniqueId()
  return {
    ...addonProps,
    id: addonId,
    type,
  }
}

function buildEdge(
  source: string,
  target: string,
  sourceHandle?: string,
  targetHandle?: string,
  edgeProps?: DefaultEdgeOptions
) {
  const edgeId = uniqueId()

  const edge: Edge = {
    ...edgeProps,
    id: edgeId,
    source,
    sourceHandle,
    target,
    targetHandle,
    markerEnd: {
      ...(typeof edgeProps?.markerEnd === 'object' ? edgeProps.markerEnd : {}),
      type: MarkerType.ArrowClosed,
      width: 24,
      height: 24,
      strokeWidth: 0,
      color: 'rgb(var(--behaviour-selected-tertiary))',
    },
  }
  return edge
}

function buildAddonAndEdge(
  type: string,
  source: string,
  target: string,
  addonProps?: object,
  edgeProps?: DefaultEdgeOptions
) {
  const addon = buildAddon(type, addonProps)
  const edge = buildEdge(source, target, addon.id, undefined, edgeProps)
  return { addon, edge }
}

function downloadImage(dataUrl: string) {
  const a = document.createElement('a')

  a.setAttribute('download', `callflow-${uniqueId()}.png`)
  a.setAttribute('href', dataUrl)
  a.click()
}

export const useCallflowDownload = (ref: RefObject<HTMLElement | null>) => {
  const [error, setError] = useState(null)

  const onClick = useCallback(() => {
    if (!ref.current) return
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    return toPng(ref.current, {
      backgroundColor: '#FFF',
      quality: 1,
    })
      .then(downloadImage)
      .catch(setError)
  }, [ref])

  return {
    onClick,
    error,
  }
}
