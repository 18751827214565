import type { JSX } from 'react'

import { useTranslation } from 'react-i18next'
import { type NodeProps } from 'reactflow'

import { FlowNodeAddonProps, NodeDataBase } from '../../../../types/nodes'
import { FlowNode } from '../FlowNode'

export interface IVROptionAddonProps extends FlowNodeAddonProps {
  type: 'ivrOption'
  option: string
}

export interface IVRNodeData extends NodeDataBase {
  addons?: Array<
    | IVROptionAddonProps
    | ({
        type: 'busyForward' | 'noAnswerForward' | 'noOptionForward'
      } & FlowNodeAddonProps)
  >
}

export const IVRNode = (props: NodeProps<IVRNodeData>): JSX.Element => {
  const { t } = useTranslation()
  const { data } = props
  return (
    <FlowNode
      {...props}
      addons={data.addons?.reduce<FlowNodeAddonProps[]>((prev, AddonProps) => {
        if (AddonProps.type === 'noAnswerForward') {
          return [
            ...prev,
            {
              icon: 'communication--call--forward--no--answer',
              label: t('No Answer Forward'),
              ...AddonProps,
            },
          ]
        } else if (AddonProps.type === 'busyForward') {
          return [
            ...prev,
            {
              icon: 'communication--call--forward--busy',
              label: t('On Busy Forward'),
              ...AddonProps,
            },
          ]
        } else if (AddonProps.type === 'ivrOption') {
          const ivrOptionProps = AddonProps
          return [
            ...prev,
            {
              icon: 'communication--call--dialpad',
              label: t('Dialpad {{nr}}', { nr: ivrOptionProps.option }),
              ...ivrOptionProps,
            },
          ]
        } else if (AddonProps.type === 'noOptionForward') {
          return [
            ...prev,
            {
              icon: 'communication--call--forward--unconditional',
              label: t('No option forward'),
              ...AddonProps,
            },
          ]
        }
        console.warn(`Unknown addon type: ${AddonProps.type} passed in ${props.type} data `, data)

        return prev
      }, [])}
      body={{ children: data.title }}
      title={{
        icon: 'generic--branch--alt',
        label: t('IVR'),
        nmbr: data.extensionNmbr,
      }}
    />
  )
}

export default IVRNode
