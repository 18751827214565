/**
 * this is the single source of truth for the available languages in the application
 * the key is the language code and the value is the language name
 * NOTE: the value should be translated into the language itself
 */
export const availableLanguages: Record<string, string> = {
  en: 'English',
  nl: 'Nederlands',
}

export const defaultLanguage = 'en'
