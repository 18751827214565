import { type JSX, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SortOrder } from '@/types'
import { Button, Card, Divider, Icon, Typography } from '@/ui'

import NmbrSearchField from '../components/NmbrSearchField'
import { SchemeLink } from '../components/SchemeLink'
import { useGetSchemesQuery } from '../types/graphqlOperations'
import { useCurrentAccount } from '../utils/currentAccountUtils'
import { generateLink, RoutePaths } from '../utils/routeUtiles'

export const AccountLandingView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const account = useCurrentAccount()

  const handleClickSchemes = useCallback(() => {
    navigate(generateLink(RoutePaths.SCHEMES, { accountId: account?.id || null }))
  }, [navigate, account])

  const handleClickNumbers = useCallback(() => {
    navigate(generateLink(RoutePaths.NUMBERS, { accountId: account?.id || null }))
  }, [navigate, account])

  return (
    <Card className='relative flex size-fit flex-col items-start justify-center gap-10'>
      <div className='relative flex w-fit flex-col items-stretch gap-0 md:flex-row'>
        <div className='flex w-fit flex-col justify-between md:pr-10'>
          <div>
            <Typography className='uppercase' variant='subtitle-1' gutterBottom>
              {t('welcome')}
            </Typography>
            <Typography variant='h5' gutterBottom>
              {t('Choose a Scheme')}
            </Typography>
            <Typography className='my-4' variant='microcopy'>
              {t('Recent Schemes')}
            </Typography>
            <MostUsedSchemes />
          </div>
          <div className='mt-5'>
            <Button onClick={handleClickSchemes}>{t('All Schemes')}</Button>
          </div>
        </div>
        <div className='flex w-fit flex-col md:flex-row'>
          <Divider className='!hidden md:!block' vertical />
          <Divider className='!block md:!hidden' spacing={6} />
          <div className='flex w-fit flex-col justify-between md:pl-10'>
            <div>
              <Typography className='uppercase' variant='subtitle-1' gutterBottom>
                {t('Or')}
              </Typography>
              <Typography variant='h5' gutterBottom>
                {t('Find by Number')}
              </Typography>
              <Typography className='my-4' variant='body-1'>
                Lorum ipsum dolor sit amet
              </Typography>
              <NmbrSearchField />
            </div>
            <div className='mt-5'>
              <Button variant='secondary' onClick={handleClickNumbers}>
                {t('All Numbers')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

const MostUsedSchemes = (): JSX.Element | null => {
  const account = useCurrentAccount()
  // TODO: handle loading and error
  const { data, error, loading } = useGetSchemesQuery({
    skip: !account,
    ...(!!account && {
      variables: {
        accountId: account?.id,
        sort: {
          field: 'usedAt',
          order: SortOrder.Desc,
        },
        pagination: {
          limit: 3,
          page: 0,
        },
      },
    }),
  })
  if (!data?.schemes || !account) return null
  return (
    <ul className='flex flex-col gap-2'>
      {data.schemes.schemes.map(scheme => (
        <li key={scheme.id}>
          <SchemeLink accountId={account.id} schemeId={scheme.id}>
            <span className='flex gap-2 align-middle'>
              <Icon className='text-neutral-900' name='action--edit--alt' />
              <Typography className='w-64' lineClamp={1}>
                {scheme.name}
              </Typography>
            </span>
          </SchemeLink>
        </li>
      ))}
    </ul>
  )
}

export default AccountLandingView
