import { defineCustomElementClButton } from '@enreach/core-component-library/dist/components/index'
import { ClButton } from '@enreach/core-component-library-react'
import * as React from 'react'

defineCustomElementClButton()

interface ButtonProps extends React.ComponentPropsWithoutRef<typeof ClButton> {
  loading?: boolean
  disabled?: boolean
  className?: string
}

export const Button = ({ children, disabled, loading, ...ClButtonProps }: ButtonProps) => {
  return (
    <ClButton {...ClButtonProps} disabled={disabled} processing={loading}>
      {children}
    </ClButton>
  )
}

export default Button
