import { type JSX, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { convertSecondsToTimeString, SECONDS_PER } from '@/types'
import { Typography } from '@/ui'

import { SevenEntryArray } from '../types/dates'

/**
 * Renders a time string based on the given number of seconds.
 * @param seconds - The number of seconds.
 * @returns The formatted time string.
 * @throws Error if seconds is negative or greater than SECONDS_PER.WEEK.
 */
export const Time = ({ seconds }: { seconds: number }): JSX.Element => {
  if (seconds < 0 || seconds > SECONDS_PER.WEEK) {
    throw new Error(`Seconds must be a positive number less than ${SECONDS_PER.WEEK}, actual: ${seconds}`)
  }

  const { t } = useTranslation()
  const days = useMemo((): SevenEntryArray => [t('MO'), t('TU'), t('WE'), t('TH'), t('FR'), t('SA'), t('SU')], [t])

  return (
    <Typography className='uppercase' noWrap>
      {convertSecondsToTimeString(seconds, days)}
    </Typography>
  )
}

export default Time
