import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import LocizeBackend from 'i18next-locize-backend'
import { initReactI18next } from 'react-i18next'

import { defaultLanguage } from './i18nConfig'
import { LocalStorage as LocalStorageEnum } from './local-storage'

// Function to get the saved language from local storage
const getSavedLanguage = () => {
  let lang: string
  try {
    lang = JSON.parse(localStorage.getItem(LocalStorageEnum.LANGUAGE) as string)
  } catch (e) {
    lang = defaultLanguage
  }
  return lang
}

// Function to determine if Locize should be used
const shouldUseLocize = () => {
  // Replace with your actual condition to check if Locize should be used
  return !!window.runtimeConfig.LOCIZE_PROJECT_ID
}

void i18n
  .use<LocizeBackend | HttpBackend>(shouldUseLocize() ? LocizeBackend : HttpBackend) // Conditionally use Locize backend
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: getSavedLanguage(),
    fallbackLng: defaultLanguage,
    debug: import.meta.env.DEV,
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    returnNull: false,
    backend: shouldUseLocize()
      ? {
          projectId: window.runtimeConfig.LOCIZE_PROJECT_ID,
          apiKey: window.runtimeConfig.LOCIZE_API_KEY,
          referenceLng: 'en', // Replace with your reference language
        }
      : {
          loadPath: '/locales/{{lng}}/{{ns}}.json', // Path to load the translation files
        },
  })

export default i18n
