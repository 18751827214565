import { type JSX, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { type NodeProps } from 'reactflow'

import { convertSecondsToTimeString } from '@/types'

import { SevenEntryArray } from '../../../../types/dates'
import { FlowNodeAddonProps, NodeDataBase } from '../../../../types/nodes'
import { FlowNode } from '../FlowNode'

export interface TBRNodeData extends NodeDataBase {
  addons?: Array<
    {
      type: 'scheduleOption' | 'failOver'
      start?: number
      end?: number
    } & FlowNodeAddonProps
  >
}

export const TBRNode = (propsProps: NodeProps<TBRNodeData>): JSX.Element => {
  const { t } = useTranslation()
  const days: SevenEntryArray = useMemo(() => [t('MO'), t('TU'), t('WE'), t('TH'), t('FR'), t('SA'), t('SU')], [t])

  const { data } = propsProps
  return (
    <FlowNode
      {...propsProps}
      addons={data.addons?.reduce<FlowNodeAddonProps[]>((prev, AddonProps) => {
        if (AddonProps.type === 'scheduleOption') {
          if (AddonProps.start && AddonProps.end) {
            return [
              ...prev,
              {
                ...AddonProps,
                icon: 'generic--calendar',
                label: `${convertSecondsToTimeString(
                  AddonProps.start,
                  days
                )}-${convertSecondsToTimeString(AddonProps.end, days)}`,
              },
            ]
          }
          return prev
        } else if (AddonProps.type === 'failOver') {
          return [
            ...prev,
            {
              ...AddonProps,
              icon: 'action--block',
              label: t('Failover'),
            },
          ]
        }
        console.warn(`Unknown addon type: ${AddonProps.type} passed in ${propsProps.type} data `, data)

        return prev
      }, [])}
      body={{ children: data.title }}
      title={{
        icon: 'generic--time',
        label: t('Timeplan'),
        nmbr: data.extensionNmbr,
      }}
    />
  )
}

export default TBRNode
