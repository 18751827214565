import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Ivr } from '@/types'

import FormValue from '../../../../components/FormValue'
import PanelLayout from '../../../../components/PanelLayout'
import { type PanelProps } from '../../../../types/nodes'
import { useCurrentAccount } from '../../../../utils/currentAccountUtils'

import { IVRNodeData } from './IVRNode'

export interface IVRPanelProps extends PanelProps<IVRNodeData> {}

const GET_EXTENSION = gql`
  query Extension($id: ID!, $accountId: ID!) {
    extension(id: $id, accountId: $accountId) {
      ... on Ivr {
        type
        name
        extension
      }
    }
  }
`

export const IVRPanel = ({
  node: {
    data: {
      extension: { extension_id: extensionId },
    },
  },
  onClose,
}: IVRPanelProps) => {
  const { t } = useTranslation()
  const account = useCurrentAccount()

  const { data, loading } = useQuery<{ extension: Ivr }>(GET_EXTENSION, {
    variables: {
      id: extensionId,
      accountId: account?.id,
    },
  })

  const extension = data?.extension

  return (
    <PanelLayout loading={loading} title={t('IVR')} onClose={onClose}>
      <PanelLayout.GroupHeading>{t('General')}</PanelLayout.GroupHeading>
      <FormValue label={t('Name')} value={extension?.name} />
      <FormValue label={t('Extension number')} value={extension?.extension} />
      <PanelLayout.GroupDivider />
      <PanelLayout.GroupHeading>{t('Configuration')}</PanelLayout.GroupHeading>
      <FormValue label={t('Max IVR option length')} value='6' />
      <FormValue label={t('Audio file')} value='ivr_message.mp3' isAudiofile />
      <FormValue label={t('Ringback tone before audio file')} isBoolean />
      <PanelLayout.GroupDivider />
      {/* <PanelLayout.GroupHeading>{t('IVR Options')}</PanelLayout.GroupHeading> */}
      {/* <ScrollWrapper maxHeight={28}>
        <List>
          {options.map((option) => (
            <ListItem
              key={option.option}
              primaryText={`${option.option} - ${option.destination.value} - ${option.destination.type} ${option.destination.name}`}
              secondaryText={`Prefix: ${option.prefix}`}
            >
              <span slot='leading' className='flex gap-2'>
                <Icon name='communication--call--dialpad' />
              </span>
            </ListItem>
          ))}
        </List>
      </ScrollWrapper> */}
      {/* <PanelLayout.GroupDivider /> */}
      <PanelLayout.GroupHeading>{t('Forwarding')}</PanelLayout.GroupHeading>
      <FormValue label={t('Transfer timeout (s)')} />
    </PanelLayout>
  )
}

export default IVRPanel
