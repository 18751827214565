import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Queue, Usr } from '@/types'
import { List, ListItem, ScrollWrapper, Typography } from '@/ui'

import FormValue from '../../../../components/FormValue'
import PanelLayout from '../../../../components/PanelLayout'
import { type PanelProps } from '../../../../types/nodes'
import { useCurrentAccount } from '../../../../utils/currentAccountUtils'

import { QueueNodeData } from './QueueNode'

const GET_EXTENSION = gql`
  query Extension($id: ID!, $accountId: ID!) {
    extension(id: $id, accountId: $accountId) {
      ... on Queue {
        type
        name
        strategy
        allow_individual_forwards
        members {
          extension_id
          extension
          name
        }
      }
    }
  }
`

export interface QueuePanelProps extends PanelProps<QueueNodeData> {}

export const QueuePanel = ({
  node: {
    data: {
      extension: { extension_id: extensionId },
    },
  },
  onClose,
}: QueuePanelProps) => {
  const { t } = useTranslation()
  const account = useCurrentAccount()

  const { data, loading } = useQuery<{ extension: Queue }>(GET_EXTENSION, {
    variables: {
      id: extensionId,
      accountId: account?.id,
    },
  })

  const extension = data?.extension

  return (
    <PanelLayout loading={loading} title={t('Queue Extension')} onClose={onClose}>
      <PanelLayout.GroupHeading>{t('General')}</PanelLayout.GroupHeading>
      <FormValue label={t('Name')} value={extension?.name} />
      <FormValue label={t('Extension number')} value={extension?.extension} />
      <PanelLayout.GroupDivider />
      <PanelLayout.GroupHeading>{t('Members')}</PanelLayout.GroupHeading>

      {extension ? (
        <ScrollWrapper maxHeight={28}>
          <List>
            {extension.members?.map((member: Usr) => (
              <ListItem
                key={member.extension}
                primaryText={member.name ?? undefined}
                secondaryText={member.extension ? `${member.extension}` : undefined}
              />
            ))}
          </List>
        </ScrollWrapper>
      ) : (
        <Typography variant='microcopy'>{t('No members selected')}</Typography>
      )}
      <PanelLayout.GroupDivider />
      <PanelLayout.GroupHeading>{t('Configuration')}</PanelLayout.GroupHeading>
      <FormValue label={t('Open access')} isBoolean />
      <FormValue label={t('Language')} value={[{ label: t('Dutch'), value: 'nl' }]} isSelect />
      <FormValue
        label={t('Strategy')}
        value={[{ label: extension?.strategy ?? 'Unknown', value: extension?.strategy ?? 'Unknown' }]}
        isSelect
      />
      {/* <FormValue isBoolean label={t('Announce queue position')} />
      <FormValue label={t('Queue position announcement interval (s)')} />
      <FormValue label={t('Ring timeout (s)')} />
      <FormValue label={t('Cooldown after conversation (s)')} />
      <FormValue label={t('Queue weight (0-100)')} />
      <FormValue
        isSelect
        label={t('Option for queue breakout')}
        value={[{ label: t('Queue breakout disabled'), value: '0' }]}
      />
      <FormValue isAudiofile label={t('Queue audiofile')} value='blabla.mp3' />
      <FormValue isBoolean label={t('Music on hold')} />
      <FormValue isBoolean label={t('Skip queue intro')} />
      <FormValue isBoolean label={t('Ringback tone')} />
      <FormValue isBoolean label={t('Offer second call')} />
      <PanelLayout.GroupHeading>{t('Forwarding')}</PanelLayout.GroupHeading>
      <FormValue label={t('Forward timer (s)')} value={0} />
      <FormValue
        isBoolean
        label={t('Allow individual forwards')}
        value={extension?.allow_individual_forwards ?? false}
      /> */}
    </PanelLayout>
  )
}

export default QueuePanel
