export enum BuildNodeStrategy {
  FULLY_INTERCONNECTING = 'FULLY_INTERCONNECTING',
  UNIQUE_FLOW_PER_NUMBER_NUMBERPLAN = 'UNIQUE_FLOW_PER_NUMBER_NUMBERPLAN',
  UNIQUE_FLOW_PER_NUMBER = 'UNIQUE_FLOW_PER_NUMBER',
}

export interface DomainFilterCriteria {
  /**
   * array of dialplans that you want to show
   */
  dialplans: string[]
  /**
   * a number to search in the graph for
   */
  number?: string
  /**
   * the preferred strategy for viewing the graph
   */
  view?: BuildNodeStrategy
}
export interface GraphFilterCriteria {
  /**
   * filter unconnected members
   */
  showUnconnected?: boolean
}
